import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import ReceiptContainer from "../../containers/receiptContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Category from "../component/common/category"
import Pagination from "../component/common/pagination"
import DatePicker from "../component/common/datePicker"
import Search from "../component/common/search"
import Button from "../component/common/button"
import BasicPopup from "../../popups/basicPopup"
import CheckIcon from "../../images/check_on.png"
import { OnClickExcelSave } from "../../utils/util"
import moreBtn from "../../images/more_btn.png"
const sizeList = [
  { seq: -1, name: "선택" },
  { seq: 1, name: "대" },
  { seq: 2, name: "소" },
]

const Receipt = (props) => {
  const {
    _freights,
    _freightsTotal,
    _documents,
    _documentsTotal,
    getFreights,
    initFreight,
    getFreight,
    patchFreightsPrint,
    getDocuments,

    getDocument,
    patchDocumentsPrint,
    getDocuDownloadExcel,
    getFreightDownloadExcel,
    setFreightPrint,
    initFreightPrint,
    setDocumentPrint,
    initDocumentPrint,
    postFreightsUpload,
    postDocumentsUpload,
    getBranchList,

  } = props
  const {
    searchInfoTap,
    searchInfoStartDate,
    searchInfoEndDate,
    serachInfoIndex,
    searchInfoText,
    searchInfoIsScan,
    searchInfoCurrent,
  } = props.location.state
  const [popup, setPopup] = useState({ open: false, message: "" })
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = [
    "",
    "날짜를 다시 확인해주세요.",
    "검색항목을 입력하세요",
    "검색어를 두자이상 입력해주세요",
    "엑셀등록을 성공하였습니다",
    "엑셀등록을 실패하였습니다",
  ]
  const [tab, setTab] = useState(searchInfoTap === 1 ? 1 : 0)
  const [startDate, setStartDate] = useState(searchInfoStartDate || moment().startOf("months"))
  const [endDate, setEndDate] = useState(searchInfoEndDate || moment().endOf("months"))
  const [tempStartDate, setTempStartDate] = useState(searchInfoStartDate || moment().startOf("months"))
  const [tempEndDate, setTempEndDate] = useState(searchInfoEndDate || moment().endOf("months"))
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")
  const [tempSearchIndex, setTempSearchIndex] = useState(serachInfoIndex || 0)
  const [tempSearchText, setTempSearchText] = useState(searchInfoText || "")
  const [current, setCurrent] = useState(searchInfoCurrent || 1)
  const [isScan, setIsScan] = useState(searchInfoIsScan === 0 ? 0 : 1)
  const [checkList, setCheckList] = useState([])
  const [remarkNum, setRemarkNum] = useState(-1)
  const excelUpload = useRef()

  const onClickDocuExcelSave = async () => {
    let temp = { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD"), isScan: isScan }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDocuDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "문서 리스트" })
  }
  const onClickFreightExcelSave = async () => {
    let temp = { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD"), isScan: isScan }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getFreightDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "소화물 리스트" })
  }

  useEffect(() => {
    setTempStartDate(startDate)
    setTempEndDate(endDate)
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])
  useEffect(() => {
    if (Object.keys(props.location.state).length > 0) return
    const today = moment()
    let temp = {
      page: 0,
      count: 20,
      startDate: today.startOf("months").format("YYYY-MM-DD"),
      endDate: today.endOf("months").format("YYYY-MM-DD"),
      isScan: 1,
    }
    if (tab === 0) {
      const t = async () => await getDocuments({ ...temp })
      t()
    } else {
      const t = async () => await getFreights({ ...temp })
      t()
    }
  }, [tab])

  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      isScan: isScan,
    }

    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }

    // console.log(temp)
    if (tab === 0) {
      await getDocuments({ ...temp })
    } else {
      await getFreights({ ...temp })
    }

    await getBranchList({})
  }

  // 검색 버튼 클릭 리스너
  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요." })
      return
    }

    setTempStartDate(startDate)
    setTempEndDate(endDate)
    setSearchText(tempSearchText)
    setSearchIndex(tempSearchIndex)
    setIsScan(1)
    if (tempSearchIndex === 0) {
      setPopup({ open: true, message: "검색항목을 입력하세요" })
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setPopup({ open: true, message: "검색어를 두자이상 입력해주세요" })
    //   return
    // }

    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      isScan: 1,
    }
    if (tempSearchIndex !== 0){// && tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    if (tab === 0) {
      await getDocuments({ ...temp })
    } else if (tab === 1) {
      await getFreights({ ...temp })
    }
    setCurrent(1)
    setCheckList([])
  }
  const onClickLookUp = async () => {
    if (!moment(tempStartDate).isBefore(moment(tempEndDate))) {
      setPopup({ open: true, message: "날짜를 다시 확인해주세요." })
      return
    }

    setStartDate(tempStartDate)
    setEndDate(tempEndDate)
    setTempSearchIndex(0)
    setTempSearchText("")
    setSearchIndex(0)
    setSearchText("")
    setIsScan(1)
    setCurrent(1)
    setCheckList([])

    let temp = {
      page: 0,
      count: 20,
      startDate: moment(tempStartDate).format("YYYY-MM-DD"),
      endDate: moment(tempEndDate).format("YYYY-MM-DD"),
      isScan: 1,
    }

    if (tab === 0) {
      await getDocuments({ ...temp })
    } else if (tab === 1) {
      await getFreights({ ...temp })
    }
  }

  const onClickNoPrintA4 = async () => {

    if (checkList.length === 0) return
    
    const temp = checkList
    
    setCheckList([])
    
    // if (tab === 0) {
    //   //await patchDocumentsPrint({ docs: [...temp.map((v, i) => v.toString())] })
    // } else if (tab === 1) {
    //   //await patchFreightsPrint({ freights: [...temp.map((v, i) => v.toString())] })
    // }

    // if (tab === 0) {
    //   console.log(
    //     "111",
    //     _documents.filter((t) => checkList.includes(t.docuSeq))
    //   )
    //   setDocumentPrint(_documents.filter((t) => checkList.includes(t.docSeq)))
    //   navigate("/app/printDocu", { state: { target: "document" } })
    // } else if (tab === 1) {
      
      setFreightPrint(_freights.filter((t) => checkList.includes(t.freightSeq)))

      navigate("/app/printFreightA4", { state: { target: "freight" } })

    // }

    getData()

  }

  // 송장출력 버튼 클릭 리스너
  const onClickNoPrint = async () => {
    if (checkList.length === 0) return
    const temp = checkList
    setCheckList([])
    if (tab === 0) {
      //await patchDocumentsPrint({ docs: [...temp.map((v, i) => v.toString())] })
    } else if (tab === 1) {
      //await patchFreightsPrint({ freights: [...temp.map((v, i) => v.toString())] })
    }

    if (tab === 0) {
      console.log(
        "111",
        _documents.filter((t) => checkList.includes(t.docuSeq))
      )
      setDocumentPrint(_documents.filter((t) => checkList.includes(t.docSeq)))
      navigate("/app/printDocu", { state: { target: "document" } })
    } else if (tab === 1) {
      setFreightPrint(_freights.filter((t) => checkList.includes(t.freightSeq)))
      navigate("/app/printFreight", { state: { target: "freight" } })
    }
    getData()
  }

  // 등록 버튼 클릭 리스너
  const onClickRegister = async () => {
    if (tab === 0) {
      // await initDocument()
      // navigate("/app/receiptDocuNew")
    } else if (tab === 1) {
      await initFreight()
      navigate("/app/receiptParcelNew")
    }
  }

  // 상세 페이지로 이동
  const onClickDetailPage = async (seq) => {
    if (tab === 0) {
      await getDocument({ docSeq: seq })

      navigate("/app/receiptDocuNew", {
        state: {
          searchInfoStartDate: moment(startDate).format("YYYY-MM-DD"),
          searchInfoEndDate: moment(endDate).format("YYYY-MM-DD"),
          serachInfoIndex: searchIndex,
          searchInfoText: searchText,
          searchInfoIsScan: isScan,
          searchInfoCurrent: current,
        },
      })
    } else if (tab === 1) {
      await getFreight({ freightSeq: seq })
      navigate("/app/receiptParcelNew", {
        state: {
          searchInfoStartDate: moment(startDate).format("YYYY-MM-DD"),
          searchInfoEndDate: moment(endDate).format("YYYY-MM-DD"),
          serachInfoIndex: searchIndex,
          searchInfoText: searchText,
          searchInfoIsScan: isScan,
          searchInfoCurrent: current,
        },
      })
    }
  }

  // 체크 박스 클릭 리스너
  const onClickCheckBox = async (seq) => {
    const temp = [...checkList]
    const index = temp.indexOf(seq)
    if (index === -1) {
      temp.push(seq)
    } else {
      temp.splice(index, 1)
    }
    setCheckList(temp)
  }

  return (
    <Layout navi={"접수관리"}>
      <SEO title={"접수관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, message: "" })} content={popup.message} selectedValue={["확인"]} />
      <section>
        <PageName title={["접수관리"]}></PageName>
        <Category
          items={["문서", "소화물"]}
          state={tab}
          setState={(value) => {
            if (tab === value) return
            setTab(value)
            setCheckList([])
            props.location.state = {}
            setSearchIndex(0)
            setSearchText("")
            setTempSearchIndex(0)
            setTempSearchText("")
            setCurrent(1)
            setIsScan(1)
            setStartDate(moment().startOf("months"))
            setEndDate(moment().endOf("months"))
            setTempStartDate(moment().startOf("months"))
            setTempEndDate(moment().endOf("months"))
          }}
        ></Category>
        <Box justifyContent={"space-between"}>
          <Box>
            <DatePicker
              label={"접수일자"}
              startDate={tempStartDate}
              setStartDate={setTempStartDate}
              endDate={tempEndDate}
              setEndDate={setTempEndDate}
              onClick={onClickLookUp}
            />
            <Search
              item={
                tab === 0
                  ? ["선택", "송장번호", "발신거래처지점명", "발신인", "수신거래처지점명", "수신인", "수신주소", "발신지사", "수신지사", "내용물"]
                  : [
                      "선택",
                      "운송장번호",
                      "발신거래처지점명",
                      "발신인",
                      "수신거래처지점명",
                      "수신인",
                      "수신주소",
                      "발신지사",
                      "수신지사",
                      "품명",
                      "크기",
                    ]
              }
              index={tempSearchIndex}
              setIndex={setTempSearchIndex}
              text={tempSearchText}
              setText={setTempSearchText}
              onClickSearch={onClickSearch}
            />
          </Box>
          <Box>
            
            {tab == 1 && <Button onClick={async () => onClickNoPrintA4()}>선택송장출력(A4)</Button>}

            <Button onClick={async () => onClickNoPrint()} marginLeft={6} >선택송장출력</Button>
            <Button
              marginLeft={6}
              onClick={async () => {
                setTempSearchIndex(searchIndex)
                setTempSearchText(searchText)
                setTempStartDate(startDate)
                setTempEndDate(endDate)

                setIsScan(isScan === 1 ? 0 : 1)

                //getData
                let temp = {
                  page: 0,
                  count: 20,
                  startDate: moment(startDate).format("YYYY-MM-DD"),
                  endDate: moment(endDate).format("YYYY-MM-DD"),
                  isScan: isScan === 1 ? 0 : 1,
                }

                if (searchIndex !== 0 && searchText.length >= 2) {
                  temp = { ...temp, searchType: searchIndex, search: searchText }
                }

                if (tab === 0) {
                  await getDocuments({ ...temp })
                } else {
                  await getFreights({ ...temp })
                }
              }}
            >
              {isScan === 0 ? "전체목록" : "미스캔목록"}
            </Button>
            <Button
              marginLeft={6}
              onClick={() => {
                if (tab === 0) {
                  onClickDocuExcelSave()
                } else {
                  onClickFreightExcelSave()
                }
              }}
            >
              엑셀 저장
            </Button>
            <input
              ref={excelUpload}
              style={{ display: "none" }}
              type={"file"}
              onChange={async (e) => {
                const form_data = new FormData()

                form_data.append("file", e.target.files[0])
                if (tab === 0) {
                  const result = await postDocumentsUpload(form_data)
                  if (result.code === 0) {
                    setPopup({ open: true, message: `엑셀등록을 성공하였습니다` })
                    getData()
                  } else {
                    setPopup({ open: true, message: `엑셀등록을 실패하였습니다\n${result.message}` })
                  }
                } else {
                  const result = await postFreightsUpload(form_data)
                  if (result.code === 0) {
                    setPopup({ open: true, message: `엑셀등록을 성공하였습니다` })
                    getData()
                  } else {
                    setPopup({ open: true, message: `엑셀등록을 실패하였습니다\n${result.message}` })
                  }
                  console.log("form_data_Freight_result", result)
                }
              }}
              accept={".xlsx"}
            />
            <Button marginLeft={6} onClick={() => excelUpload.current.click()}>
              엑셀 등록
            </Button>
            {tab === 1 && (
              <Button marginLeft={6} onClick={async () => onClickRegister()}>
                등록
              </Button>
            )}
          </Box>
        </Box>
      </section>

      <section style={{ marginTop: "21px" }}>
        {tab === 0 ? (
          <table style={{ marginBottom: 35.4 }}>
            <thead>
              <tr>
                <th>선택</th>
                <th>번호</th>
                <th>송장번호</th>
                <th>{`발신\n거래처 지점명`}</th>
                <th>발신인</th>
                <th>{`수신\n거래처 지점명`}</th>
                <th>수신인</th>
                <th>수신주소</th>
                <th>발신 지사</th>
                <th>수신 지사</th>
                <th>접수일자</th>
                <th>내용물</th>
                <th>수량</th>
              </tr>
            </thead>
            <tbody>
              {_documents.map((listItem, listIndex) => {
                return (
                  <tr key={listIndex}>
                    <td>
                      <button
                        id={"checkBox"}
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid #ced1da",
                          borderRadius: "4px",
                          backgroundColor: listItem.isPrint === "1" ? "#eeeef3" : "#fff",
                        }}
                        onClick={() => {
                          if (listItem.isPrint === "1") return
                          onClickCheckBox(listItem.docSeq)
                        }}
                      >
                        <img
                          style={{ display: checkList.indexOf(listItem.docSeq) === -1 ? "none" : "block", width: "20px", height: "20px" }}
                          src={CheckIcon}
                          alt={"체크 on 아이콘"}
                        />
                      </button>
                    </td>
                    <td>{_documentsTotal - (current - 1) * 20 - listIndex}</td>
                    <td>
                      <button onClick={() => onClickDetailPage(listItem.docSeq)}>
                        <u>{listItem.docNo}</u>
                      </button>
                    </td>
                    <td>{listItem.sendPoint.pointName}</td>
                    <td>{listItem.sendEmpName}</td>
                    <td>{listItem.recvPoint.pointName}</td>
                    <td>{listItem.recvEmpName}</td>
                    <td>{listItem.recvAddr + "\n" + (listItem.recvAddrDetail || "")}</td>
                    <td>{listItem.sendBranch.branchName}</td>
                    <td>{listItem.recvBranch.branchName}</td>
                    <td>{moment(listItem.regDatetime).format("YYYY.MM.DD")}</td>
                    <td>{listItem.content}</td>
                    <td>{listItem.qty.toLocaleString()}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <table style={{ marginBottom: 35.4 }}>
            <thead>
              <tr>
                <th>선택</th>
                <th>번호</th>
                <th>운송장번호</th>
                <th>{`발신\n거래처 지점명`}</th>
                <th>발신인</th>
                <th>{`수신\n거래처 지점명`}</th>
                <th>수신인</th>
                <th>수신주소</th>
                <th>발신 지사</th>
                <th>수신 지사</th>
                <th>접수일자</th>
                <th>품명</th>
                <th>단가</th>
                <th>수량</th>
                <th>크기</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              {_freights.map((listItem, listIndex) => (
                <tr key={listIndex}>
                  <td>
                    <button
                      id={"checkBox"}
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #ced1da",
                        borderRadius: "4px",
                        backgroundColor: listItem.isPrint === "1" ? "#eeeef3" : "#fff",
                      }}
                      onClick={() => {
                        if (listItem.isPrint === "1") return
                        onClickCheckBox(listItem.freightSeq)
                      }}
                    >
                      <img
                        style={{
                          display: checkList.indexOf(listItem.freightSeq) === -1 ? "none" : "block",
                          width: "20px",
                          height: "20px",
                        }}
                        src={CheckIcon}
                        alt={"체크 on 아이콘"}
                      />
                    </button>
                  </td>
                  <td>{_freightsTotal - (current - 1) * 20 - listIndex}</td>
                  <td>
                    <button onClick={() => onClickDetailPage(listItem.freightSeq)}>{listItem.freightNo}</button>
                  </td>
                  <td>{listItem.sendPoint.pointName}</td>
                  <td>{listItem.sendEmpName}</td>
                  <td>{listItem.recvPoint.pointName}</td>
                  <td>{listItem.recvEmpName}</td>
                  <td>{listItem.recvAddr + "\n" + (listItem.recvAddrDetail || "")}</td>
                  <td>{listItem.sendBranch.branchName}</td>
                  <td>{listItem.recvBranch.branchName}</td>
                  <td>{moment(listItem.regDatetime).format("YYYY.MM.DD")}</td>
                  <td>{listItem.itemName}</td>
                  <td style={{ textAlign: "right", paddingRight: "5px" }}>{listItem.price.toLocaleString()}</td>
                  <td>{listItem.qty.toLocaleString()}</td>
                  <td>{sizeList[listItem.size].name}</td>
                  <td>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {(listItem.remark + "").length > 5 ? listItem.remark.substring(0, 5) + "..." : listItem.remark}
                      </div>
                      {(listItem.remark + "").length > 5 && (
                        <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                          <button
                            onClick={() => {
                              if (listIndex === remarkNum) setRemarkNum(-1)
                              else setRemarkNum(listIndex)
                            }}
                            onBlur={() => setRemarkNum(-1)}
                            style={{ height: "9px" }}
                          >
                            <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                          </button>

                          {listIndex === remarkNum && (
                            <div style={{ position: "relative" }}>
                              <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                <div
                                  style={{
                                    width: "0px",
                                    height: "0px",
                                    borderBottom: "5.6px solid #fff",
                                    borderLeft: "5.65px solid transparent",
                                    borderRight: "5.65px solid transparent",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    width: "380px",
                                    height: "136px",
                                    overflowY: "auto",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    borderRadius: "4px",
                                    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                    padding: "11px",
                                  }}
                                >
                                  <p style={{ whiteSpace: "pre-wrap" }}>{listItem.remark}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Pagination current={current} setCurrent={setCurrent} total={tab === 0 ? _documentsTotal : _freightsTotal} />
      </section>
    </Layout>
  )
}

export default ReceiptContainer(Receipt)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
