import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import ManageContainer from "../../containers/manageContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Pagination from "../component/common/pagination"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Category from "../component/common/category"
import DatePicker from "../component/common/datePicker"
import BasicPopup from "../../popups/basicPopup"
import moreBtn from "../../images/more_btn.png"
import { OnClickExcelSave, GetStatus } from "../../utils/util"
const Claim = (props) => {
  const {
    getFreightDownloadExcel,
    getPouchDownloadExcel,
    getManageFreightTracks,
    getManagePouchTracks,
    _manageFreightTracks,
    _managePouchTracks,
    _manageFreightTracksTotal,
    _managePouchTracksTotal,
    patchManageFreightPrice,
    patchManagePouchPrice,
    setManagePouchTracksSettlePrice,
    setManagePouchTracksBillPrice,
    setManageFreightTracksSettlePrice,
    setManageFreightTracksBillPrice,
    _manageFreightBillPrice,
    _manageFreightSettlePrice,
    _managePouchBillPrice,
    _managePouchSettlePrice,
  } = props
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [tab, setTab] = useState(0) // 탭
  //
  const [startDate, setStartDate] = useState(moment().startOf("months")) // 배송 시작 일자
  const [endDate, setEndDate] = useState(moment().endOf("months")) // 배송 완료 일자
  const [tempStartDate, setTempStartDate] = useState(moment().startOf("months"))
  const [tempEndDate, setTempEndDate] = useState(moment().endOf("months"))
  //
  const [searchIndex, setSearchIndex] = useState(0) // 검색
  const [searchText, setSearchText] = useState("") // 검색
  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  //
  const [current, setCurrent] = useState(1) // 페이지네이션
  const [toggle, setToggle] = useState(0) // 토글 버튼
  const [isPriceEditable, setIsPriceEditable] = useState(false)

  const [remarkNum, setRemarkNum] = useState(-1)
  const [test, setTest] = useState([])
  useEffect(() => {
    const t = async () => {
      let temp = {
        page: 0,
        count: 20,
        startDate: moment().startOf("months").format("yyyy-MM-DD"),
        endDate: moment().endOf("months").format("yyyy-MM-DD"),
      }
      if (toggle === 0) {
        await getManagePouchTracks({ ...temp })
      } else if (toggle === 1) {
        await getManageFreightTracks({ ...temp })
      }
    }
    t()
    setIsPriceEditable(false)

    setTempStartDate(moment().startOf("months"))
    setTempEndDate(moment().endOf("months"))
    setStartDate(moment().startOf("months"))
    setEndDate(moment().endOf("months"))
    setSearchText("")
    setSearchIndex(0)
    setTempSearchText("")
    setTempSearchIndex(0)
    setRemarkNum(-1)
  }, [toggle, tab])
  useEffect(() => {
    getData()
  }, [current])
  useEffect(() => {
    if (toggle === 0) {
      //행낭
      let temp = []
      if (Object.keys(_managePouchTracks).length === 0) return
      _managePouchTracks.map((v, i) => {
        temp = [...temp, { trackSeq: v.trackSeq, billPrice: v.billPrice, settlePrice: v.settlePrice }]
      })
      setTest([...temp])
    } else if (toggle == 1) {
      //소화물
      let temp = []
      if (Object.keys(_manageFreightTracks).length === 0) return
      _manageFreightTracks.map((v, i) => {
        temp = [...temp, { trackSeq: v.trackSeq, billPrice: v.billPrice, settlePrice: v.settlePrice }]
      })
      setTest([...temp])
    }
  }, [toggle, _managePouchTracks, _manageFreightTracks])

  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    if (toggle === 0) {
      await getManagePouchTracks({ ...temp })
    } else if (toggle === 1) {
      await getManageFreightTracks({ ...temp })
    }
  }
  const onClickPouchExcelSave = async () => {
    let temp = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
      type: tab + 1,
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getPouchDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "청구/정산 행낭 리스트" })
  }
  const onClickFreightExcelSave = async () => {
    let temp = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
      type: tab + 1,
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getFreightDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "청구/정산 정산 리스트" })
  }
  const onClickSearch = async () => {
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }

    setTempStartDate(startDate)
    setTempEndDate(endDate)
    setSearchText(tempSearchText)
    setSearchIndex(tempSearchIndex)
    setIsPriceEditable(false)
    setCurrent(1)
    if (tempSearchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } else if (tempSearchText.length < 2) {
      setMessageIndex(3)
      setPopup(true)
      return
    }
    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    if (toggle === 0) {
      await getManagePouchTracks({ ...temp })
    } else if (toggle === 1) {
      await getManageFreightTracks({ ...temp })
    }
  }
  const onClickLookUp = async () => {
    if (!moment(tempStartDate).isBefore(moment(tempEndDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }

    setStartDate(tempStartDate)
    setEndDate(tempEndDate)
    setTempSearchIndex(0)
    setTempSearchText("")
    setSearchIndex(0)
    setSearchText("")
    setIsPriceEditable(false)
    setCurrent(1)

    let temp = {
      page: current - 1,
      count: 20,
      startDate: moment(tempStartDate).format("yyyy-MM-DD"),
      endDate: moment(tempEndDate).format("yyyy-MM-DD"),
    }
    if (toggle === 0) {
      await getManagePouchTracks({ ...temp })
    } else if (toggle === 1) {
      await getManageFreightTracks({ ...temp })
    }
  }
  useEffect(() => {
    console.log("ttt", test)
  }, [test])
  const onClickPriceSave = async () => {
    console.log("!!!", "저장")
    if (toggle === 0) {
      await patchManagePouchPrice({
        tracks: [
          ...test.map((i) => ({
            trackSeq: i.trackSeq,
            billPrice: Number(i.billPrice.toString().replace(/,/g, "")),
            settlePrice: Number(i.settlePrice.toString().replace(/,/g, "")),
          })),
        ],
      })
    } else if (toggle === 1) {
      await patchManageFreightPrice({
        tracks: [
          ...test.map((i) => ({
            trackSeq: i.trackSeq,
            billPrice: Number(i.billPrice.toString().replace(/,/g, "")),
            settlePrice: Number(i.settlePrice.toString().replace(/,/g, "")),
          })),
        ],
      })
    }

    getData({ page: current - 1 })
  }

  return (
    <Layout navi={"청구/정산관리"}>
      <SEO title={"청구/정산관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["청구/정산관리"]}></PageName>
      <Category
        items={["청구", "정산"]}
        state={tab}
        setState={(value) => {
          setTab(value)
          setToggle(0)
        }}
      ></Category>

      <Container>
        <Box1 select={toggle === 0} onClick={() => setToggle(0)}>
          <Text select={toggle === 0}>행낭</Text>
        </Box1>
        <Box1 select={toggle === 1} onClick={() => setToggle(1)}>
          <Text select={toggle === 1}>소화물</Text>
        </Box1>
      </Container>

      <Box justifyContent={"space-between"}>
        <Box>
          <DatePicker
            label={"배송완료일자"}
            startDate={tempStartDate}
            setStartDate={setTempStartDate}
            endDate={tempEndDate}
            setEndDate={setTempEndDate}
            onClick={onClickLookUp}
          />
          <Search
            item={["선택", "행낭번호", "발신거래처지점명", "수신거래처지점명", "발신지사", "수신지사", "위치", "상태"]}
            index={tempSearchIndex}
            setIndex={setTempSearchIndex}
            text={tempSearchText}
            setText={setTempSearchText}
            onClickSearch={onClickSearch}
          />
        </Box>
        <Button
          style={{ marginLeft: "317px" }}
          onClick={() => {
            if (toggle === 0) {
              onClickPouchExcelSave()
            } else {
              onClickFreightExcelSave()
            }
          }}
        >
          엑셀 저장
        </Button>
      </Box>

      <Box justifyContent={"space-between"}>
        <FlexBox>
          <P>총금액</P>
          <P>
            {tab === 0 && toggle === 0 && Number(_managePouchBillPrice).toLocaleString()}
            {tab === 0 && toggle === 1 && Number(_manageFreightBillPrice).toLocaleString()}
            {tab === 1 && toggle === 0 && Number(_managePouchSettlePrice).toLocaleString()}
            {tab === 1 && toggle === 1 && Number(_manageFreightSettlePrice).toLocaleString()}
            {"원"}
          </P>
        </FlexBox>

        <Button
          style={{
            marginLeft: "737px",
            marginTop: "20px",
            color: isPriceEditable ? "#fff" : "#00adee",
            backgroundColor: isPriceEditable ? "#00adee" : "#fff",
          }}
          onClick={async () => {
            if (isPriceEditable) {
              await onClickPriceSave()
            }
            setIsPriceEditable(!isPriceEditable)
          }}
        >
          {isPriceEditable ? "금액 저장" : "금액 수정"}
        </Button>
      </Box>
      <Section>
        <table style={{ marginBottom: 43.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th>{toggle === 0 ? "행낭코드" : "운송장번호"}</th>
              <th>발신 거래처 지점명</th>
              <th>수신 거래처 지점명</th>
              <th>발신지사</th>
              <th>수신지사</th>
              <th>위치</th>
              <th>상태</th>
              <th>비고</th>
              <th>금액</th>
            </tr>
          </thead>
          <tbody>
            {toggle === 0
              ? _managePouchTracks.length === test.length &&
                _managePouchTracks.map((listItem, listIndex) => (
                  <tr key={listIndex}>
                    <td>{_managePouchTracksTotal - (current - 1) * 20 - listIndex}</td>
                    <td>{listItem.pouch === null ? '' : listItem.pouch.pouchNo}</td>
                    <td>{listItem.pouch === null ? '' : listItem.pouch.sendPoint.pointName}</td>
                    <td>{listItem.pouch === null ? '' : listItem.pouch.recvPoint ? listItem.pouch.recvPoint.pointName : ''}</td>
                    <td>{listItem.pouch === null ? '' : listItem.pouch.sendPoint.branch === null ? '' : listItem.pouch.sendPoint.branch.branchName}</td>
                    <td>{listItem.pouch === null ? '' : listItem.pouch.recvPoint ? (listItem.pouch.recvPoint.branch === null ? '' : listItem.pouch.recvPoint.branch.branchName) : ''}</td>
                    <td>{listItem.addr}</td>
                    <td>{GetStatus(listItem.status)}</td>
                    <td>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {(listItem.pouch === null ? '' : listItem.pouch.remark + "").length > 5 ? listItem.pouch === null ? '' : listItem.pouch.remark.substring(0, 5) + "..." : listItem.pouch === null ? '' : listItem.pouch.remark}
                        </div>
                        {(listItem.pouch === null ? '' : listItem.pouch.remark + "").length > 5 && (
                          <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                            <button
                              onClick={() => {
                                if (listIndex === remarkNum) setRemarkNum(-1)
                                else setRemarkNum(listIndex)
                              }}
                              onBlur={() => setRemarkNum(-1)}
                              style={{ height: "9px" }}
                            >
                              <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                            </button>

                            {listIndex === remarkNum && (
                              <div style={{ position: "relative" }}>
                                <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                  <div
                                    style={{
                                      width: "0px",
                                      height: "0px",
                                      borderBottom: "5.6px solid #fff",
                                      borderLeft: "5.65px solid transparent",
                                      borderRight: "5.65px solid transparent",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      width: "380px",
                                      height: "136px",
                                      overflowY: "auto",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      borderRadius: "4px",
                                      boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                      padding: "11px",
                                    }}
                                  >
                                    <p style={{ whiteSpace: "pre-wrap" }}>{listItem.pouch === null ? '' : listItem.pouch.remark}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <input
                        type={"text"}
                        disabled={!isPriceEditable}
                        value={tab === 0 ? test[listIndex].billPrice.toLocaleString() : test[listIndex].settlePrice.toLocaleString()}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value === "") {
                            if (tab === 0) {
                              let tempList = [...test]
                              tempList[listIndex].billPrice = "0"
                              setTest(tempList)
                            } else if (tab === 1) {
                              let tempList = [...test]
                              tempList[listIndex].settlePrice = "0"
                              setTest(tempList)
                            }
                          } else {
                            const value2 = parseInt(value.replace(/,/g, "")).toLocaleString()
                            if (tab === 0) {
                              let tempList = [...test]
                              tempList[listIndex].billPrice = value2
                              setTest(tempList)
                            } else if (tab === 1) {
                              let tempList = [...test]
                              tempList[listIndex].settlePrice = value2
                              setTest(tempList)
                            }
                          }
                        }}
                        style={{ border: "1px solid #dbdee5", borderRadius: "4px", height: "30px", textAlign: "center" }}
                      ></input>
                    </td>
                  </tr>
                ))
              : _manageFreightTracks.length === test.length &&
                _manageFreightTracks.map((listItem, listIndex) => (
                  <tr key={listIndex}>
                    <td>{_manageFreightTracksTotal - (current - 1) * 20 - listIndex}</td>
                    <td>{listItem.freight.freightNo}</td>
                    <td>{listItem.freight.sendPoint.pointName}</td>
                    <td>{listItem.freight.recvPoint.pointName}</td>
                    <td>{listItem.freight.sendBranch.branchName}</td>
                    <td>{listItem.freight.recvBranch.branchName}</td>
                    <td>{listItem.addr}</td>
                    <td>{GetStatus(listItem.status)}</td>
                    <td>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {(listItem.freight.remark + "").length > 5 ? listItem.freight.remark.substring(0, 5) + "..." : listItem.freight.remark}
                        </div>
                        {(listItem.freight.remark + "").length > 5 && (
                          <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                            <button
                              onClick={() => {
                                if (listIndex === remarkNum) setRemarkNum(-1)
                                else setRemarkNum(listIndex)
                              }}
                              onBlur={() => setRemarkNum(-1)}
                              style={{ height: "9px" }}
                            >
                              <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                            </button>

                            {listIndex === remarkNum && (
                              <div style={{ position: "relative" }}>
                                <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                  <div
                                    style={{
                                      width: "0px",
                                      height: "0px",
                                      borderBottom: "5.6px solid #fff",
                                      borderLeft: "5.65px solid transparent",
                                      borderRight: "5.65px solid transparent",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      width: "380px",
                                      height: "136px",
                                      overflowY: "auto",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      borderRadius: "4px",
                                      boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                      padding: "11px",
                                    }}
                                  >
                                    <p style={{ whiteSpace: "pre-wrap" }}>{listItem.freight.remark}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <input
                        type={"text"}
                        disabled={!isPriceEditable}
                        value={tab === 0 ? test[listIndex].billPrice.toLocaleString() : test[listIndex].settlePrice.toLocaleString()}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value === "") {
                            if (tab === 0) {
                              let tempList = [...test]
                              tempList[listIndex].billPrice = "0"
                              setTest(tempList)
                            } else if (tab === 1) {
                              let tempList = [...test]
                              tempList[listIndex].settlePrice = "0"
                              setTest(tempList)
                            }
                          } else {
                            const value2 = parseInt(value.replace(/,/g, "")).toLocaleString()
                            if (tab === 0) {
                              let tempList = [...test]
                              tempList[listIndex].billPrice = value2
                              setTest(tempList)
                            } else if (tab === 1) {
                              let tempList = [...test]
                              tempList[listIndex].settlePrice = value2
                              setTest(tempList)
                            }
                          }
                        }}
                        style={{ border: "1px solid #dbdee5", borderRadius: "4px", height: "30px", textAlign: "center" }}
                      ></input>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </Section>
      <Pagination current={current} setCurrent={setCurrent} total={toggle === 0 ? _managePouchTracksTotal : _manageFreightTracksTotal} />
    </Layout>
  )
}

export default ManageContainer(Claim)

const Section = styled.section`
  padding: 20px 0;
`

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 20px;
`

const Text = styled.p`
  font-family: NanumSquareBold;
  font-size: 15px;
  line-height: 28px;
  /* letter-spacing: -0.48px; */
  color: ${(props) => (props.select ? "#fff" : "#35363a")};
`

const Box1 = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.select ? "#00adee" : "#dbdee5")};
  background-color: ${(props) => (props.select ? "#00adee" : "#fff")};

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const FlexBox = styled.div`
  width: 380px;
  height: 40px;
  border-radius: 4px;
  border: solid #7e96ad 1px;
  margin-top: 20px;
  padding: 0 20px;
  background-color: #eef2f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const P = styled.p`
  color: #445e78;
  font-size: 14px;
  line-height: 24.5px;
  font-family: NanumSquareBold;
`
