import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import styled from "styled-components"
//
import BasicPopup from "../../popups/basicPopup"
import Category from "../component/common/category"
import FotterButton from "../component/common/fotterButton"
import InputBox from "../component/common/inputBox"
import Layout from "../component/common/layout"
import moment from "moment"
import PageName from "../component/common/pageName"
import CustomerContainer from "../../containers/customerContainer"
import ZipCodePopup from "../../popups/zipCodePopup"
const CustomerNew = (props) => {
  const { _customer, postCustomer, patchCustomer, deleteCustomer, initCustomer, getCustomer } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const hasKeys = Object.keys(_customer).length > 0
  const [tap, setTap] = useState(0) // 탭 = 0: 일반정보 , 1: 지점

  const [popup, setPopup] = useState(false) // 페이지네이션

  const [inputBoxState1, setInputBoxState1] = useState(hasKeys ? _customer.custNo : "")
  const [custName, setCustName] = useState(hasKeys ? _customer.custName : "")
  const [fullName, setFullName] = useState(hasKeys ? _customer.fullName : "")
  const [empName, setEmpName] = useState(hasKeys ? _customer.empName : "")
  const [deptName, setDeptName] = useState(hasKeys ? _customer.deptName : "")
  const [phoneNo, setPhoneNo] = useState(hasKeys ? _customer.phoneNo : "")
  const [bizNo, setBizNo] = useState(hasKeys ? _customer.bizNo : "")
  const [repName, setRepName] = useState(hasKeys ? _customer.repName : "")
  const [bizType, setBizType] = useState(hasKeys ? _customer.bizType : "")
  const [addr, setAddr] = useState(hasKeys ? _customer.addr : "")
  const [addrDetail, setAddrDetail] = useState(hasKeys ? _customer.addrDetail : "")
  const [zipCode, setZipCode] = useState(hasKeys ? _customer.zipCode : "")
  const [billAddr, setBillAddr] = useState(hasKeys ? _customer.billAddr : "")
  const [billAddrDetail, setBillAddrDetail] = useState(hasKeys ? _customer.billAddrDetail : "")
  const [billZipCode, setBillZipCode] = useState(hasKeys ? _customer.billZipCode : "")
  const [teleNo, setTeleNo] = useState(hasKeys ? _customer.teleNo : "")
  const [faxNo, setFaxNo] = useState(hasKeys ? _customer.faxNo : "")
  const [dealStartDate, setDealStartDate] = useState(hasKeys ? _customer.dealStartDate : null)
  const [dealEndDate, setDealEndDate] = useState(hasKeys ? _customer.dealEndDate : null)
  const [remark, setRemark] = useState(hasKeys ? _customer.remark : "")
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [zipCodeBillPopup, setZipCodeBillPopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  //onClick...
  const onClickCancel = () => {
    navigate("/app/customer", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }
  const onClickSave = async () => {
    if (
      custName === "" ||
      fullName === "" ||
      empName === "" ||
      deptName === "" ||
      phoneNo === "" ||
      bizNo === "" ||
      repName === "" ||
      bizType === "" ||
      addr === "" ||
      addrDetail === "" ||
      billAddr === "" ||
      billAddrDetail === "" ||
      teleNo === "" ||
      faxNo === ""
    ) {
      setSaveCheckPopup(true)
      return
    }
    const temp = {
      custName: custName,
      fullName: fullName,
      empName: empName,
      deptName: deptName,
      phoneNo: phoneNo,
      bizNo: bizNo,
      repName: repName,
      bizType: bizType,
      addr: addr,
      addrDetail: addrDetail,
      zipCode: zipCode,
      billAddr: billAddr,
      billAddrDetail: billAddrDetail,
      billZipCode: billZipCode,
      teleNo: teleNo,
      faxNo: faxNo,
      dealStartDate: dealStartDate === null ? null : moment(dealStartDate).format("YYYY-MM-DD"),
      dealEndDate: dealEndDate === null ? null : moment(dealEndDate).format("YYYY-MM-DD"),
      remark: remark,
    }
    if (!hasKeys) {
      //등록
      await postCustomer({ ...temp })
    } else {
      const seqTemp = _customer.custSeq

      //수정
      await patchCustomer({ ...temp, custSeq: _customer.custSeq })
      await initCustomer()
      await getCustomer({ custSeq: seqTemp })
    }
    navigate("/app/customer")
  }
  const onClickDelete = async () => {
    await deleteCustomer({ custSeq: _customer.custSeq })
    navigate("/app/customer")
  }

  const onClickTap = async (v) => {
    if (v === 0) return
    if (!hasKeys) return //등록일경우,
    navigate("/app/point")
  }
  //end onClick...

  return (
    <Layout navi={"거래처관리"}>
      <SEO title={"거래처관리"} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "확인") {
            onClickDelete()
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setAddr} setZipCode={setZipCode} />
      <ZipCodePopup open={zipCodeBillPopup} onClose={() => setZipCodeBillPopup(false)} setAddr={setBillAddr} setZipCode={setBillZipCode} />
      <PageName title={["거래처관리", hasKeys ? "상세" : "등록"]} style={{ marginBottom: "40px" }}></PageName>
      <Category items={["일반 정보", "지점"]} state={tap} setState={onClickTap}></Category>
      <div style={{ display: "flex" }}>
        <InputBox label="거래처코드" readOnly={true} value={inputBoxState1} setValue={setInputBoxState1}></InputBox>
        <InputBox label="거래처명" value={custName} setValue={setCustName} marginLeft={12}></InputBox>
        <InputBox label="업체명(세금계산서)" value={fullName} setValue={setFullName} marginLeft={12}></InputBox>
        <InputBox label="담당자" value={empName} setValue={setEmpName} marginLeft={12}></InputBox>
        <InputBox label="부서명" value={deptName} setValue={setDeptName} marginLeft={12}></InputBox>
        <InputBox label="휴대폰번호" value={phoneNo} setValue={setPhoneNo} marginLeft={12}></InputBox>
      </div>
      <Splitline mt={17} mb={20}></Splitline>
      <div style={{ display: "flex" }}>
        <InputBox label="사업자등록번호" value={bizNo} setValue={setBizNo}></InputBox>
        <InputBox label="대표자" value={repName} setValue={setRepName} marginLeft={12}></InputBox>
        <InputBox label="업태" value={bizType} setValue={setBizType} marginLeft={12}></InputBox>
        <InputBox type={"address"} label="주소" value={addr} setValue={setAddr} marginLeft={12} onClick={() => setZipCodePopup(true)} />
        <InputBox label="상세주소" value={addrDetail} setValue={setAddrDetail} marginLeft={12}></InputBox>
        <InputBox label="우편번호" readOnly={true} value={zipCode} setValue={setZipCode} marginLeft={12}></InputBox>
      </div>

      <Splitline mt={17} mb={20}></Splitline>
      <div style={{ display: "flex" }}>
        <AlignDiv>
          <InputBox type={"address"} label="주소(세금계산서)" value={billAddr} setValue={setBillAddr} onClick={() => setZipCodeBillPopup(true)} />
        </AlignDiv>
        <AlignDiv>
          <InputBox label="상세주소(세금계산서)" value={billAddrDetail} setValue={setBillAddrDetail} marginLeft={12}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox label="우편번호(세금계산서)" readOnly={true} value={billZipCode} setValue={setBillZipCode} marginLeft={12}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox label="전화번호" value={teleNo} setValue={setTeleNo} marginLeft={12}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox label="팩스번호" value={faxNo} setValue={setFaxNo} marginLeft={12}></InputBox>
        </AlignDiv>
      </div>
      <Splitline mt={17} mb={20}></Splitline>

      <div style={{ display: "flex" }}>
        <AlignDiv>
          <InputBox type={"date"} noSelectable={true} label="최초거래일" value={dealStartDate} setValue={setDealStartDate} />
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox type={"date"} noSelectable={true} label="최종거래일" value={dealEndDate} setValue={setDealEndDate} />
        </AlignDiv>
        <AlignDiv flex={1} ml={12}>
          <InputBox fullWidth={true} label="비고" value={remark} setValue={setRemark}></InputBox>
        </AlignDiv>
      </div>
      <Splitline mt={17} mb={20}></Splitline>

      <Section id="FotterButton">
        {!hasKeys ? (
          <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
        ) : (
          <FotterButton
            onClickCancel={onClickCancel}
            onClickSave={onClickSave}
            onClickDelete={() => {
              setPopup(true)
            }}
          ></FotterButton>
        )}
      </Section>
    </Layout>
  )
}

export default CustomerContainer(CustomerNew)

const Section = styled.section`
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`

const AlignDiv = styled.div`
  flex: ${(props) => (props.flex ? 1 : 0)};
  margin-left: ${(props) => props.ml || 0}px;
  margin-right: ${(props) => props.mr || 0}px;
`
