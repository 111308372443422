import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getEmployees,
  getEmployee,
  postEmployee,
  patchEmployee,
  deleteEmployee,
  initEmployee,
  getEmployeeCheckId,
  getDownloadExcel,
} = actions.EmployeeAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _employees: state.EmployeeReducer._employees,
  _employee: state.EmployeeReducer._employee,
  _employeesTotal: state.EmployeeReducer._employeesTotal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getEmployees: (param) => dispatch(getEmployees(param)),
  getEmployee: (param) => dispatch(getEmployee(param)),
  postEmployee: (param) => dispatch(postEmployee(param)),
  patchEmployee: (param) => dispatch(patchEmployee(param)),
  deleteEmployee: (param) => dispatch(deleteEmployee(param)),
  initEmployee: () => dispatch(initEmployee()),
  getEmployeeCheckId: (param) => dispatch(getEmployeeCheckId(param)),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
