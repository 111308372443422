import React, { useEffect, useState } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import styled from "styled-components"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import { navigate } from "gatsby"
import BasicPopup from "../../popups/basicPopup"
import SectionName from "../component/common/sectionName"
import EmployeeContainer from "../../containers/employeeContainer"
import { OnClickExcelSave } from "../../utils/util"

const Employee = (props) => {
  const { getEmployees, _employees, initEmployee, _employeesTotal, getEmployee, getDownloadExcel } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(searchInfoCurrent || 1)

  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  //onClick ..
  const onClickRegister = async () => {
    await initEmployee()
    navigate("/app/employeeNew")
  }
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }
    
    let temp = { page: current - 1, count: 20 }
    if (tempSearchIndex !== 0){ // && tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    setCurrent(1)
    await getEmployees({ ...temp })
  }
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "사원리스트" })
  }
  //end onClick...
  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getEmployees({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])
  return (
    <Layout navi={"사원관리"}>
      <SEO title={"사원관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["사원관리"]}></PageName>
      <SectionName>사원관리</SectionName>

      <Section>
        <Search
          item={["선택", "사원명", "아이디", "관할구역", "지역", "휴대폰번호", "등록자"]}
          index={tempSearchIndex}
          setIndex={setTempSearchIndex}
          text={tempSearchText}
          setText={setTempSearchText}
          onClickSearch={onClickSearch}
        />
        <DivBtn>
          <Button onClick={onClickExcelSave} style={{ marginRight: "3px" }}>
            엑셀 저장
          </Button>
          <Button onClick={onClickRegister} style={{ marginLeft: "3px" }}>
            등록
          </Button>
        </DivBtn>
      </Section>

      <Section>
        <table style={{ marginBottom: 43.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th>사원명</th>
              <th>아이디</th>
              <th>관할구역</th>
              <th>지역</th>
              <th>휴대폰번호</th>
              <th>등록일자</th>
              <th>등록자</th>
            </tr>
          </thead>
          <tbody>
            {_employees.map((v, i) => (
              <tr key={i}>
                <td>{_employeesTotal - (current - 1) * 20 - i}</td>
                <td>
                  <button
                    onClick={async () => {
                      const result = await getEmployee({ empSeq: v.empSeq })
                      if (result) {
                        navigate("/app/employeeNew", {
                          state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                        })
                      }
                    }}
                  >
                    <u>{v.empName}</u>
                  </button>
                </td>
                <td>{v.userId}</td>
                <td>{v.region}</td>
                <td>{v.area}</td>
                <td>{v.phoneNo}</td>
                <td>{v.regDatetime}</td>
                <td>{v.registName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
      <Pagination current={current} setCurrent={setCurrent} total={_employeesTotal} />
    </Layout>
  )
}

export default EmployeeContainer(Employee)

const Section = styled.section`
  font-family: "NanumSquareRegular";
  padding-bottom: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
  margin-bottom: 20px;
`

const DivBtn = styled.div`
  margin-inline-start: auto;
`
