import React, { useEffect, useState, useRef } from "react"
import Layout from "../component/common/layout"
import styled from "styled-components"
import SEO from "../seo"
import moment from "moment"
import { useBarcode } from "react-barcodes"
import arrow from "../../images/print-arrow.png"
import logo from "../../images/print-logo.png"
import mobile from "../../images/print-mobile.png"
import PouchContainer from "../../containers/pouchContainer"
const ZeplinPxToMM = (px) => {
  return px * 0.3199249
}

const PrintPouchTop = (props) => {
  const { _pouchPrintTop, initPouchPrintTop, getDrivers, _drivers } = props
  const getData = async () => {
    await getDrivers({})
  }
  useEffect(() => {
    getData()
    return () => {
      const t = async () => {
        await initPouchPrintTop()
      }
    }
  }, [])
  return (
    <Container style={{ width: "100%", height: "100%", overflowY: "scroll", display: "block" }}>
      {_pouchPrintTop.map((v, i) => {
        return (
          <div
            style={{
              padding: `${ZeplinPxToMM(30)}px`,
            }}
            key={i}
          >
            <div style={{ pageBreakBefore: "always" }}></div>
            <Item
              sendCustName={v.sendPoint.customer.custName}
              pointName={v.sendPoint.pointName}
              deliverBranch={v.sendPoint.branch ? v.sendPoint.branch.branchName : ""}
              sendDeliverName={
                _drivers && _drivers.find((i) => i.driverSeq === v.sendPoint.driverSeq) !== undefined
                  ? _drivers.find((i) => i.driverSeq === v.sendPoint.driverSeq).driverName
                  : ""
              }
              sendDeliverTeleNo={
                _drivers && _drivers.find((i) => i.driverSeq === v.sendPoint.driverSeq) !== undefined
                  ? _drivers.find((i) => i.driverSeq === v.sendPoint.driverSeq).phoneNo
                  : ""
              }
              recvDeliverName={
                _drivers && _drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq) !== undefined
                  ? _drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq).driverName
                  : ""
              }
              recvDeliverTeleNo={
                _drivers && _drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq) !== undefined
                  ? _drivers.find((i) => i.driverSeq === v.recvPoint.driverSeq).phoneNo
                  : ""
              }
            ></Item>
          </div>
        )
      })}
    </Container>
  )
}
const Item = (props) => {
  const {
    sendCustName = "DB생명",
    pointName = "강동지점",
    deliverBranch = "한통",
    sendDeliverName = "홍길동",
    sendDeliverTeleNo = "010-1234-5678",
    recvDeliverName = "홍길동",
    recvDeliverTeleNo = "010-1234-5678",
  } = props

  const on = false

  return (
    <FlexSection
      style={{
        position: 'relative',
        width: `${ZeplinPxToMM(1652)}px`,
        height: `${ZeplinPxToMM(802)}px`,
        marginTop: `${ZeplinPxToMM(150)}px`,
        border: on? `${ZeplinPxToMM(8)}px solid #0084d1`:'none',
        borderRadius: `${ZeplinPxToMM(20)}px`,
        flexDirection: "column",
      }}
    >
      <Real>
        <Pstyled2 style={{ position:'absolute', top: 20, left: 90, width: 453, fontSize: sendCustName.length > 10? ZeplinPxToMM(120):ZeplinPxToMM(166.7), textAlign: 'center' }}>{sendCustName}</Pstyled2>
        <Pstyled3 style={{ position:'absolute', top: 100, left: 90, width: 453, fontSize: pointName.length > 10? ZeplinPxToMM(90):ZeplinPxToMM(125), textAlign: 'center' }}>{pointName}</Pstyled3>
        <Pstyled3 style={{ position:'absolute', top: 160, left: 90, width: 453, fontSize: deliverBranch.length > 10? ZeplinPxToMM(90):ZeplinPxToMM(125), textAlign: 'center' }}>{deliverBranch}</Pstyled3>
        <Pstyled5 style={{ position:'absolute', top: 235, left: 90, width: 170, textAlign: 'center' }}>
          {sendDeliverName? `${sendDeliverName}(${sendDeliverTeleNo})`: ''}  
        </Pstyled5>
        <Pstyled5 style={{ position:'absolute', top: 235, left: 360, width: 170, textAlign: 'center'  }}>
          {recvDeliverName? `${recvDeliverName}(${recvDeliverTeleNo})`: ''}
        </Pstyled5>
      </Real>
     
      {on&&<>
      <FlexDiv style={{ width: "100%", height: `${ZeplinPxToMM(312)}px` }}>
        <FlexDiv
          style={{
            width: `${ZeplinPxToMM(312)}px`,
            height: "100%",
            backgroundColor: "#def3ff",
            justifyContent: "flex-start",
            alignItems: "center",
            borderTopLeftRadius: `${ZeplinPxToMM(10)}px`,
          }}
        >
          <Pstyled1 style={{ marginLeft: `${ZeplinPxToMM(15.6)}px` }}>거래처명</Pstyled1>
        </FlexDiv>
        <FlexDiv
          grow={1}
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Pstyled2 style={{ fontSize: pointName.length > 10? ZeplinPxToMM(120):ZeplinPxToMM(166.7) }}>{sendCustName}</Pstyled2> */}
        </FlexDiv>
      </FlexDiv>
      <FlexDiv style={{ width: "100%", height: `${ZeplinPxToMM(190)}px` }}>
        <FlexDiv
          style={{
            width: `${ZeplinPxToMM(312)}px`,
            height: "100%",
            backgroundColor: "#def3ff",
            justifyContent: "flex-start",
            alignItems: "center",
            borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
            borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1`,
          }}
        >
          <Pstyled1 style={{ marginLeft: `${ZeplinPxToMM(15.6)}px` }}>지점명</Pstyled1>
        </FlexDiv>
        <FlexDiv
          grow={1}
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderTop: `${ZeplinPxToMM(2)}px solid #0084d1`,
            borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1`,
          }}
        >
          {/* <Pstyled3 style={{ fontSize: pointName.length > 10? ZeplinPxToMM(90):ZeplinPxToMM(125) }}>{pointName}</Pstyled3> */}
        </FlexDiv>
      </FlexDiv>
      <FlexDiv style={{ width: "100%", height: `${ZeplinPxToMM(190)}px` }}>
        <FlexDiv
          style={{
            width: `${ZeplinPxToMM(312)}px`,
            height: "100%",
            backgroundColor: "#def3ff",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Pstyled1 style={{ marginLeft: `${ZeplinPxToMM(15.6)}px` }}>관할배송지사</Pstyled1>
        </FlexDiv>
        <FlexDiv
          grow={1}
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Pstyled3 style={{ fontSize: deliverBranch.length > 10? ZeplinPxToMM(90):ZeplinPxToMM(125) }}>{deliverBranch}</Pstyled3> */}
        </FlexDiv>
      </FlexDiv>
      <FlexDiv style={{ width: "100%", height: "100%", borderTop: `${ZeplinPxToMM(2)}px solid #0084d1` }}>
        <FlexDiv style={{ minWidth: `${ZeplinPxToMM(826)}px`, height: `100%` }}>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(312)}px`,
              height: "100%",
              backgroundColor: "#def3ff",
              justifyContent: "flex-start",
              alignItems: "center",

              borderBottomLeftRadius: `${ZeplinPxToMM(10)}px`,
            }}
          >
            <FlexDiv style={{ alignItems: "baseline", marginLeft: `${ZeplinPxToMM(15.6)}px` }}>
              <Pstyled1>배송기사</Pstyled1>
              <Pstyled4>이름(연락처)</Pstyled4>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            grow={1}
            style={{
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
            }}
          >
            {/* <Pstyled5 style={{ marginLeft: `${ZeplinPxToMM(10)}px` }}>
              {sendDeliverName}({sendDeliverTeleNo})
            </Pstyled5> */}
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ minWidth: `${ZeplinPxToMM(826)}px`, height: `100%` }}>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(312)}px`,
              height: "100%",
              backgroundColor: "#def3ff",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <FlexDiv style={{ alignItems: "baseline", marginLeft: `${ZeplinPxToMM(15.6)}px` }}>
              <Pstyled1>픽업기사</Pstyled1>
              <Pstyled4>이름(연락처)</Pstyled4>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            grow={1}
            style={{
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <Pstyled5 style={{ marginLeft: `${ZeplinPxToMM(10)}px` }}>
              {recvDeliverName}({recvDeliverTeleNo.substring(3)})
            </Pstyled5> */}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
      </>}
    </FlexSection>
  )
}

export default PouchContainer(PrintPouchTop)
const Container = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const FlexSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`
const Pstyled1 = styled.p`
  font-family: "NanumSquareBold";
  font-size: ${ZeplinPxToMM(37.5)}px;
  line-height: ${ZeplinPxToMM(45)}px;
  letter-spacing: ${ZeplinPxToMM(-1.13)}px;
  color: #0066a2;
`
const Pstyled2 = styled.p`
  font-family: "NanumSquareExtraBold";
  font-size: ${ZeplinPxToMM(166.7)}px;
  line-height: ${ZeplinPxToMM(200)}px;
  letter-spacing: ${ZeplinPxToMM(-5)}px;
  white-space: pre-wrap;
  color: #000;
`
const Pstyled3 = styled.p`
  font-family: "NanumSquareExtraBold";
  font-size: ${ZeplinPxToMM(125)}px;
  line-height: ${ZeplinPxToMM(200)}px;
  letter-spacing: ${ZeplinPxToMM(-5)}px;
  white-space: pre-wrap;
  color: #000;
`
const Pstyled4 = styled.p`
  font-family: "NanumSquareBold";
  font-size: ${ZeplinPxToMM(27.1)}px;
  line-height: ${ZeplinPxToMM(45)}px;
  letter-spacing: ${ZeplinPxToMM(-0.81)}px;
  white-space: pre-wrap;
  color: #0066a2;
`
const Pstyled5 = styled.p`
  font-family: "NanumSquareBold";
  font-size: ${ZeplinPxToMM(41.7)}px;
  line-height: ${ZeplinPxToMM(50)}px;
  letter-spacing: ${ZeplinPxToMM(-1.25)}px;
  white-space: pre-wrap;
  color: #000;
`
const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.alignItems || "normal"};

  flex: ${(props) => props.flex || "0 1 auto"};

  flex-grow: ${(props) => props.grow || "0"};
  flex-shrink: ${(props) => props.shrink || "1"};
  flex-basis: ${(props) => props.basis || "auto"};

  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`



const Real = styled.div`
  position: absolute; 
`