import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import styled from "styled-components"
import BasicPopup from "../../popups/basicPopup"
import FotterButton from "../component/common/fotterButton"
import InputBox from "../component/common/inputBox"
import Layout from "../component/common/layout"
import moment from "moment"
import PageName from "../component/common/pageName"
import PointContainer from "../../containers/pointContainer"
import ZipCodePopup from "../../popups/zipCodePopup"

import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import searchIcon from "../../images/search.png"
import dropIcon from "../../images/drop.png"

const tempList = [
  { seq: 0, name: "운영" },
  { seq: 1, name: "폐쇄" },
]
const PointNew = (props) => {
  const { _point, postPoint, patchPoint, deletePoint, _customer, getPointCheckId, _drivers, getDrivers, _branchs, getBranchs } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const hasKeys = Object.keys(_point).length > 0

  const [sendBranchName, setSendBranchName] = useState(hasKeys ? (_point.branch ? _point.branch.branchName : "") : "")
  const [sendBranchPopup, setSendBranchPopup] = useState(false)
  
  const [popup, setPopup] = useState(false)
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  const [pointNo, setPointNo] = useState(hasKeys ? _point.pointNo : "") // 지점코드
  const [pointName, setPointName] = useState(hasKeys ? _point.pointName : "") // 지점명
  const [teleNo, setTeleNo] = useState(hasKeys ? _point.teleNo : "") // 전화 번호
  const [faxNo, setFaxNo] = useState(hasKeys ? _point.faxNo : "") // 팩스 번호
  const [empName, setEmpName] = useState(hasKeys ? _point.empName : "") // 담당자
  const [deptName, setDeptName] = useState(hasKeys ? _point.deptName : "") // 부서명
  const [phoneNo, setPhoneNo] = useState(hasKeys ? _point.phoneNo : "") // 휴대폰 번호
  const [email, setEmail] = useState(hasKeys ? _point.email : "") // 이메일
  const [driver, setDriver] = useState(
    hasKeys && _point.driver ? { seq: _point.driver.driverSeq, name: _point.driver.driverName } : { seq: -1, name: "선택" }
  ) // 배송기사
  const [visitTimes, setVisitTimes] = useState(hasKeys ? _point.visitTimes : "") // 방문 시간대
  const [addr, setAddr] = useState(hasKeys ? _point.addr : "") // 주소
  const [addrDetail, setAddrDetail] = useState(hasKeys ? _point.addrDetail : "") // 세금계산서주소
  const [zipCode, setZipCode] = useState(hasKeys ? _point.zipCode : "") // 우편 번호
  const [opType, setOpType] = useState(
    hasKeys && _point.opType !== -1 ? { seq: _point.opType, name: tempList.find((i) => i.seq === _point.opType).name } : { seq: -1, name: "선택" } // 지점 운영 상태
  )
  const [openDate, setOpenDate] = useState(hasKeys ? _point.openDate : moment()) // 지점 개설일자
  const [closeDate, setCloseDate] = useState(hasKeys ? _point.closeDate : null) // 지점 폐쇄일자
  const [userId, setUserId] = useState(hasKeys ? _point.userId : "") //아이디
  const [password, setPassword] = useState("") //비밀번호
  const [remark, setRemark] = useState(hasKeys ? _point.remark : "") // 비고
  const [idDesc, setIdDesc] = useState(hasKeys ? 1 : 0)
  const idDescArr = ["", "사용할 수 있는 아이디입니다", "사용할 수 없는 아이디입니다"]
  //onClick...
  const onClickCancel = () => {
    navigate("/app/point", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }

  const onClickSave = async () => {
    if (sendBranchName === "" || pointName === "" || teleNo === "" || addr === "" || addrDetail === "" || userId === "" || idDesc !== 1 || (!hasKeys && password === "")) {
      setSaveCheckPopup(true)
      return
    }

    let temp = {
      custSeq: _customer.custSeq,
      pointName: pointName,
      teleNo: teleNo,
      faxNo: faxNo,
      empName: empName,
      deptName: deptName,
      phoneNo: phoneNo,
      email: email,
      driverSeq: driver.seq,
      visitTimes: visitTimes,
      addr: addr,
      addrDetail: addrDetail,
      zipCode: zipCode,
      opType: opType.seq,
      openDate: moment(openDate).format("YYYY-MM-DD"),
      closeDate: closeDate === null ? null : moment(closeDate).format("YYYY-MM-DD"),
      id: userId,
      remark: remark,
      branchSeq: _branchs.find((v) => v.branchName === sendBranchName).branchSeq,
    }
    if (password !== "") {
      temp = { ...temp, password: password }
    }
    if (!hasKeys) {
      await postPoint({ ...temp })
    } else {
      await patchPoint({ ...temp, pointSeq: _point.pointSeq })
    }
    navigate("/app/point")
  }
  const onFocusIn = () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    if (userId === "") return
    let result
    if (!hasKeys) {
      //등록
      result = getPointCheckId({ id: userId })
      if (result) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    } else {
      //수정
      result = getPointCheckId({ id: userId, branchSeq: _point.branchSeq })
      if (result) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    }
  }
  //end onClick...
  const getData = async () => {
    await getDrivers({})
    await getBranchs({})
  }
  useEffect(() => {
    getData()
  }, [])

  const onClickSendBranch = () => {
    setSendBranchPopup(true)
  }

  return (
    <Layout navi={"거래처관리"}>
      <SEO title={"거래처관리"} />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setAddr} setZipCode={setZipCode} />
      <PageName title={["거래처관리", "상세", hasKeys ? "지점상세" : "지점등록"]} style={{ marginBottom: "40px" }}></PageName>
      <PopupStyled2
        open={sendBranchPopup}
        setOpen={setSendBranchPopup}
        branchs={_branchs}
        branchName={sendBranchName}
        setBranchName={setSendBranchName}
      ></PopupStyled2>
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={async (value) => {
          if (value === "확인") {
            await deletePoint({ pointSeq: _point.pointSeq })
            navigate("/app/point")
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />

      <div style={{ display: "flex", marginTop: "30px" }}>
        <AlignDiv>
          <InputBox label="지점코드" readOnly={true} value={pointNo} setValue={setPointNo} style={{ marginRight: "20px" }}></InputBox>
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox label="지점명" value={pointName} setValue={setPointName} style={{ marginRight: "20px" }}></InputBox>
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox label="전화번호" value={teleNo} setValue={setTeleNo}></InputBox>
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox label="팩스번호" value={faxNo} setValue={setFaxNo}></InputBox>
        </AlignDiv>

        <InputBox
          type={"address"}
          label="지사"
          value={sendBranchName}
          setValue={setSendBranchName}
          marginLeft={12}
          onClick={onClickSendBranch}
        ></InputBox>

      </div>

      <Splitline mt={17} mb={20}></Splitline>

      <div style={{ display: "flex" }}>
        <AlignDiv>
          <InputBox label="담당자" value={empName} setValue={setEmpName}></InputBox>
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox label="부서명" value={deptName} setValue={setDeptName}></InputBox>
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox label="휴대폰번호" value={phoneNo} setValue={setPhoneNo}></InputBox>
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox label="이메일" value={email} setValue={setEmail}></InputBox>
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox
            type={"select"}
            label="배송기사"
            item={_drivers.filter((i) => i.isOkcheon === "0").map((i) => ({ seq: i.driverSeq, name: i.driverName }))}
            value={driver.name}
            setValue={(value) => {
              setDriver(value)
            }}
          />
        </AlignDiv>

        <AlignDiv ml={12}>
          <InputBox label="방문시간대" value={visitTimes} setValue={setVisitTimes}></InputBox>
        </AlignDiv>
      </div>

      <Splitline mt={17} mb={20}></Splitline>

      <div style={{ display: "flex" }}>
        <AlignDiv>
          <InputBox type={"address"} label="주소" value={addr} setValue={setAddr} onClick={() => setZipCodePopup(true)}></InputBox>
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox label="상세주소" value={addrDetail} setValue={setAddrDetail}></InputBox>
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox label="우편번호" readOnly={true} value={zipCode} setValue={setZipCode}></InputBox>
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox type={"select"} label="지점운영상태" item={tempList} value={opType.name} setValue={setOpType} />
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox type={"date"} label="지점개설일자" value={openDate} setValue={setOpenDate} />
        </AlignDiv>
        <AlignDiv ml={12}>
          <InputBox type={"date"} noSelectable={true} label="지점폐쇄일자" value={closeDate} setValue={setCloseDate} />
        </AlignDiv>
      </div>

      <Splitline mt={17} mb={20}></Splitline>

      <div style={{ display: "flex" }}>
        <AlignDiv flex={1}>
          <InputBox fullWidth={true} label="아이디" value={userId} setValue={setUserId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
          <div style={{ display: "flex", height: "20px" }}>
            <IDdesc isEnable={idDesc === 1}>{idDescArr[idDesc]}</IDdesc>
          </div>
        </AlignDiv>

        <AlignDiv flex={1} ml={12}>
          <InputBox fullWidth={true} isPassword={true} label="비밀 번호" value={password} setValue={setPassword}></InputBox>
        </AlignDiv>
      </div>

      <Splitline mb={20}></Splitline>

      <div style={{ display: "flex" }}>
        <AlignDiv flex={1}>
          <InputBox fullWidth={true} label="비고" value={remark} setValue={setRemark}></InputBox>
        </AlignDiv>
      </div>
      <Splitline mt={17} mb={20}></Splitline>

      {!hasKeys ? (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton
          onClickCancel={onClickCancel}
          onClickSave={onClickSave}
          onClickDelete={() => {
            setPopup(true)
          }}
        ></FotterButton>
      )}
    </Layout>
  )
}

export default PointContainer(PointNew)

const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`
const Section = styled.section`
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const AlignDiv = styled.div`
  flex: ${(props) => (props.flex ? 1 : 0)};
  margin-left: ${(props) => props.ml || 0}px;
  margin-right: ${(props) => props.mr || 0}px;
`

const useStyles = makeStyles(() => ({
  container1: {
    "& .MuiPaper-root": {
      width: "400px",
      height: "448px",
      padding: "0",
    },
    "& .MuiDialog-paper": {},
  },
  container: {
    "& .MuiPaper-root": {
      width: "300px",
      height: "448px",
      padding: "0",
    },
    "& .MuiDialog-paper": {},
  },
}))

const PopupStyled2 = (props) => {
  const classes = useStyles()
  const { open, setOpen, branchs, branchName, setBranchName } = props
  const [index, setIndex] = useState(2)
  const [text, setText] = useState("")
  const [messageIndex, setMessageIndex] = useState(0)
  const [popup, setPopup] = useState(false)
  const [select, setSelect] = useState(false)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const item = ["선택", "코드", "이름"]
  const [searchResult, setSearchResult] = useState([])
  const [tempBranch, setTempBranch] = useState()
  const [isPressEnter, setIsPressEnter] = useState(false)
  const onKeyPress = (e) => {
    if (isPressEnter) return
    if (e.key === "Enter") {
      setIsPressEnter(true)
      onClickSearch()
    }
  }
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      setIsPressEnter(false)
    }
  }
  const onClickSearch = () => {
    if (index === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } else if (text.length < 2) {
      setMessageIndex(2)
      setPopup(true)
      return
    }
    if (index === 1) {
      setSearchResult(branchs.filter((i) => i.branchNo.toString().includes(text)))
    } else if (index === 2) {
      setSearchResult(branchs.filter((i) => i.branchName.toString().includes(text)))
    }
  }
  useEffect(() => {
    if (branchName) {
      const findItem = branchs.find((i) => i.branchName === branchName)
      if (findItem !== undefined) {
        setTempBranch(findItem)
        setSearchResult([findItem])
      } else {
        setTempBranch()
        setSearchResult([])
      }
    }
  }, [branchName])
  return (
    <Dialog className={classes.container} open={open} fullWidth={true}>
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <div>
        <div style={{ display: "flex", width: "300px", height: "406px", padding: "20px", paddingBottom: "0px", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
            <p style={{ fontFamily: "NanumSquareRegular", fontSize: "14px", lineHeight: "24.5px", letterSpacing: "-0.42px", color: "#101010" }}>
              검색
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <Container>
              <SelectBox>
                <StyledSelect onClick={() => setSelect(!select)} onBlur={() => setSelect(false)}>
                  <StyledP select={item[index]}>{item[index]}</StyledP>
                  <img style={{ width: "8px", height: "6px", marginRight: "6px" }} src={dropIcon} alt={"드롭 아이콘"} />
                </StyledSelect>
                <OptionBox open={select} onMouseDown={(e) => e.preventDefault()}>
                  {item.map((option, optionIndex) => (
                    <Option
                      key={optionIndex}
                      onClick={() => {
                        setIndex(optionIndex)
                        setSelect(false)
                      }}
                    >
                      {option}
                    </Option>
                  ))}
                </OptionBox>
              </SelectBox>

              <SearchBox>
                <InputStyled value={text} onChange={(e) => setText(e.target.value)} onKeyPress={onKeyPress} onKeyUp={onKeyUp} />
                <SearchBtn onClick={onClickSearch}>
                  <img style={{}} src={searchIcon} alt={"검색 아이콘"} />
                </SearchBtn>
              </SearchBox>
            </Container>
          </div>
          <div style={{ display: "flex", marginTop: "10px", width: "100%", height: "34px", backgroundColor: "#eef2f7", alignItems: "center" }}>
            <span>
              <p
                style={{
                  fontFamily: "NanumSquareRegular",
                  fontSize: "13px",
                  lineHeight: "15px",
                  letterSpacing: "-0.39px",
                  color: "#445e78",
                  marginLeft: "10px",
                }}
              >
                코드
              </p>
            </span>
            <span style={{ marginLeft: "85px" }}>
              <p style={{ fontFamily: "NanumSquareRegular", fontSize: "13px", lineHeight: "15px", letterSpacing: "-0.39px", color: "#445e78" }}>
                이름
              </p>
            </span>
          </div>
          <div style={{ display: "flex", overflowY: "scroll", flexDirection: "column", height: "270px", marginRight: "-15px" }}>
            {searchResult.map((v, i) => {
              return (
                <div key={i} style={{ height: "50px", borderBottom: "1px solid #dbdee5", display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", width: "80px", height: "100%", alignItems: "center", paddingLeft: "10px" }}>
                    <PStyled1 style={{ wordBreak: "break-all" }}>{v.branchNo}</PStyled1>
                  </div>
                  <div style={{ display: "flex", width: "80px", flex: "1", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <PStyled1 style={{ wordBreak: "break-all" }}>{v.branchName}</PStyled1>
                  </div>
                  <div style={{ display: "flex", width: "70px", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <button
                      style={{
                        width: "50px",
                        height: "30px",
                        borderRadius: "4px",
                        color: "#00adee",
                        border: "1px solid #00adee",
                        backgroundColor: tempBranch === v ? "#00adee" : "#fff",
                      }}
                      onClick={() => {
                        if (tempBranch === v) setTempBranch()
                        else setTempBranch(v)
                      }}
                    >
                      {tempBranch === v && <PStyled1 style={{ color: "#fff" }}>해제</PStyled1>}
                      {tempBranch !== v && <PStyled1 style={{ color: "#00adee" }}>선택</PStyled1>}
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <ButtonView>
          {["취소", "확인"].map((v, i) => {
            return (
              <Button
                key={i}
                one={i === 0}
                onClick={() => {
                  setOpen(false)
                  if (v === "취소") {
                    setIndex(2)
                    setText("")
                    //
                  } else if (v === "확인") {
                    if (tempBranch) {
                      setBranchName(tempBranch.branchName)
                      setSearchResult([tempBranch])
                    } else {
                      setBranchName("")
                      setSearchResult([])
                    }
                    setIndex(2)
                    setText("")
                  }
                }}
              >
                {v}
              </Button>
            )
          })}
        </ButtonView>
      </div>
    </Dialog>
  )
}

const PStyled1 = styled.p`
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const ButtonView = styled.section`
  display: flex;
  height: 42px;
`

const Button = styled.button`
  background-color: #ffffff;
  width: 100%;
  padding: 0px;
  border-top: 1px solid #dbdee5;
  border-radius: 0;
  color: ${(props) => (props.one ? "#8a8e98" : "#00adee")};
  font-size: 14px;
  font-family: NanumSquareExtraBold;
  line-height: 24.45px;
  letter-spacing: -0.42px;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 410px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`

// SELECT
const SelectBox = styled.div`
  position: relative;
  width: 69px;
  height: 40px;
  z-index: 100;
`
const StyledSelect = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 42px;
  left: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: 69px;
  max-height: 160px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const Option = styled.button`
  overflow-x: hidden;
  display: block;
  width: 100%;
  /* min-width: 100px; */
  height: 40px;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  border-top: 1px solid #dbdee5;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-top: none;
  }
`

// SEARCH
const SearchBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-left: 1px solid #dbdee5;
`
const InputStyled = styled.input`
  background-color: ${(props) => (props.readOnly ? "#eef2f7" : "#fff")};
  flex: 1;
  padding-left: 10px;
  max-width: 150px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const SearchBtn = styled.button`
  background-color: #00adee;
  width: 40px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
const StyledP = styled.p`
  margin-left: 10px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: ${(props) => (props.select === "선택" ? "#acb0bb" : "#35363a")};
`

