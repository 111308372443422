import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import Footer from "../component/common/footer"
import BasicPopup from "../../popups/basicPopup"
import LoginLogo from "../../images/loginLogo.png"
import User from "../../images/user.png"
import Pw from "../../images/pw.png"
import SigninContainer from "../../containers/signinContainer"
const SignIn = (props) => {
  const { postSignin } = props
  const [focus, setFocus] = useState(-1)
  const [popup, setPopup] = useState(false)
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [isPressEnter, setIsPressEnter] = useState(false)
  const onKeyPress = (e) => {
    if (isPressEnter) return
    if (e.key === "Enter") {
      setIsPressEnter(true)
      onClickLogin()
    }
  }
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      setIsPressEnter(false)
    }
  }
  //onClick ...
  const onClickLogin = async () => {
    if (id === "" || password === "") {
      setPopup(true)
      return
    } else {
      const result = await postSignin({ id: id, password: password })
      if (result) {
        navigate("/app/main")
      } else {
        setPopup(true)
      }
    }
  }
  //end onClick...
  return (
    <Container>
      <SEO title={"로그인"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={"아이디와 비밀번호를 다시 확인해주세요."} selectedValue={["확인"]} />
      <LoginBox>
        <LogoImg src={LoginLogo} alt={"로그인 로고 이미지"} />
        <InputBox marginTop={30} borderColor={focus === 0 ? "#35363a" : "#dddde5"}>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: 14, height: 14, marginRight: 7.5 }}>
            <img style={{ width: 14, height: 14 }} src={User} alt={"아이디 이미지"} />
          </div>
          <input
            onFocus={() => setFocus(0)}
            onBlur={() => setFocus(-1)}
            type={"text"}
            placeholder={"아이디를 입력해주세요."}
            value={id}
            onChange={(e) => setId(e.target.value)}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
          />
        </InputBox>
        <InputBox marginTop={10} borderColor={focus === 1 ? "#35363a" : "#dddde5"}>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: 14, height: 14, marginRight: 7.5 }}>
            <img style={{ width: 13, height: 16 }} src={Pw} alt={"비밀번호 이미지"} />
          </div>
          <input
            onFocus={() => setFocus(1)}
            onBlur={() => setFocus(-1)}
            type={"password"}
            placeholder={"비밀번호를 입력해주세요."}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
          />
        </InputBox>
        <Button onClick={onClickLogin}>로그인</Button>
      </LoginBox>
      <Footer />
    </Container>
  )
}

export default SigninContainer(SignIn)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`
const LogoImg = styled.img`
  width: 160px;
  height: auto;
`
const InputBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 340px;
  height: 54px;
  margin-top: ${(props) => props.marginTop || 0}px;
  padding: 0 20px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;

  & > input {
    flex: 1;
    height: 100%;
    border-radius: 4px;
    font-size: 15px;
    line-height: 26.2px;
    letter-spacing: -0.45px;
  }
`
const Button = styled.button`
  background-color: #00adee;
  width: 340px;
  height: 54px;
  margin-top: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-family: NanumSquareBold;
  line-height: 28px;
  letter-spacing: -0.48px;
`
