import React, { useState } from "react"
import styled from "styled-components"
import SEO from "../seo"
import Layout from "../component/common/layout"
import BasicPopup from "../../popups/basicPopup"
import PageName from "../component/common/pageName"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import { navigate } from "gatsby"
import CheckBox from "../component/common/checkbox"
import EmployeeContainer from "../../containers/employeeContainer"
const EmployeeNew = (props) => {
  const { _employee, postEmployee, patchEmployee, deleteEmployee, initEmployee, getEmployeeCheckId } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const isHasKeys = Object.keys(_employee).length > 0
  const [popup, setPopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  const [type, setType] = useState(isHasKeys ? (_employee.empSeq ? 1 : 0) : 0) //0:등록, 1:상세
  const [auth, setAuth] = useState(isHasKeys ? (_employee.auth ? true : false) : false) //false:언체크, true:체크
  const [empName, setEmpName] = useState(isHasKeys ? _employee.empName : "") //사원명
  const [region, setRegion] = useState(isHasKeys ? _employee.region : "") //관할구역
  const [area, setArea] = useState(isHasKeys ? _employee.area : "") //지역
  const [phoneNo, setPhoneNo] = useState(isHasKeys ? _employee.phoneNo : "") //휴대폰번호

  const [id, setId] = useState(isHasKeys ? _employee.userId : "") //아이디
  const [password, setPassword] = useState("") //비밀번호

  const [idDesc, setIdDesc] = useState(isHasKeys ? 1 : 0)
  const idDescArr = [" ", "사용할수있는 아이디 입니다", "사용할수없는 아이디 입니다"]
  //onClick...
  const onClickCancel = async () => {
    await initEmployee()
    navigate("/app/employee", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }
  const onClickSave = async () => {
    if (empName === "" || region === "" || area === "" || phoneNo === "" || id === "" || (!isHasKeys && password === "")) {
      setSaveCheckPopup(true)
      return
    }
    //등록일 경우.
    if (!isHasKeys) {
      const result = await postEmployee({
        id: id,
        password: password,
        empName: empName,
        region: region,
        area: area,
        phoneNo: phoneNo,
        auth: auth ? 1 : 0,
      })
      if (result) {
        navigate("/app/employee")
      }
    }
    //수정일 경우.
    else {
      let temp = {
        empSeq: _employee.empSeq,
        id: id,
        empName: empName,
        region: region,
        area: area,
        phoneNo: phoneNo,
        auth: auth ? 1 : 0,
      }
      if (password !== "") {
        temp = { ...temp, password: password }
      }
      const result = await patchEmployee({ ...temp })
      if (result) {
        navigate("/app/employee")
      }
    }
  }
  const onClickDelete = async () => {
    const result = await deleteEmployee({
      empSeq: _employee.empSeq,
    })
    if (result) {
      await initEmployee()
      setPopup(false)
      navigate("/app/employee")
    }
  }
  const onFocusIn = async () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    if (id === "") return
    //신규등록
    if (!isHasKeys) {
      const result = await getEmployeeCheckId({ id: id })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    } else {
      //수정
      const result = await getEmployeeCheckId({ id: id, empSeq: _employee.empSeq })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    }
  }
  //end onClick...
  return (
    <Layout navi={"사원관리"}>
      <SEO title={"사원관리"} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <PageName title={["사원관리", isHasKeys ? "상세" : "등록"]} style={{ marginBottom: "40px" }}></PageName>
      <div style={{ display: "flex", alignItems: "center", padding: "20px 0" }}>
        <CheckBox label={"관리자 권한 부여"} value={auth} setValue={setAuth} style={{ marginRight: "15px", width: "20px", height: "20px" }} />
        <Section></Section>
      </div>
      <BasicPopup
        open={popup}
        onClose={async (value) => {
          if (value === "확인") {
            onClickDelete()
          } else {
            setPopup(false)
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <Splitline mb={17}></Splitline>

      <Section id="InputBox">
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <InputBox label="사원명" value={empName} setValue={setEmpName}></InputBox>
          <InputBox label="관할구역" value={region} setValue={setRegion} marginLeft={10}></InputBox>
          <InputBox label="지역" value={area} setValue={setArea} marginLeft={10}></InputBox>
          <InputBox label="휴대폰 번호" value={phoneNo} setValue={setPhoneNo} marginLeft={10}></InputBox>
        </div>
      </Section>

      <Splitline mb={17}></Splitline>
      <Section id="InputBox">
        <AlignDiv flex={1}>
          <InputBox fullWidth={true} label="아이디" value={id} setValue={setId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
          <div style={{ height: "20px" }}>
            <IDdesc isEnable={idDesc === 1}>{idDescArr[idDesc]}</IDdesc>
          </div>
        </AlignDiv>

        <AlignDiv flex={1}>
          <InputBox fullWidth={true} isPassword={true} label="비밀 번호" value={password} setValue={setPassword}></InputBox>
        </AlignDiv>
      </Section>

      <Splitline mb={20}></Splitline>

      <Section id="FotterButton">
        {!isHasKeys ? (
          <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
        ) : (
          <FotterButton
            onClickCancel={onClickCancel}
            onClickSave={onClickSave}
            onClickDelete={() => {
              setPopup(true)
            }}
          ></FotterButton>
        )}
      </Section>
    </Layout>
  )
}

export default EmployeeContainer(EmployeeNew)

const PStyled = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`

const SectionName = styled.p`
  font-family: NanumSquareBold;
  font-size: 12px;
  margin-bottom: 20px;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
`

const Section = styled.section`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`
const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`

const AlignDiv = styled.div`
  flex: ${(props) => (props.flex ? 1 : 0)};
  margin-left: ${(props) => (props.start ? "0px" : "6px")};
  margin-right: ${(props) => (props.end ? "0px" : "6px")};
`
