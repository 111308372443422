import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"
const { getDrivers, getDriverPouchs, initDriverPouchs } = actions.DriverAction
// redux state > component mapping
const mapStateToProps = (state) => ({
  _drivers: state.DriverReducer._drivers,
  _driverPouchs: state.DriverReducer._driverPouchs,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getDrivers: (param) => dispatch(getDrivers(param)),
  getDriverPouchs: (param) => dispatch(getDriverPouchs(param)),
  initDriverPouchs: () => dispatch(initDriverPouchs()),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
