import React, { useEffect, useState } from "react"
import SEO from "../seo"
import styled from "styled-components"
import moment from "moment"
import { navigate } from "gatsby"
import Button from "../component/common/button"
import Category from "../component/common/category"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Pagination from "../component/common/pagination"
import Search from "../component/common/search"
import SectionName from "../component/common/sectionName"
import CustomerContainer from "../../containers/customerContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const Customer = (props) => {
  const { initCustomer, _customers, getCustomer, _customersTotal, getCustomers, getDownloadExcel } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  //검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")
  //페이지 네이션
  const [current, setCurrent] = useState(searchInfoCurrent || 1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }

    let temp = { page: 0, count: 20 }
    if (tempSearchIndex !== 0){ //&& tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getCustomers({ ...temp })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "거래처리스트" })
  }
  const onClickRegister = async () => {
    await initCustomer()
    navigate("/app/customerNew")
  }
  //end onClick...
  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getCustomers({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])
  return (
    <Layout navi={"거래처관리"}>
      <SEO title={"거래처관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["거래처관리"]}></PageName>

      {<SectionName>거래처관리</SectionName>}

      <Section>
        <Search
          item={["선택", "거래처코드", "거래처명", "대표자", "주소", "전화번호", "등록일자", "등록자"]}
          index={tempSearchIndex}
          setIndex={setTempSearchIndex}
          text={tempSearchText}
          setText={setTempSearchText}
          onClickSearch={onClickSearch}
        />
        <DivBtn>
          <Button onClick={onClickExcelSave} style={{ marginRight: "3px" }}>
            엑셀 저장
          </Button>

          <Button onClick={onClickRegister} style={{ marginLeft: "3px" }}>
            등록
          </Button>
        </DivBtn>
      </Section>

      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>거래처코드</th>
            <th>거래처명</th>
            <th>대표자</th>
            <th>주소</th>
            <th>전화번호</th>
            <th>등록일자</th>
            <th>등록자</th>
          </tr>
        </thead>
        <tbody>
          {_customers &&
            _customers.map((v, i) => (
              <tr key={i}>
                <td>{_customersTotal - (current - 1) * 20 - i}</td>
                <td>{v.custNo}</td>
                <td>
                  <button
                    onClick={async () => {
                      await getCustomer({ custSeq: v.custSeq })
                      navigate("/app/customerNew", {
                        state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                      })
                    }}
                  >
                    <u>{v.custName}</u>
                  </button>
                </td>
                <td>{v.repName}</td>
                <td>
                  {v.addr}&nbsp;{v.addrDetail}
                </td>
                <td>{v.teleNo}</td>
                <td>{moment(v.regDatetime).format("yyyy.MM.DD")}</td>
                <td>{v.registName}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <Pagination current={current} setCurrent={setCurrent} total={_customersTotal} />
    </Layout>
  )
}

export default CustomerContainer(Customer)

const Section = styled.section`
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`

const DivBtn = styled.div`
  margin-inline-start: auto;
`
