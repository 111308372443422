import React, { useState, useEffect } from "react"
import SEO from "../seo"
import { navigate } from "gatsby"
import styled from "styled-components"
import ReceiptContainer from "../../containers/receiptContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import BasicPopup from "../../popups/basicPopup"
import ZipCodePopup from "../../popups/zipCodePopup"

const ReceiptDocuNew = (props) => {
  const { _document, postDocument, _points, _branchs, patchDocument, deleteDocument, getPoints, getBranchs, getPointToBranch } = props
  console.log("NewPage", props.location.state)
  const {
    searchInfoTap,
    searchInfoStartDate,
    searchInfoEndDate,
    serachInfoIndex,
    searchInfoText,
    searchInfoIsScan,
    searchInfoCurrent,
  } = props.location.state
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [deletePopup, setDeletePopup] = useState(false)
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [waybillNum, setWaybillNum] = useState("") //운송장번호
  const [senderCustomer, setSenderCustomer] = useState({ seq: -1, name: "선택" })
  const [sender, setSender] = useState("")
  const [recipientCustomer, setRecipientCustomer] = useState({ seq: -1, name: "선택" })
  const [recipient, setRecipient] = useState("")
  //
  const [recipientPhone, setRecipientPhone] = useState("")
  const [addr, setAddr] = useState("")
  const [addrDetail, setAddrDetail] = useState("")
  const [zipCode, setZipCode] = useState("")
  //
  const [originatingBranch, setOriginatingBranch] = useState({ seq: -1, name: "선택" })
  const [receivingBranch, setReceivingBranch] = useState({ seq: -1, name: "선택" })
  const [contents, setContents] = useState("")
  const [qty, setQty] = useState("")

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (Object.keys(_document).length === 0) return
    if (Object.keys(_points).length === 0) return
    if (Object.keys(_branchs).length === 0) return

    setWaybillNum(_document.docNo)
    setSender(_document.sendEmpName)
    const sendPoint = _points.find((i) => i.pointSeq === _document.sendPointSeq)
    const recvPoint = _points.find((i) => i.pointSeq === _document.recvPointSeq)
    setSenderCustomer({ seq: sendPoint.pointSeq, name: sendPoint.pointName })
    setRecipient(_document.recvEmpName)
    setRecipientCustomer({ seq: recvPoint.pointSeq, name: recvPoint.pointName })
    setRecipientPhone(_document.recvTeleNo)
    setAddr(_document.recvAddr)
    setAddrDetail(_document.recvAddrDetail)
    setZipCode(_document.recvZipCode)
    const sendBranch = _branchs.find((i) => i.branchSeq === _document.sendBranchSeq)
    const recvBranch = _branchs.find((i) => i.branchSeq === _document.recvBranchSeq)
    if (sendBranch !== undefined) {
      setOriginatingBranch({ seq: sendBranch.branchSeq, name: sendBranch.branchName })
    } else {
      setOriginatingBranch({ seq: -1, name: "" })
    }
    if (recvBranch !== undefined) {
      setReceivingBranch({ seq: recvBranch.branchSeq, name: recvBranch.branchName })
    } else {
      setReceivingBranch({ seq: -1, name: "" })
    }

    setContents(_document.content)
    setQty(_document.qty)
  }, [_document, _points, _branchs])
  const getData = async () => {
    await getPoints({})
    await getBranchs({})
  }

  const onClickSave = async () => {
    if (
      senderCustomer.seq === -1 ||
      recipientCustomer.seq === -1 ||
      sender === "" ||
      recipient === "" ||
      recipientPhone === "" ||
      addr === "" ||
      addrDetail === "" ||
      zipCode === "" ||
      originatingBranch.seq === -1 ||
      receivingBranch.seq === -1 ||
      contents === "" ||
      qty === "" ||
      qty === "0"
    )
      setPopup({ open: true, text: "빈 칸이 존재합니다." })

    let result = false

    if (Object.keys(_document).length === 0) {
      result = await postDocument({
        sendPointSeq: senderCustomer.seq, //발신지점코드
        recvPointSeq: recipientCustomer.seq, //수신지점코드
        sendEmpName: sender, //발신인
        recvEmpName: recipient, //수신인
        recvTeleNo: recipientPhone, //수신인전화번호
        recvAddr: addr, //수신주소
        recvAddrDetail: addrDetail, //수신상세주소
        recvZipCode: zipCode, //수신우편번호
        sendBranchSeq: originatingBranch.seq, //발신지사코드
        recvBranchSeq: receivingBranch.seq, //수신지사코드
        content: contents, //내용물
        qty: qty.replace(/,/g, ""), //수량
      })
    } else {
      result = await patchDocument({
        docSeq: _document.docSeq,
        sendPointSeq: senderCustomer.seq, //발신지점코드
        recvPointSeq: recipientCustomer.seq, //수신지점코드
        sendEmpName: sender, //발신인
        recvEmpName: recipient, //수신인
        recvTeleNo: recipientPhone, //수신인전화번호
        recvAddr: addr, //수신주소
        recvAddrDetail: addrDetail, //수신상세주소
        recvZipCode: zipCode, //수신우편번호
        sendBranchSeq: originatingBranch.seq, //발신지사코드
        recvBranchSeq: receivingBranch.seq, //수신지사코드
        content: contents, //내용물
        qty: qty.replace(/,/g, ""), //수량
      })
    }
    if (result) navigate(-1)
  }

  return (
    <Layout navi={"접수관리"}>
      <SEO title={"접수관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <BasicPopup
        open={deletePopup}
        onClose={async (value) => {
          if (value === "확인") {
            const result = await deleteDocument({ docSeq: _document.docSeq })
            if (result) navigate(-1)
          }
          setDeletePopup(false)
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setAddr} setZipCode={setZipCode} />
      <section>
        <PageName title={["접수관리", "문서상세"]}></PageName>

        <Box>
          <InputBox readOnly={true} label="운송장번호" value={waybillNum} setValue={setWaybillNum} />
          <InputBox
            type={"select"}
            label="발신거래처지점명"
            marginLeft={12}
            item={
              recipientCustomer.seq === -1
                ? [{ seq: -1, name: "선택" }, ..._points.map((i) => ({ seq: i.pointSeq, name: i.pointName }))]
                : [
                    { seq: -1, name: "선택" },
                    ..._points
                      .filter((i) => i.custSeq === _points.find((i) => i.pointSeq === recipientCustomer.seq).custSeq)
                      .map((i) => ({ seq: i.pointSeq, name: i.pointName })),
                  ]
            }
            value={senderCustomer.name}
            setValue={async (value) => {
              if (recipientCustomer.seq !== -1) {
                const result = await getPointToBranch({ sendPointSeq: value.seq, recvPointSeq: recipientCustomer.seq })
                if (result.send && result.recv) {
                  setOriginatingBranch({ name: result.send.branchName, seq: result.send.branchSeq })
                  setReceivingBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })
                } else {
                  setOriginatingBranch({ name: "", seq: -1 })
                  setReceivingBranch({ name: "", seq: -1 })
                }
              }
              setSenderCustomer(value)
              if (value.seq !== -1) {
                const temp = _points.find((i) => i.pointSeq === value.seq)
                if (
                  temp !== -1 &&
                  !_points
                    .filter((i) => i.custSeq === temp.custSeq)
                    .map((i) => i.pointSeq)
                    .includes(recipientCustomer.seq)
                ) {
                  setRecipientCustomer({ seq: -1, name: "선택" })
                }
              }
            }}
          />
          <InputBox label="발신인" marginLeft={12} value={sender} setValue={setSender} />
          <InputBox
            type={"select"}
            label="수신거래처지점명"
            marginLeft={12}
            item={
              senderCustomer.seq === -1
                ? [{ seq: -1, name: "선택" }, ..._points.map((i) => ({ seq: i.pointSeq, name: i.pointName }))]
                : [
                    { seq: -1, name: "선택" },
                    ..._points
                      .filter((i) => i.custSeq === _points.find((i) => i.pointSeq === senderCustomer.seq).custSeq)
                      .map((i) => ({ seq: i.pointSeq, name: i.pointName })),
                  ]
            }
            value={recipientCustomer.name}
            setValue={async (value) => {
              if (senderCustomer.seq !== -1) {
                const result = await getPointToBranch({ sendPointSeq: senderCustomer.seq, recvPointSeq: value.seq })
                if (result.send && result.recv) {
                  setOriginatingBranch({ name: result.send.branchName, seq: result.send.branchSeq })
                  setReceivingBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })
                } else {
                  setOriginatingBranch({ name: "", seq: -1 })
                  setReceivingBranch({ name: "", seq: -1 })
                }
              }
              setRecipientCustomer(value)
              if (value.seq !== -1) {
                const temp = _points.find((i) => i.pointSeq === value.seq)
                if (
                  temp !== -1 &&
                  !_points
                    .filter((i) => i.custSeq === temp.custSeq)
                    .map((i) => i.pointSeq)
                    .includes(senderCustomer.seq)
                ) {
                  setSenderCustomer({ seq: -1, name: "선택" })
                }
              }
            }}
          />
          <InputBox label="수신인" marginLeft={12} value={recipient} setValue={setRecipient} />
        </Box>
        <Box>
          <InputBox label="수신인전화번호" value={recipientPhone} setValue={setRecipientPhone} />
          <InputBox type={"address"} label="수신주소" marginLeft={12} value={addr} setValue={setAddr} onClick={() => setZipCodePopup(true)} />
          <InputBox label="상세주소" marginLeft={12} value={addrDetail} setValue={setAddrDetail} />
          <InputBox readOnly={true} label="우편번호" marginLeft={12} value={zipCode} setValue={setZipCode} />
        </Box>
        <Box>
          <InputBox label="발신지사" readOnly={true} value={originatingBranch.name} setValue={setOriginatingBranch} />
          <InputBox label="수신지사" marginLeft={12} readOnly={true} value={receivingBranch.name} setValue={setReceivingBranch} />
          <InputBox label="내용물" marginLeft={12} value={contents} setValue={setContents} />
          <InputBox type={"qty"} label="수량" marginLeft={12} value={Number(qty.toString().replace(/,/g, "")).toLocaleString()} setValue={setQty} />
        </Box>

        <div style={{ marginTop: 20 }}>
          {Object.keys(_document).length === 0 ? (
            <FotterButton
              onClickCancel={() =>
                navigate("/app/receipt", {
                  state: {
                    searchInfoTap: 0,
                    searchInfoStartDate,
                    searchInfoEndDate,
                    serachInfoIndex,
                    searchInfoText,
                    searchInfoIsScan,
                    searchInfoCurrent,
                  },
                })
              }
              onClickSave={onClickSave}
            ></FotterButton>
          ) : (
            <FotterButton
              onClickCancel={() =>
                navigate("/app/receipt", {
                  state: {
                    searchInfoTap: 0,
                    searchInfoStartDate,
                    searchInfoEndDate,
                    serachInfoIndex,
                    searchInfoText,
                    searchInfoIsScan,
                    searchInfoCurrent,
                  },
                })
              }
              onClickSave={onClickSave}
              onClickDelete={() => setDeletePopup(true)}
            ></FotterButton>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ReceiptContainer(ReceiptDocuNew)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  padding-top: 17px;
  padding-bottom: 20px;
  border-bottom: 4px solid #fafafb;
`
