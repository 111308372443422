import React from "react"
import styled from "styled-components"
import nextPageIcon from "../../../images/page-right.png"
import prevPageIcon from "../../../images/page-left.png"

const Pagination = (props) => {
  const { current, setCurrent, total } = props

  const toPrevPage = () => {
    if (current > 1) setCurrent(current - 1)
  }
  const toNextPage = () => {
    if (total > current * 20) setCurrent(current + 1)
  }
  const renderPages = () => {
    let isBeforeRender = false
    let firstNum = parseInt((current - 1) / 10) * 10 + 1
    let lastNum = parseInt((total - 1) / 20) + 1
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((v, i) => {
      if (total <= (firstNum - 1 + v) * 20) {
        if (!(current === 1 && v === 0)) return null
      }
      if (v !== 0 && lastNum !== v + firstNum) {
        if (Math.abs(current - (firstNum + v)) > 2) {
          if (isBeforeRender) {
            isBeforeRender = false
            return (
              <div
                key={i}
                style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "6px" }}
              >
                {"…"}
              </div>
            )
          } else {
            return null
          }
        }
      }

      isBeforeRender = true
      return (
        <PagerCircleButton
          key={i}
          color={current === firstNum + v ? "#dbdee5" : "#fff"}
          onClick={() => {
            if (total > (firstNum - 1 + v) * 20) setCurrent(firstNum + v)
          }}
        >
          <PagerItemText color={current === firstNum + v ? "#000000" : "#35363a"}>{firstNum + v}</PagerItemText>
        </PagerCircleButton>
      )
    })
  }

  return (
    <PagerContainer>
      <PagerBox>
        <PagerCircleButton color={"#fff"} onClick={toPrevPage}>
          <img style={{ width: "30px", height: "30px" }} src={prevPageIcon} alt={"이전페이지 아이콘"} />
        </PagerCircleButton>
        {renderPages()}
        <PagerCircleButton color={"#fff"} onClick={toNextPage}>
          <img style={{ width: "30px", height: "30px" }} src={nextPageIcon} alt={"다음페이지 아이콘"} />
        </PagerCircleButton>
      </PagerBox>
    </PagerContainer>
  )
}

export default Pagination

const PagerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PagerBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const PagerCircleButton = styled.button`
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  border: 1px solid #dbdee5;

  &:last-child {
    margin-right: 0px;
  }
`
const PagerItemText = styled.p`
  color: ${(props) => props.color};
  font-size: 15px;
  font-family: NanumSquareBold;
`
