import React from "react"
import { connect } from "react-redux"
import actions from "../actions"
import { compose } from "redux"

const { postSignin } = actions.SignAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  //
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  postSignin: (param) => dispatch(postSignin(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

export default compose(connect(mapStateToProps, mapDispatchToProps), WithHoc)
