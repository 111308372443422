import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getBranchs, getBranch, postBranch, patchBranch, deleteBranch, initBranch, getBranchCheckId, getDownloadExcel } = actions.BranchAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _branchs: state.BranchReducer._branchs,
  _branch: state.BranchReducer._branch,
  _branchsTotal: state.BranchReducer._branchsTotal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getBranchs: (param) => dispatch(getBranchs(param)),
  getBranch: (param) => dispatch(getBranch(param)),
  postBranch: (param) => dispatch(postBranch(param)),
  patchBranch: (param) => dispatch(patchBranch(param)),
  deleteBranch: (param) => dispatch(deleteBranch(param)),
  initBranch: () => dispatch(initBranch()),
  getBranchCheckId: (param) => dispatch(getBranchCheckId(param)),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
