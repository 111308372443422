import React, { useEffect, useState } from "react"
import SEO from "../seo"
import styled from "styled-components"
import moment from "moment"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import SectionName from "../component/common/sectionName"
import dropIcon from "../../images/drop.png"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import CourseContainer from "../../containers/courseContainer"
import moreBtn from "../../images/more_btn.png"
const Course = (props) => {
  const { _drivers, getDrivers, getDriverPouchs, _driverPouchs, initDriverPouchs } = props
  const [date, setDate] = useState(moment())
  const [selectIndex, setSelectIndex] = useState(0)
  const [selectItem, setSelectItem] = useState([])
  const [selectOpen, setSelectOpen] = useState(false)
  const [items, setItems] = useState([])
  const [remarkNum, setRemarkNum] = useState(-1)
  const onDragEnd = (result) => {
    //바깥에 드롭할경우,
    if (!result.destination) {
      return
    }
    const t = reorder(items, result.source.index, result.destination.index)
    setItems(t)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgray" : "#fff",
    ...draggableStyle,
  })
  const getData = async () => {
    await getDrivers({ type: 1, isOkcheon: 0 })
  }
  useEffect(() => {
    getData()
    return () => {
      initDriverPouchs()
    }
  }, [])
  useEffect(() => {
    setSelectItem([{ driverName: "선택", driverSeq: -1 }, ..._drivers.map((v, i) => ({ driverName: v.driverName, driverSeq: v.driverSeq }))])
  }, [_drivers])
  useEffect(() => {
    setItems(_driverPouchs.map((v, i) => ({ id: `item-${i}`, ...v })))
  }, [_driverPouchs])
  return (
    <Layout navi={"기사코스관리"}>
      <SEO title={"기사코스관리"} />
      <section>
        <PageName title={["기사코스관리"]}></PageName>
        <SectionName>기사코스관리</SectionName>
        <Box>
          <DateBox>
            <DateChangeBtn onClick={() => setDate(moment(date).add(-1, "days"))}>{"<"}</DateChangeBtn>
            <DataTextBox>
              <DateText>{date.format("YYYY-MM-DD")}</DateText>
            </DataTextBox>
            <DateChangeBtn onClick={() => setDate(moment(date).add(1, "days"))}>{">"}</DateChangeBtn>
          </DateBox>
          <SelectBox>
            <SelectBtn onClick={() => setSelectOpen(!selectOpen)} onBlur={() => setSelectOpen(false)}>
              <p
                style={{
                  paddingLeft: "10px",
                  fontSize: "14px",
                  fontFamily: "NanumSquareRegular",
                  lineHeight: "24.5px",
                  letterSpacing: "-0.42px",
                  color: selectItem.length !== 0 && selectItem[selectIndex].driverName === "선택" ? "#acb0bb" : "#35363a",
                }}
              >
                {selectItem.length !== 0 && selectItem[selectIndex].driverName}
              </p>
              <img style={{ width: "8px", height: "6px", marginRight: "10px" }} src={dropIcon} alt={"드롭 아이콘"} />
            </SelectBtn>
            <OptionBox open={selectOpen} onMouseDown={(e) => e.preventDefault()}>
              {selectItem.length !== 0 &&
                selectItem.map((option, optionIndex) => (
                  <OptionItem
                    key={optionIndex}
                    onClick={async () => {
                      setSelectOpen(false)
                      setSelectIndex(optionIndex)
                      if (optionIndex === 0) {
                        initDriverPouchs()
                        return
                      }
                      await getDriverPouchs({ driverSeq: option.driverSeq })
                    }}
                  >
                    {option.driverName}
                  </OptionItem>
                ))}
            </OptionBox>
          </SelectBox>
        </Box>
      </section>
      <DragDropContext onDragEnd={onDragEnd}>
        <section style={{ marginTop: "21px", marginBottom: "35.4px" }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <table ref={provided.innerRef}>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>행낭코드</th>
                    <th>발신 거래처 지점명</th>
                    <th>수신 거래처 지점명</th>
                    <th>발신지사</th>
                    <th>수신지사</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.length !== 0 &&
                    items.map((v, i) => (
                      <Draggable key={v.id} draggableId={v.id} index={i}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <td style={{ width: "95px" }}>{items.length - i}</td>
                            <td style={{ width: "218.75px" }}>{v.pouchNo}</td>
                            <td style={{ width: "310px" }}>{v.sendPoint.pointName}</td>
                            <td style={{ width: "310px" }}>{v.recvPoint.pointName}</td>
                            <td style={{ width: "222.5px" }}>{v.sendPoint.branch === null || v.sendPoint.branch === undefined ? '' : v.sendPoint.branch.branchName}</td>
                            <td style={{ width: "218.75px" }}>{v.recvPoint.branch === null || v.recvPoint.branch === undefined ? '' : v.recvPoint.branch.branchName}</td>
                            <td style={{ width: "133.75px" }}>
                              <div style={{ width: "100%", height: "100%" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "calc( 100% - 9px )",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {(v.remark + "").length > 5 ? v.remark.substring(0, 5) + "..." : v.remark}
                                </div>
                                {(v.remark + "").length > 5 && (
                                  <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                                    <button
                                      onClick={() => {
                                        if (i === remarkNum) setRemarkNum(-1)
                                        else setRemarkNum(i)
                                      }}
                                      onBlur={() => setRemarkNum(-1)}
                                      style={{ height: "9px" }}
                                    >
                                      <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                                    </button>

                                    {i === remarkNum && (
                                      <div style={{ position: "relative" }}>
                                        <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                          <div
                                            style={{
                                              width: "0px",
                                              height: "0px",
                                              borderBottom: "5.6px solid #fff",
                                              borderLeft: "5.65px solid transparent",
                                              borderRight: "5.65px solid transparent",
                                              marginLeft: "auto",
                                              marginRight: "auto",
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              backgroundColor: "#fff",
                                              width: "380px",
                                              height: "136px",
                                              overflowY: "auto",
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              borderRadius: "4px",
                                              boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                              padding: "11px",
                                            }}
                                          >
                                            <p style={{ whiteSpace: "pre-wrap" }}>{v.remark}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </section>
      </DragDropContext>
    </Layout>
  )
}

export default CourseContainer(Course)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const DateBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  height: 40px;
  margin-right: 10px;
`
const DateChangeBtn = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const DataTextBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: -1px;
  margin-right: -1px;
  border: 1px solid #dbdee5;
`
const DateText = styled.p`
  color: #35363a;
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 24.5px;
  letter-spacing: -0.42px;
`
const SelectBox = styled.div`
  position: relative;
  width: 110px;
`
const SelectBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 42px;
  left: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: 100px;
  max-height: 160px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const OptionItem = styled.button`
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border-bottom: 1px solid #dbdee5;
  color: #35363a;
  font-size: 14px;
  font-family: NanumSquareRegular;
  line-height: 24.5px;
  letter-spacing: -0.42px;
  text-align: left;
  &:last-child {
    border-bottom: none;
  }
`
