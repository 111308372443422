import styled from "styled-components"

const Button = styled.button`
  width: auto;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #00adee;
  border-radius: 4px;
  color: #00adee;
  font-size: 14px;
  font-family: NanumSquareBold;
  line-height: 24.5px;
  letter-spacing: -0.42px;
  ${(props) => props};
`

export default Button
