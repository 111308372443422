import React, { useState, useEffect } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import styled from "styled-components"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import Category from "../component/common/category"
import { navigate } from "gatsby"
import DriverContainer from "../../containers/driverContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const DriverLocal = (props) => {
  const { getDriversLocal, _driversLocal, _driversLocalTotal, getDriverLocal, initDriverLocal, getDownloadExcel } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state

  const [tap, setTap] = useState(1) //0:본사, 1:지사
  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(searchInfoCurrent || 1)

  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]

  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }

    let temp = { page: 0, count: 20, type: 2 }
    if (tempSearchIndex !== 0){ //&& tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getDriversLocal({ ...temp })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = { type: 2, isOkcheon: 0 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "지사기사리스트" })
  }
  const onClickRegister = async () => {
    await initDriverLocal()
    navigate("/app/driverLocalNew")
  }
  //end onClick ..
  const getData = async () => {
    let temp = { page: current - 1, count: 20, type: 2 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getDriversLocal({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])
  useEffect(() => {
    if (tap === 1) return
    navigate("/app/driver")
  }, [tap])
  return (
    <Layout navi={"기사관리"}>
      <SEO title={"기사관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["기사관리"]} style={{ marginBottom: "40px" }}></PageName>
      <div style={{ marginTop: "-10px" }}>
        <Category items={["본사", "지사"]} state={tap} setState={setTap}></Category>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Search
          item={["선택", "기사명", "지사", "아이디", "관할구역", "지역", "휴대폰번호", "등록자"]}
          index={tempSearchIndex}
          setIndex={setTempSearchIndex}
          text={tempSearchText}
          setText={setTempSearchText}
          onClickSearch={onClickSearch}
        />
        <div style={{ diplay: "flex", marginBottom: "18.4px" }}>
          <Button onClick={onClickExcelSave} marginLeft={5}>
            {"엑셀 저장"}
          </Button>
          <Button onClick={onClickRegister} marginLeft={5}>
            {"등록"}
          </Button>
        </div>
      </div>

      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>기사명</th>
            <th>지사</th>
            <th>아이디</th>
            <th>관할구역</th>
            <th>지역</th>
            <th>휴대폰번호</th>
            <th>등록일자</th>
            <th>등록자</th>
          </tr>
        </thead>
        <tbody>
          {_driversLocal &&
            _driversLocal.map((v, i) => {
              return (
                <tr key={i}>
                  <td>{_driversLocalTotal - (current - 1) * 20 - i}</td>
                  <td>
                    <button
                      onClick={async () => {
                        await getDriverLocal({ driverSeq: v.driverSeq })
                        navigate("/app/driverLocalNew", {
                          state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                        })
                      }}
                    >
                      <u>{v.driverName}</u>
                    </button>
                  </td>
                  <td>{v.branch && v.branch.branchName}</td>
                  <td>{v.userId}</td>
                  <td>{v.region}</td>
                  <td>{v.area}</td>
                  <td>{v.phoneNo}</td>
                  <td>{v.regDatetime}</td>
                  <td>{v.registName}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <Pagination current={current} setCurrent={setCurrent} total={_driversLocalTotal} />
    </Layout>
  )
}

export default DriverContainer(DriverLocal)
const SectionName = styled.p`
  margin-bottom: 20px;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
`
