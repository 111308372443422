import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import styled from "styled-components"
//
import CheckBox from "../component/common/checkbox"
import FotterButton from "../component/common/fotterButton"
import InputBox from "../component/common/inputBox"
import Layout from "../component/common/layout"
import moment from "moment"
import PageName from "../component/common/pageName"
import branchContainer from "../../containers/branchContainer"
import ZipCodePopup from "../../popups/zipCodePopup"
import BasicPopup from "../../popups/basicPopup"
const tempList = [
  { seq: -1, name: "선택" },
  { seq: 0, name: "운영" },
  { seq: 1, name: "미운영" },
]
const BranchNew = (props) => {
  const { _branch, postBranch, patchBranch, deleteBranch, getBranchCheckId } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const isHasKeys = Object.keys(_branch).length > 0
  const [type, setType] = useState(isHasKeys ? 1 : 0) //0:등록, 1:상세
  const [authDriver, setAuthDriver] = useState(isHasKeys ? (_branch.authDriver === "1" ? true : false) : false) //false:언체크, true:체크//0 : x , 1: o
  const [input1, setInput1] = useState(isHasKeys ? _branch.branchNo : "") //지사코드
  const [branchName, setBranchName] = useState(isHasKeys ? _branch.branchName : "") //지사명
  const [branchEmpName, setBranchEmpName] = useState(isHasKeys ? _branch.branchEmpName : "") //지사장명
  const [openDate, setOpenDate] = useState(isHasKeys ? _branch.openDate : moment()) //개설일자
  const [opType, setOpType] = useState(isHasKeys ? tempList[_branch.opType + 1] : { seq: -1, name: "선택" }) //운영구분
  //
  const [closeDate, setCloseDate] = useState(isHasKeys ? _branch.closeDate : null) //폐쇄일자
  const [addr, setAddr] = useState(isHasKeys ? _branch.addr : "") //주소
  const [addrDetail, setAddrDetail] = useState(isHasKeys ? _branch.addrDetail : "") //상세주소
  const [zipCode, setZipCode] = useState(isHasKeys ? _branch.zipCode : "") //우편번호
  const [teleNo, setTeleNo] = useState(isHasKeys ? _branch.teleNo : "") //전화번호
  const [faxNo, setFaxNo] = useState(isHasKeys ? _branch.faxNo : "") //팩스번호
  //
  const [id, setId] = useState(isHasKeys ? _branch.userId : "") //아이디
  const [password, setPassword] = useState("") //비밀번호
  const [idDesc, setIdDesc] = useState(isHasKeys ? 1 : 0)
  const idDescArr = [" ", "사용할수있는 아이디 입니다", "사용할수없는 아이디 입니다"]
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  //onClick...
  const onClickSave = async () => {
    if (
      branchName === "" ||
      branchEmpName === "" ||
      opType.seq === -1 ||
      addr === "" ||
      addrDetail === "" ||
      teleNo === "" ||
      faxNo === "" ||
      id === "" ||
      idDesc !== 1 ||
      (!isHasKeys && password === "")
    ) {
      setSaveCheckPopup(true)
      return
    }

    let temp = {
      branchName: branchName,
      branchEmpName: branchEmpName,
      openDate: openDate,
      opType: opType.seq,
      closeDate: closeDate === null ? null : moment(closeDate).format("YYYY-MM-DD"),
      addr: addr,
      addrDetail: addrDetail,
      zipCode: zipCode,
      teleNo: teleNo,
      faxNo: faxNo,
      id: id,
      authDriver: authDriver ? 1 : 0,
    }
    if (password !== "") {
      temp = { ...temp, password: password }
    }
    //등록일경우.
    if (!isHasKeys) {
      const result = await postBranch({ ...temp })
      if (result) {
        navigate("/app/branch")
      }
    }
    //수정일경우.
    else {
      const result = await patchBranch({ ...temp, branchSeq: _branch.branchSeq })
      if (result) {
        navigate("/app/branch")
      }
    }
  }
  const onClickCancel = () => {
    navigate("/app/branch", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }
  const onClickDelete = async () => {
    const result = await deleteBranch({ branchSeq: _branch.branchSeq })
    if (result) {
      navigate("/app/branch")
    }
  }
  const onFocusIn = async () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    if (id === "") return
    //신규등록
    if (!isHasKeys) {
      const result = await getBranchCheckId({ id: id })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    } else {
      //수정
      const result = await getBranchCheckId({ id: id, branchSeq: _branch.branchSeq })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    }
  }
  //end OnClick...

  return (
    <Layout navi={"지사관리"}>
      <SEO title={"지사관리"} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={setAddr} setZipCode={setZipCode} />
      <PageName title={["지사관리", type === 0 ? "등록" : "상세"]}></PageName>
      <div style={{ marginTop: "30px" }}>
        <CheckBox label={"지사가 직접 기사 정보 등록 및 수정할 수 있게 권한 부여"} value={authDriver} setValue={setAuthDriver} />
      </div>
      <Splitline mt={20} mb={17}></Splitline>
      <div style={{ display: "flex" }}>
        <AlignDiv>
          <InputBox label="지사코드" readOnly={true} value={input1} setValue={setInput1}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox label="지사명" marginLeft={10} value={branchName} setValue={setBranchName}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox label="지사장명" marginLeft={10} value={branchEmpName} setValue={setBranchEmpName}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox type={"date"} label="개설일자" marginLeft={10} value={openDate} setValue={setOpenDate}></InputBox>
        </AlignDiv>
        <AlignDiv>
          <InputBox type={"select"} label="운영구분" marginLeft={10} item={tempList} value={opType.name} setValue={setOpType}></InputBox>
        </AlignDiv>
      </div>
      <Splitline mt={20} mb={17}></Splitline>
      <div style={{ display: "flex", width: "100%" }}>
        <InputBox type={"date"} label="폐쇄일자" value={closeDate} setValue={setCloseDate} noSelectable={true}></InputBox>
        <InputBox type={"address"} label="주소" marginLeft={10} value={addr} setValue={setAddr} onClick={() => setZipCodePopup(true)}></InputBox>
        <InputBox label="상세주소" marginLeft={10} value={addrDetail} setValue={setAddrDetail}></InputBox>
        <InputBox label="우편번호" marginLeft={10} readOnly={true} value={zipCode} setValue={setZipCode}></InputBox>
        <InputBox label="전화번호" marginLeft={10} value={teleNo} setValue={setTeleNo}></InputBox>
        <InputBox label="팩스번호" marginLeft={10} value={faxNo} setValue={setFaxNo}></InputBox>
      </div>
      <Splitline mt={20} mb={17}></Splitline>
      <div style={{ display: "flex", marginBottom: "2px" }}>
        <InputBox label="아이디" fullWidth={true} value={id} setValue={setId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
        <InputBox label="비밀번호" isPassword={true} marginLeft={10} fullWidth={true} value={password} setValue={setPassword}></InputBox>
      </div>
      <div style={{ display: "flex", height: "20px" }}>
        <IDdesc isEnable={idDesc === 1}>{idDescArr[idDesc]}</IDdesc>
      </div>
      <Splitline mb={20}></Splitline>
      {type === 0 ? (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave} onClickDelete={onClickDelete}></FotterButton>
      )}
    </Layout>
  )
}
export default branchContainer(BranchNew)
const PStyled = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const AlignDiv = styled.div`
  margin-right: ${(props) => (props.end ? "0px" : "2px")};
`
const Section = styled.section`
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
