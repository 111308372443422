import React from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import cookie from "react-cookies"
import Logo from "../../../images/logo.png"
import { useDispatch } from "react-redux"
import { logout } from "../../../actions/signAction"

const Header = (props) => {
  const { navi = "지사관리" } = props
  const dispatch = useDispatch()

  const naviList = [
    { title: "지사관리", to: "/app/branch" },
    { title: "사원관리", to: "/app/employee" },
    { title: "기사관리", to: "/app/driver" },
    { title: "거래처관리", to: "/app/customer" },
    { title: "행낭관리", to: "/app/pouchTrack" },
    { title: "소화물관리", to: "/app/parcel" },
    { title: "기사코스관리", to: "/app/course" },
    { title: "접수관리", to: "/app/receipt" },
    { title: "청구/정산관리", to: "/app/claim" },
    { title: "공지관리", to: "/app/notice" },
  ]

  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1, height: "100%" }}>
        <button style={{ cursor: "pointer" }} onClick={() => navigate("/app/main")}>
          <img style={{}} src={Logo} alt={"로고 이미지"} />
        </button>
      </div>

      <NaviContainer>
        {naviList.map((naviItem, naviIndex) => (
          <NaviItem key={naviIndex} to={naviItem.to} active={navi === naviItem.title ? "on" : "off"}>
            {naviItem.title}
          </NaviItem>
        ))}
      </NaviContainer>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", flex: 1, height: "100%" }}>
        <div style={{ display: "flex" }}>
          <Text>
            <span>{cookie.load("adminName", { path: "/" })}</span>님이 로그인 하셨습니다.
          </Text>
        </div>
        <div>
          <button
            onClick={async () => {
              await dispatch(logout())
              navigate("/app/signin")
            }}
            style={{ border: "1px solid #00adee", borderRadius: "4px", color: "#00adee", padding: "5px 10px", marginTop: "5px", fontWeight: 700 }}
          >
            로그아웃
          </button>
        </div>
      </div>
    </Container>
  )
}

export default Header

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #eeeef3;
`
const NaviContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-width: 1200px;
`
const NaviItem = styled(Link)`
  display: block;
  color: ${(props) => (props.active === "on" ? "#00adee" : "#35363a")};
  font-size: 17px;
  font-family: NanumSquareBold;
  line-height: 20.4px;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  word-break: keep-all;
`
const Text = styled.p`
  margin-top: 10px;
  color: #8a8e98;
  font-size: 11px;
  font-family: NanumSquareRegular;
  line-height: 19.2px;
  letter-spacing: -0.33px;
  word-break: keep-all;

  & > span {
    color: #35363a;
  }
`
