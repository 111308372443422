import React, { useEffect, useState, useRef } from "react"
import Layout from "../component/common/layout"
import styled from "styled-components"
import SEO from "../seo"
import moment from "moment"
import { useBarcode } from "react-barcodes"
import arrow from "../../images/print-arrow.png"
import logo from "../../images/print-logo.png"
import mobile from "../../images/print-mobile.png"
import ReceiptContainer from "../../containers/receiptContainer"
const ZeplinPxToMM = (px) => {
  return px * 0.3200277
}
const PrintDocu = (props) => {
  const { _documentPrint, initDocumentPrint } = props
  const [target, setTarget] = useState(props.location.state.target)
  const generateBarcode = (num) => {
    const { inputRef } = useBarcode({
      value: num,
      options: {
        font: "Noto Sans KR",
        fontOptions: "bold",
        fontSize: `${ZeplinPxToMM(45)}px`,
        textMargin: 0,
        height: ZeplinPxToMM(100),
        margin: 0,
        width: ZeplinPxToMM(5.5),
      },
    })
    return <img ref={inputRef}></img>
  }
  const componentRef = useRef()

  return (
    <Container ref={componentRef} style={{ width: "100%", height: "100%", overflowY: "scroll", display: "block" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "flex-start",
          width: `210mm`,
          height: "296mm",
          padding: `10mm 4mm 15mm 4mm`,
        }}
      >
        {target === "document" &&
          _documentPrint.map((v, i) => {
            if (i >= 12) return null
            return (
              <Item
                key={i}
                isOdd={i % 2 === 1}
                barcodeNo={v.docNo}
                generateBarcode={generateBarcode}
                sendPointName={v.sendPoint.pointName}
                sendEmpName={v.sendEmpName}
                recvPointName={v.recvPoint.pointName}
                recvEmpName={v.recvEmpName}
              ></Item>
            )
          })}
      </div>
      {_documentPrint.length > 12 && (
        <div style={{ pageBreakBefore: "always" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: `210mm`,
              padding: `10mm 4mm 15mm 4mm`,
            }}
          >
            {target === "document" &&
              _documentPrint.map((v, i) => {
                if (i < 12) return null
                return (
                  <Item
                    key={i}
                    isOdd={i % 2 === 1}
                    barcodeNo={v.docNo}
                    generateBarcode={generateBarcode}
                    sendPointName={v.sendPoint.pointName}
                    sendEmpName={v.sendEmpName}
                    recvPointName={v.recvPoint.pointName}
                    recvEmpName={v.recvEmpName}
                  ></Item>
                )
              })}
          </div>
        </div>
      )}
    </Container>
  )
}
const Item = (props) => {
  const { sendPointName, sendEmpName, recvPointName, recvEmpName, generateBarcode, barcodeNo, isOdd } = props
  return (
    <FlexSection
      style={{
        width: `${ZeplinPxToMM(1181)}px`,
        height: `${ZeplinPxToMM(531)}px`,
        //padding: `${ZeplinPxToMM(30)}px`,
        marginLeft: isOdd ? `auto` : `0px`,
      }}
    >
      <FlexDiv
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          border: `${ZeplinPxToMM(8)}px solid #0084d1`,
          borderRadius: `${ZeplinPxToMM(20)}px`,
        }}
      >
        <FlexDiv style={{ width: "100%", height: `${ZeplinPxToMM(110)}px`, border: "none", borderBottom: `${ZeplinPxToMM(2)}px solid #0084d1` }}>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(200)}px`,
              height: "100%",
              backgroundColor: "#def3ff",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: `${ZeplinPxToMM(10)}px`,
            }}
          >
            <Pstyled1>발신지점명</Pstyled1>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(610)}px`,
              height: "100%",
              justifyContent: "left",
              alignItems: "center",
              borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
            }}
          >
            <Pstyled2 style={{ marginLeft: `${ZeplinPxToMM(20)}px` }}>{sendPointName}</Pstyled2>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(110)}px`,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#def3ff",
            }}
          >
            <Pstyled1>성함</Pstyled1>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(200)}px`,
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Pstyled2 style={{ marginLeft: `${ZeplinPxToMM(20)}px` }}>{sendEmpName}</Pstyled2>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          grow={1}
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {generateBarcode(barcodeNo)}
        </FlexDiv>

        <FlexDiv style={{ width: "100%", height: `${ZeplinPxToMM(110)}px`, borderTop: `${ZeplinPxToMM(2)}px solid #0084d1` }}>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(200)}px`,
              height: "100%",
              backgroundColor: "#def3ff",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: `${ZeplinPxToMM(10)}px`,
            }}
          >
            <Pstyled1>수신지점명</Pstyled1>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(610)}px`,
              height: "100%",
              justifyContent: "left",
              alignItems: "center",
              borderRight: `${ZeplinPxToMM(2)}px solid #0084d1`,
            }}
          >
            <Pstyled2 style={{ marginLeft: `${ZeplinPxToMM(20)}px` }}>{recvPointName}</Pstyled2>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(110)}px`,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#def3ff",
            }}
          >
            <Pstyled1>성함</Pstyled1>
          </FlexDiv>
          <FlexDiv
            style={{
              width: `${ZeplinPxToMM(200)}px`,
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Pstyled2 style={{ marginLeft: `${ZeplinPxToMM(20)}px` }}>{recvEmpName}</Pstyled2>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </FlexSection>
  )
}

export default ReceiptContainer(PrintDocu)
const Container = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const FlexSection = styled.div`
  border-collapse: collapse;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`
const Pstyled1 = styled.p`
  font-family: "NanumSquareBold";
  font-size: ${ZeplinPxToMM(33.3)}px;
  line-height: ${ZeplinPxToMM(40)}px;
  letter-spacing: ${ZeplinPxToMM(-1)}px;
  color: #0066a2;
`
const Pstyled2 = styled.p`
  font-family: "NanumSquareBold";
  font-size: ${ZeplinPxToMM(41.7)}px;
  line-height: ${ZeplinPxToMM(50)}px;
  letter-spacing: ${ZeplinPxToMM(-1.25)}px;
  white-space: pre-wrap;
  color: #000;
`

const Pstyled3 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(37.5)}px;
  line-height: ${ZeplinPxToMM(39)}px;
  color: #fff;
`

const Pstyled4 = styled.p`
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: ${ZeplinPxToMM(29.2)}px;
  line-height: ${ZeplinPxToMM(35)}px;
  color: #0066a2;
`

const Pstyled5 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(22.9)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #0066a2;
`

const Pstyled6 = styled.p`
  font-family: "Noto Sans KR";
  font-size: ${ZeplinPxToMM(21)}px;
  line-height: ${ZeplinPxToMM(33.3)}px;
  color: #000;
`
const FlexDiv = styled.div`
  border-collapse: collapse;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.alignItems || "normal"};

  flex: ${(props) => props.flex || "0 1 auto"};

  flex-grow: ${(props) => props.grow || "0"};
  flex-shrink: ${(props) => props.shrink || "1"};
  flex-basis: ${(props) => props.basis || "auto"};

  margin-left: ${(props) => props.ml || "0"}px;
  margin-right: ${(props) => props.mr || "0"}px;
  margin-top: ${(props) => props.mt || "0"}px;
  margin-bottom: ${(props) => props.mb || "0"}px;

  padding-left: ${(props) => props.pl || "0"}px;
  padding-right: ${(props) => props.pr || "0"}px;
  padding-top: ${(props) => props.pt || "0"}px;
  padding-bottom: ${(props) => props.pb || "0"}px;
`
