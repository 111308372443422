import React, { useState, useEffect } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import styled from "styled-components"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import { navigate } from "gatsby"
import BasicPopup from "../../popups/basicPopup"
import CheckBox from "../component/common/checkbox"
import DriverContainer from "../../containers/driverContainer"
const DriverLocalNew = (props) => {
  const { _driverLocal, postDriver, patchDriver, deleteDriver, getDriverCheckId, _branchs, getBranchs } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state

  console.log("!!!!", searchInfoText, serachInfoIndex, searchInfoCurrent)
  const isHasKeys = Object.keys(_driverLocal).length > 0

  const [driverName, setDriverName] = useState(isHasKeys ? _driverLocal.driverName : "") //기사명
  const [region, setRegion] = useState(isHasKeys ? _driverLocal.region : "") //관할구역
  const [area, setArea] = useState(isHasKeys ? _driverLocal.area : "") //지역
  const [phoneNo, setPhoneNo] = useState(isHasKeys ? _driverLocal.phoneNo : "") //휴대폰번호
  //
  const [id, setId] = useState(isHasKeys ? _driverLocal.userId : "") //아이디
  const [password, setPassword] = useState("") //비밀번호
  //
  const [popup, setPopup] = useState(false) //팝업
  const [idDesc, setIdDesc] = useState(isHasKeys ? 1 : 0)
  const idDescArr = ["", "사용할수있는 아이디 입니다", "사용할수없는 아이디 입니다"]
  const [branch, setBranch] = useState({ seq: -1, name: "선택" })
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)
  //onClick...
  const onClickCancel = () => {
    navigate("/app/driverLocal", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }
  const onClickSave = async () => {
    if (
      driverName === "" ||
      region === "" ||
      area === "" ||
      phoneNo === "" ||
      id === "" ||
      (!isHasKeys && password === "") ||
      branch.seq === -1 ||
      idDesc !== 1
    ) {
      setSaveCheckPopup(true)
      return
    }
    let temp = {
      branchSeq: branch.seq,
      driverName: driverName,
      region: region,
      area: area,
      phoneNo: phoneNo,
      id: id,
    }
    if (password !== "") {
      temp = { ...temp, password: password }
    }
    if (!isHasKeys) {
      await postDriver({ ...temp })
    } else {
      await patchDriver({
        ...temp,
        driverSeq: _driverLocal.driverSeq,
      })
    }
    navigate("/app/driverLocal")
  }
  const onClickDelete = async () => {
    await deleteDriver({ driverSeq: _driverLocal.driverSeq })
    navigate("/app/driverLocal")
  }
  const onFocusIn = async () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    if (id === "") return
    //신규등록
    if (!isHasKeys) {
      const result = await getDriverCheckId({ id: id })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    } else {
      //수정
      const result = await getDriverCheckId({ id: id, driverSeq: _driverLocal.driverSeq })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    }
  }
  //end onClick...
  const getData = async () => {
    await getBranchs({})
  }
  useEffect(() => {
    getData()
    if (isHasKeys) {
      const temp = _branchs.find((i) => _driverLocal.branchSeq === i.branchSeq)
      if (temp) {
        setBranch({ seq: temp.branchSeq, name: temp.branchName })
      }
    }
  }, [])
  return (
    <Layout navi={"기사관리"}>
      <SEO title={"기사관리"} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "취소") {
            setPopup(false)
          } else if (value === "확인") {
            onClickDelete()
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <PageName title={["지사", !isHasKeys ? "등록" : "상세"]} style={{ marginBottom: "40px" }}></PageName>
      <Section>
        <div style={{ display: "flex", width: "100%" }}>
          <AlignDiv>
            <InputBox label="기사명" value={driverName} setValue={setDriverName}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="관할구역" marginLeft={10} value={region} setValue={setRegion}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="지역" marginLeft={10} value={area} setValue={setArea}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="휴대폰번호" marginLeft={10} value={phoneNo} setValue={setPhoneNo}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox
              type="select"
              label="지사"
              marginLeft={10}
              item={_branchs.map((i) => ({ seq: i.branchSeq, name: i.branchName }))}
              value={branch.name}
              setValue={(value) => setBranch(value)}
            ></InputBox>
          </AlignDiv>
        </div>
      </Section>
      <Splitline mb={17}></Splitline>

      <div style={{ display: "flex", marginBottom: "2px" }}>
        <InputBox label="아이디" fullWidth={true} value={id} setValue={setId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
        <InputBox label="비밀번호" isPassword={true} marginLeft={10} fullWidth={true} value={password} setValue={setPassword}></InputBox>
      </div>
      <div style={{ display: "flex", height: "20px" }}>
        <IDdesc isEnable={idDesc === 1}>{idDescArr[idDesc]}</IDdesc>
      </div>
      <Splitline mb={20}></Splitline>
      {!isHasKeys ? (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton
          onClickCancel={onClickCancel}
          onClickSave={onClickSave}
          onClickDelete={() => {
            setPopup(true)
          }}
        ></FotterButton>
      )}
    </Layout>
  )
}
export default DriverContainer(DriverLocalNew)
const PStyled = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const AlignDiv = styled.div`
  margin-right: ${(props) => (props.end ? "0px" : "2px")};
`
const Section = styled.section`
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
