import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Page404 from "./404"
import Root from "../components/page/root"
import SignIn from "../components/page/signin"
import Branch from "../components/page/branch"
import BranchNew from "../components/page/branchNew"
import Employee from "../components/page/employee"
import EmployeeNew from "../components/page/employeeNew"
import Driver from "../components/page/driver"
import DriverLocal from "../components/page/driverLocal"
import DriverNew from "../components/page/driverNew"
import DriverLocalNew from "../components/page/driverLocalNew"
import Customer from "../components/page/customer"
import CustomerNew from "../components/page/customerNew"
import Point from "../components/page/point"
import PointNew from "../components/page/pointNew"
import PouchManage from "../components/page/pouchManage"
import PouchTrack from "../components/page/pouchTrack"
import PouchNew from "../components/page/pouchNew"
import LargePouchManage from "../components/page/largePouchManage"
import LargePouchNew from "../components/page/largePouchNew"
import LargePouchTrack from "../components/page/largePouchTrack"
import LargePouchTrackDetail from "../components/page/largePouchTrackDetail"
import Main from "../components/page/main"
import Parcel from "../components/page/parcel"
import Course from "../components/page/course"
import Receipt from "../components/page/receipt"
import ReceiptDocuNew from "../components/page/receiptDocuNew"
import ParcelNew from "../components/page/parcelNew"
import Claim from "../components/page/claim"
import Notice from "../components/page/notice"
import NoticeNew from "../components/page/noticeNew"

import PrintFreight from "../components/page/printFreight"
import PrintFreightA4 from "../components/page/printFreightA4"

import PrintDocu from "../components/page/printDocu"
import PrintPouchTop from "../components/page/printPouchTop"
import PrintPouchBottom from "../components/page/printPouchBottom"
import PrintLargePouch from "../components/page/printLargePouch"
import "../components/layout.css"

const App = () => {
  return (
    <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
      <PrivateRoute path="/" component={Root} />
      <SignIn path="/signin" />
      
      <PrintFreight path="/printFreight" />
      <PrintFreightA4 path="/printFreightA4" />

      <PrintDocu path="/printDocu" />
      <PrintPouchTop path="/printPouchTop" />
      <PrintPouchBottom path="/printPouchBottom" />
      <PrintLargePouch path="/printLargePouch" />
      <PrivateRoute path="/main" component={Main} />

      <PrivateRoute path="/branch" component={Branch} />
      <PrivateRoute path="/branchNew" component={BranchNew} />
      <PrivateRoute path="/employee" component={Employee} />
      <PrivateRoute path="/employeeNew" component={EmployeeNew} />
      <PrivateRoute path="/driver" component={Driver} />
      <PrivateRoute path="/driverLocal" component={DriverLocal} />
      <PrivateRoute path="/driverNew" component={DriverNew} />
      <PrivateRoute path="/driverLocalNew" component={DriverLocalNew} />
      <PrivateRoute path="/customer" component={Customer} />
      <PrivateRoute path="/point" component={Point} />
      <PrivateRoute path="/customerNew" component={CustomerNew} />
      <PrivateRoute path="/pointNew" component={PointNew} />
      <PrivateRoute path="/pouchManage" component={PouchManage} />
      <PrivateRoute path="/pouchTrack" component={PouchTrack} />
      <PrivateRoute path="/pouchNew" component={PouchNew} />
      <PrivateRoute path="/largePouchManage" component={LargePouchManage} />
      <PrivateRoute path="/largePouchNew" component={LargePouchNew} />
      <PrivateRoute path="/largePouchTrack" component={LargePouchTrack} />
      <PrivateRoute path="/largePouchTrackDetail" component={LargePouchTrackDetail} />
      <PrivateRoute path="/parcel" component={Parcel} />
      <PrivateRoute path="/course" component={Course} />
      <PrivateRoute path="/receipt" component={Receipt} />
      <PrivateRoute path="/receiptDocuNew" component={ReceiptDocuNew} />
      <PrivateRoute path="/receiptParcelNew" component={ParcelNew} />
      <PrivateRoute path="/claim" component={Claim} />
      <PrivateRoute path="/notice" component={Notice} />
      <PrivateRoute path="/noticeNew" component={NoticeNew} />
      {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
      <Page404 path="/*" />
    </Router>
  )
}

export default App
