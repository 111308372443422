import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SEO from "../seo"
import LargePouchContainer from "../../containers/largePouchContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const LargePouchTrackDetail = (props) => {
  const { _largePouchItems, _largePouchItemsTotal, getLargePouchItems, getTrackDetailDownloadExcel } = props
  const params = new URLSearchParams(props.location.search)
  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]

  useEffect(() => {
    getData(params.get("largeTrackSeq"))
  }, [])
  const onClickSearch = async () => {
    if (searchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } else if (searchText.length < 2) {
      setMessageIndex(2)
      setPopup(true)
      return
    }
    let temp = {
      page: 0,
      count: 20,
      largeTrackSeq: params.get("largeTrackSeq"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getLargePouchItems({ ...temp })
    setCurrent(1)
  }
  const getData = async (seq) => {
    await getLargePouchItems({
      page: current - 1,
      count: 20,
      largeTrackSeq: seq,
    })
  }

  const onClickExcelSave = async () => {
    let temp = { largeTrackSeq: params.get("largeTrackSeq") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getTrackDetailDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "대낭추적 상세리스트" })
  }
  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["행낭관리", "대낭추적 상세"]} style={{ marginBottom: "40px" }}></PageName>
      <SectionName>
        {params.get("largeTrackNo")}
        {"(대낭코드)"}
      </SectionName>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Search
          item={["선택", "행낭코드", "발신거래처지점명", "수신거래처지점명", "발신지사", "수신지사"]}
          index={searchIndex}
          setIndex={setSearchIndex}
          text={searchText}
          setText={setSearchText}
          onClickSearch={onClickSearch}
        />
        <div style={{ diplay: "flex", marginBottom: "18.4px" }}>
          <Button onClick={onClickExcelSave}>{"엑셀 저장"}</Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>행낭코드</th>
            <th>발신 거래처 지점명</th>
            <th>수신 거래처 지점명</th>
            <th>발신지사</th>
            <th>수신지사</th>
          </tr>
        </thead>
        <tbody>
          {_largePouchItems.map((v, i) => {
            return (
              <tr key={i}>
                <td>{_largePouchItemsTotal - (current - 1) * 10 - i}</td>
                <td>{v.pouch === null ? '' : v.pouch.pouchNo}</td>
                <td>{v.pouch === null ? '' : v.pouch.sendPoint.pointName}</td>
                <td>{v.pouch === null ? '' : v.pouch.recvPoint.pointName}</td>
                <td>{v.pouch === null ? '' : v.pouch.sendPoint.branch === null ? '' : v.pouch.sendPoint.branch.branchName}</td>
                <td>{v.pouch === null ? '' : v.pouch.recvPoint.branch === null ? '' : v.pouch.recvPoint.branch.branchName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination current={current} setCurrent={setCurrent} total={_largePouchItemsTotal} />
    </Layout>
  )
}

export default LargePouchContainer(LargePouchTrackDetail)

const SectionName = styled.p`
  margin-bottom: 20px;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
`
