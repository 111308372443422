import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import styled from "styled-components"
import moment from "moment"
import Button from "../component/common/button"
import Category from "../component/common/category"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Pagination from "../component/common/pagination"
import Search from "../component/common/search"
import PointContainer from "../../containers/pointContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const Point = (props) => {
  const { getPoints, _points, getPoint, initPoint, _pointsTotal, _customer, getDownloadExcel, postPointsUpload } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const [tap, setTap] = useState(1) // 탭 == 0: 일정관리 , 1: 지점
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0) //검색 컴포넌트
  const [searchText, setSearchText] = useState(searchInfoText || "") //검색 컴포넌트
  const [current, setCurrent] = useState(searchInfoCurrent || 1) //페이지 네이션
  const [popup, setPopup] = useState({ open: false, message: "" })
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요", "엑셀등록을 성공하였습니다", "엑셀등록을 실패하였습니다"]

  const excelUpload = useRef()
  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setPopup({ open: true, message: "검색항목을 입력하세요" })
      return
    } else if (tempSearchText.length < 2) {
      setPopup({ open: true, message: "검색어를 두자이상 입력해주세요" })
      return
    }
    await getPoints({
      page: 0,
      count: 20,
      searchType: tempSearchIndex,
      search: tempSearchText,
    })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = { custSeq: _customer.custSeq }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "지점리스트" })
  }
  const onClickRegister = async () => {
    await initPoint()
    navigate("/app/pointNew")
  }
  const onClickTap = (v) => {
    if (v === 1) return
    navigate("/app/customerNew")
  }
  //end onClick...

  const getData = async () => {
    let temp = { page: current - 1, count: 20, custSeq: _customer.custSeq }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getPoints({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])

  return (
    <Layout navi={"거래처관리"}>
      <SEO title={"거래처관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, message: "" })} content={popup.message} selectedValue={["확인"]} />
      <PageName title={["거래처관리", "상세"]}></PageName>

      <div style={{ marginTop: "-27px", marginBottom: "-40px" }}>
        <Section id="Category">
          <Category items={["일반 정보", "지점"]} state={tap} setState={onClickTap}></Category>
        </Section>
      </div>

      <Section>
        <Search
          item={["선택", "지점코드", "거래처명", "지점명", "아이디", "주소", "전화번호", "등록일자", "등록자"]}
          index={tempSearchIndex}
          setIndex={setTempSearchIndex}
          text={tempSearchText}
          setText={setTempSearchText}
          onClickSearch={onClickSearch}
        />
        <DivBtn>
          <Button onClick={onClickExcelSave} style={{ marginRight: "3px" }}>
            엑셀 저장
          </Button>
          <input
            ref={excelUpload}
            style={{ display: "none" }}
            type={"file"}
            onChange={async (e) => {
              const form_data = new FormData()
              form_data.append("file", e.target.files[0])
              form_data.append("custSeq", _customer.custSeq)
              const result = await postPointsUpload(form_data)
              if (result.code === 0) {
                setPopup({ open: true, message: "엑셀등록을 성공하였습니다" })
                getData()
              } else {
                setPopup({ open: true, message: `엑셀등록을 실패하였습니다\n${result.message}` })
              }
            }}
            accept={".xlsx"}
          />
          <Button onClick={() => excelUpload.current.click()} style={{ margin: "3px" }}>
            엑셀 등록
          </Button>
          <Button onClick={onClickRegister} style={{ marginLeft: "3px" }}>
            등록
          </Button>
        </DivBtn>
      </Section>

      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>지점코드</th>
            <th>거래처명</th>
            <th>지점명</th>
            <th>아이디</th>
            <th>주소</th>
            <th>전화번호</th>
            <th>지점운영상태</th>
            <th>등록일자</th>
            <th>등록자</th>
          </tr>
        </thead>
        <tbody>
          {_points.map((v, i) => (
            <tr key={i} style={{ backgroundColor: v.opType === 1 ? "#ffd1e0" : "#fff" }}>
              <td>{_pointsTotal - (current - 1) * 20 - i}</td>
              <td>{v.pointNo}</td>
              <td>{v.customer.custName}</td>
              <td>
                <button
                  onClick={async () => {
                    await getPoint({ pointSeq: v.pointSeq })
                    navigate("/app/pointNew", {
                      state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                    })
                  }}
                >
                  <u>{v.pointName}</u>
                </button>
              </td>
              <td>{v.userId}</td>
              <td>
                {v.addr}&nbsp;{v.addrDetail}
              </td>
              <td>{v.teleNo}</td>
              <td>{v.opType === 0 ? "운영" : v.opType === 1 ? "폐쇄" : ""}</td>
              <td>{moment(v.regDatetime).format("yyyy.MM.DD")}</td>
              <td>{v.registName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination current={current} setCurrent={setCurrent} total={_pointsTotal} />
    </Layout>
  )
}
export default PointContainer(Point)

const SectionName = styled.p`
  font-family: NanumSquareBold;
  font-size: 12px;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 5px;
`

const Section = styled.section`
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`

const DivBtn = styled.div`
  margin-inline-start: auto;
`
