import React from "react"
import { useDispatch } from "react-redux"
import { checkAuth } from "../actions/signAction"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const dispatch = useDispatch()
  dispatch(checkAuth())

  return <Component location={location} {...rest} />
}

export default PrivateRoute
