import React, { useState, useEffect } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import moment from "moment"
import styled from "styled-components"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import Category from "../component/common/category"
import { navigate } from "gatsby"
import DatePicker from "../component/common/datePicker"
import PouchContainer from "../../containers/pouchContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave, GetStatus } from "../../utils/util"
const pouchTrack = (props) => {
  const { getPouchTracks, _pouchTracks, _pouchTracksTotal, getTrackDownloadExcel } = props
  //탭
  const [tap, setTap] = useState(0) //0:행낭추적,1:행낭관리,2:대낭추적, 3:대낭관리
  const [popup, setPopup] = useState(false)
  const [signPopup, setSignPopup] = useState({ open: false, imageUrl: null })
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]

  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(1)
  //스캔일자
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))

  const [tempSearchStartDate, setTempSearchStartDate] = useState(moment().startOf("months"))
  const [tempSearchEndDate, setTempSearchEndDate] = useState(moment().endOf("months"))
  //onClick ..

  const tapCallback = (tap) => {
    if (tap === 0) return
    switch (tap) {
      case 0:
        navigate("/app/pouchTrack")
        break
      case 1:
        navigate("/app/pouchManage")
        break
      case 2:
        navigate("/app/largePouchTrack")
        break
      case 3:
        navigate("/app/largePouchManage")
        break
    }
  }
  const onClickSearch = async () => {
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)

    let dateFr = moment(startDate).format("yyyy-MM-DD")
    let dateTo = moment(endDate).format("yyyy-MM-DD")

    // if (!moment(startDate).isBefore(moment(endDate))) {
    if ( dateFr > dateTo ) {

      setMessageIndex(1)
      setPopup(true)
      return
    }
    
    if (tempSearchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(3)
    //   setPopup(true)
    //   return
    // }

    let temp = {
      page: 0,
      count: 20,
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (tempSearchIndex !== 0){// && tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getPouchTracks({ ...temp })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = {
      startDate: moment(startDate).format("yyyy-MM-DD"),
      endDate: moment(endDate).format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getTrackDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "행낭추적리스트" })
  }
  const onClickLookUp = async () => {
    setTempSearchIndex(0)
    setTempSearchText("")
    setSearchIndex(0)
    setSearchText("")
    setStartDate(tempSearchStartDate)
    setEndDate(tempSearchEndDate)

    let dateFr = moment(tempSearchStartDate).format("yyyy-MM-DD")
    let dateTo = moment(tempSearchEndDate).format("yyyy-MM-DD")

    // if (!moment(tempSearchStartDate).isBefore(moment(tempSearchEndDate))) {
    if ( dateFr > dateTo ) {
      setMessageIndex(1)
      setPopup(true)
      return
    }

    let temp = {
      page: 0,
      count: 20,
      startDate: tempSearchStartDate.format("yyyy-MM-DD"),
      endDate: tempSearchEndDate.format("yyyy-MM-DD"),
    }
    await getPouchTracks({ ...temp })
    setCurrent(1)
  }
  //end onClick ..
  const getData = async () => {
    let temp = {
      page: current - 1,
      count: 20,
      startDate: startDate.format("yyyy-MM-DD"),
      endDate: endDate.format("yyyy-MM-DD"),
    }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getPouchTracks({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    getData()
  }, [current])
  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup
        open={signPopup.open}
        onClose={() => setSignPopup({ open: false, imageUrl: null })}
        imageUrl={signPopup.imageUrl}
        content={""}
        selectedValue={["확인"]}
      />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["행낭추적"]} style={{ marginBottom: "40px" }}></PageName>
      <Category items={["행낭추적", "행낭관리", "대낭추적", "대낭관리"]} state={tap} setState={tapCallback}></Category>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <DatePicker
            label={"스캔일자"}
            startDate={tempSearchStartDate}
            setStartDate={setTempSearchStartDate}
            endDate={tempSearchEndDate}
            setEndDate={setTempSearchEndDate}
            onClick={onClickLookUp}
          ></DatePicker>
          <Search
            item={["선택", "행낭코드", "거래처명", "발신거래처지점명", "수신거래처지점명", "발신지사", "수신지사", "스캔담당", "상태"]}
            index={tempSearchIndex}
            setIndex={setTempSearchIndex}
            text={tempSearchText}
            setText={setTempSearchText}
            onClickSearch={onClickSearch}
          />
        </div>
        <div style={{ diplay: "flex" }}>
          <Button onClick={onClickExcelSave}>{"엑셀 저장"}</Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>행낭코드</th>
            <th>거래처명</th>
            <th>발신 거래처 지점명</th>
            <th>수신 거래처 지점명</th>
            <th>발신지사</th>
            <th>수신지사</th>
            <th>스캔일자</th>
            <th>스캔담당</th>
            <th>위치</th>
            <th>상태</th>
            <th>사진 서명</th>
          </tr>
        </thead>
        <tbody>
          {_pouchTracks.map((v, i) => {
            return (
              <tr key={i}>
                <td>{_pouchTracksTotal - (current - 1) * 20 - i}</td>
                <td>{v.pouch === null ? '' : v.pouch.pouchNo}</td>
                <td>{v.pouch === null ? '' : v.pouch.sendPoint.customer.custName}</td>
                <td>{v.pouch === null ? '' : v.pouch.sendPoint.pointName}</td>
                <td>{v.pouch === null ? '' : v.pouch.recvPoint.pointName}</td>
                <td>{v.pouch === null ? '' : v.pouch.sendPoint.branch === null || v.pouch.sendPoint.branch === undefined ? '' : v.pouch.sendPoint.branch.branchName}</td>
                <td>{v.pouch === null ? '' : v.pouch.recvPoint.branch === null || v.pouch.recvPoint.branch === undefined ? '' : v.pouch.recvPoint.branch.branchName}</td>
                <td>{v.regDatetime}</td>
                <td>{v.scanName}</td>
                <td>
                  {v.addr}&nbsp;{v.addrDetail}
                </td>
                <td>{GetStatus(v.status)}</td>
                <td>
                  <SmallButton
                    onClick={() => {
                      setSignPopup({ open: true, imageUrl: v.photoUrl || v.signUrl })
                    }}
                  >
                    {"확인"}
                  </SmallButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Pagination current={current} setCurrent={setCurrent} total={_pouchTracksTotal} />
    </Layout>
  )
}

export default PouchContainer(pouchTrack)
const SmallButton = styled(Button)`
  height: 30px;
  margin-left: 6px;
`
