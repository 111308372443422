import React, { useState, useEffect } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import moment from "moment"
import styled from "styled-components"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import Category from "../component/common/category"
import { navigate } from "gatsby"
import DatePicker from "../component/common/datePicker"
import CheckBox from "../component/common/checkbox"
import PouchContainer from "../../containers/pouchContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const tempList = [
  { seq: 0, name: "미사용" },
  { seq: 1, name: "사용" },
  { seq: 2, name: "삭제" },
]
const PouchManage = (props) => {
  const {
    getPouchs,
    _pouchs,
    _pouchsTotal,
    getPouch,
    initPouch,
    postPouch,
    getManageDownloadExcel,
    setPouchPrintTop,
    setPouchPrintBottom,
    initPouchPrintTop,
    initPouchPrintBottom,
  } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  //탭
  const [tap, setTap] = useState(1) //0:행낭추적,1:행낭관리,2:대낭추적, 3:대낭관리
  const tapCallback = (tap) => {
    if (tap === 1) return
    switch (tap) {
      case 0:
        navigate("/app/pouchTrack")
        break
      case 1:
        navigate("/app/pouchManage")
        break
      case 2:
        navigate("/app/largePouchTrack")
        break
      case 3:
        navigate("/app/largePouchManage")
        break
    }
  }
  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [checkList, setCheckList] = useState([])
  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }
    let temp = { page: 0, count: 20 }
    if (tempSearchIndex !== 0){// && tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getPouchs({ ...temp })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getManageDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "행낭관리리스트" })
  }
  const onClickRegister = async () => {
    await initPouch()
    navigate("/app/pouchNew")
  }
  const onClickPrintTop = async () => {
    if (checkList.length === 0) return
    const temp = checkList
    setCheckList([])
    setPouchPrintTop(_pouchs.filter((t) => temp.includes(t.pouchSeq)))
    navigate("/app/printPouchTop")
  }
  const onClickPrintBottom = () => {
    if (checkList.length === 0) return
    const temp = checkList
    setCheckList([])
    setPouchPrintBottom(_pouchs.filter((t) => temp.includes(t.pouchSeq)))
    navigate("/app/printPouchBottom")
  }
  const onClickCheck = async (seq) => {
    const temp = [...checkList]
    if (temp.includes(seq)) {
      temp.splice(temp.indexOf(seq), 1)
    } else {
      temp.push(seq)
    }
    setCheckList(temp)
  }
  //end onClick ..
  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getPouchs({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])
  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["행낭관리"]} style={{ marginBottom: "40px" }}></PageName>
      <Category items={["행낭추적", "행낭관리", "대낭추적", "대낭관리"]} state={tap} setState={tapCallback}></Category>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Search
            item={["선택", "행낭코드", "발행자", "거래처명", "발신거래처지점명", "수신거래처지점명", "행낭상태"]}
            index={tempSearchIndex}
            setIndex={setTempSearchIndex}
            text={tempSearchText}
            setText={setTempSearchText}
            onClickSearch={onClickSearch}
          />
        </div>
        <div style={{ diplay: "flex" }}>
          <Button onClick={onClickPrintTop}>{"선택 표찰 출력(상)"}</Button>
          <Button onClick={onClickPrintBottom} marginLeft={6}>
            {"선택 표찰 출력(하)"}
          </Button>
          <Button onClick={onClickExcelSave} marginLeft={6}>
            {"엑셀 저장"}
          </Button>
          <Button onClick={onClickRegister} marginLeft={6}>
            {"등록"}
          </Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>선택</th>
            <th>번호</th>
            <th>행낭코드</th>
            <th>발행자</th>
            <th>발행일</th>
            <th>거래처명</th>
            <th>발신 거래처 지점명</th>
            <th>수신 거래처 지점명</th>
            {/* <th>발신지사</th>
            <th>수신지사</th> */}
            <th>행낭상태</th>
            <th>표찰출력</th>
            <th>복사생성</th>
          </tr>
        </thead>
        <tbody>
          {_pouchs.map((v, i) => {
            return (
              <tr key={i}>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CheckBox label="" value={checkList.includes(v.pouchSeq)} setValue={() => onClickCheck(v.pouchSeq)} />
                  </div>
                </td>
                <td>{_pouchsTotal - (current - 1) * 20 - i}</td>

                <td>
                  <button
                    onClick={async () => {
                      await getPouch({ pouchSeq: v.pouchSeq })
                      navigate("/app/pouchNew", {
                        state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                      })
                    }}
                  >
                    <u>{v.pouchNo}</u>
                  </button>
                </td>
                <td>{v.registName}</td>
                <td>{v.regDatetime}</td>
                <td>{v.sendPoint.customer.custName}</td>
                <td>{v.sendPoint.pointName}</td>
                <td>{v.recvPoint.pointName}</td>
                {/* <td>{v.sendBranch.branchName}</td>
                <td>{v.recvBranch.branchName}</td> */}
                <td>{tempList[v.status].name}</td>
                <td>
                  <SmallButton
                    onClick={() => {
                      setPouchPrintTop(_pouchs.filter((t) => t.pouchSeq == v.pouchSeq))
                      navigate("/app/printPouchTop")
                    }}
                  >
                    {"상"}
                  </SmallButton>
                  <SmallButton
                    onClick={() => {
                      setPouchPrintBottom(_pouchs.filter((t) => t.pouchSeq == v.pouchSeq))
                      navigate("/app/printPouchBottom")
                    }}
                  >
                    {"하"}
                  </SmallButton>
                </td>
                <td>
                  <SmallButton
                    onClick={async () => {
                      
                      const pouch = _pouchs.filter((row) => row.pouchSeq == v.pouchSeq)

                      if ( pouch.length > 0 ) {

                        console.log(777,pouch[0])
                        
                        const temp = {
                          sendPointSeq: pouch[0].sendPointSeq,
                          recvPointSeq: pouch[0].recvPointSeq,
                          status: pouch[0].status,
                          sendBranchSeq: pouch[0].sendBranchSeq,
                          recvBranchSeq: pouch[0].recvBranchSeq,
                          price: pouch[0].price,
                          remark: pouch[0].remark,
                        }
                    
                        await postPouch({ ...temp })
                      
                        getData()
                        
                      } // end if 

                    }}
                  >
                    {"복사생성"}
                  </SmallButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Pagination current={current} setCurrent={setCurrent} total={_pouchsTotal} />
    </Layout>
  )
}

export default PouchContainer(PouchManage)

const SmallButton = styled(Button)`
  height: 30px;
  margin-left: 6px;
`
