import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getPouchTracks,
  getPouchs,
  getPouch,
  postPouch,
  patchPouch,
  deletePouch,
  initPouch,
  getTrackDownloadExcel,
  getManageDownloadExcel,
  setPouchPrintTop,
  setPouchPrintBottom,
  initPouchPrintTop,
  initPouchPrintBottom,
} = actions.PouchAction
const { getPoints } = actions.PointAction
const { getBranchs } = actions.BranchAction
const { getCustomers } = actions.CustomerAction
const { getDrivers, getDriversLocal } = actions.DriverAction
// redux state > component mapping
const mapStateToProps = (state) => ({
  _pouchTracks: state.PouchReducer._pouchTracks,
  _pouchTracksTotal: state.PouchReducer._pouchTracksTotal,
  _pouchs: state.PouchReducer._pouchs,
  _pouchsTotal: state.PouchReducer._pouchsTotal,
  _pouch: state.PouchReducer._pouch,
  _points: state.PointReducer._points,
  _branchs: state.BranchReducer._branchs,
  _customers: state.CustomerReducer._customers,
  _pouchPrintTop: state.PouchReducer._pouchPrintTop,
  _pouchPrintBottom: state.PouchReducer._pouchPrintBottom,
  _drivers: state.DriverReducer._drivers,
  _driversLocal: state.DriverReducer._driversLocal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getCustomers: (param) => dispatch(getCustomers(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
  getPoints: (param) => dispatch(getPoints(param)),
  getPouchTracks: (param) => dispatch(getPouchTracks(param)),
  getPouchs: (param) => dispatch(getPouchs(param)),
  getPouch: (param) => dispatch(getPouch(param)),
  postPouch: (param) => dispatch(postPouch(param)),
  patchPouch: (param) => dispatch(patchPouch(param)),
  deletePouch: (param) => dispatch(deletePouch(param)),
  initPouch: () => dispatch(initPouch()),
  getTrackDownloadExcel: (param) => dispatch(getTrackDownloadExcel(param)),
  getManageDownloadExcel: (param) => dispatch(getManageDownloadExcel(param)),
  setPouchPrintTop: (param) => dispatch(setPouchPrintTop(param)),
  setPouchPrintBottom: (param) => dispatch(setPouchPrintBottom(param)),
  initPouchPrintTop: () => dispatch(initPouchPrintTop()),
  initPouchPrintBottom: () => dispatch(initPouchPrintBottom()),
  getDrivers: (param) => dispatch(getDrivers(param)),
  getDriversLocal: (param) => dispatch(getDriversLocal(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
