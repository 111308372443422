import api from "../utils/api"

const uploadImage = async (data) => {
  const result = await api.post(`/upload/image`, {
    body: data,
  })
  return result.data
}

const uploadImages = async (data) => {
  const result = await api.post(`/upload/images`, {
    body: data,
  })
  return result.data
}

export default {
  uploadImage,
  uploadImages,
}
