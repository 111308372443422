import React from "react"
import styled from "styled-components"
import CheckIcon from "../../../images/check_on.png"

const CheckBox = (props) => {
  const { label, value, setValue } = props

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
      <button
        id={"checkBox"}
        style={{ width: "20px", height: "20px", marginRight: "10px", border: "1px solid #ced1da", borderRadius: "4px" }}
        onClick={() => setValue(!value)}
      >
        <img style={{ display: value ? "block" : "none", width: "20px", height: "20px" }} src={CheckIcon} alt={"체크 on 아이콘"} />
      </button>
      <label
        htmlFor={"checkBox"}
        style={{ color: "#35363a", fontSize: "14px", fontFamily: "NanumSquareRegular", lineHeight: "16px", letterSpacing: "-0.42px" }}
      >
        {label}
      </label>
    </div>
  )
}

export default CheckBox
