import React from "react"
import styled from "styled-components"
import homeIcon from "../../../images/home.png"
const PageName = (props) => {
  const { title } = props
  return (
    <Container>
      <HomeIcon src={homeIcon} alt="홈아이콘" />
      {title.map((v, i) => (
        <PageView key={i}>
          <SplitText>{">"}</SplitText>
          <Text>{v}</Text>
        </PageView>
      ))}
    </Container>
  )
}

export default PageName
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const HomeIcon = styled.img`
  width: 15px;
  height: 13px;
`
const PageView = styled.div`
  display: flex;
`
const Text = styled.p`
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.36px;
  color: #8a8e98;
`
const SplitText = styled.p`
  margin-left: 5.8px;
  margin-right: 5.8px;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.36px;
  color: #8a8e98;
`
