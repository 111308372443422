import React, { useState, useEffect } from "react"
import SEO from "../seo"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import styled from "styled-components"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import { navigate } from "gatsby"
import BasicPopup from "../../popups/basicPopup"
import CheckBox from "../component/common/checkbox"
import DriverContainer from "../../containers/driverContainer"
const DriverNew = (props) => {
  const { _driver, postDriver, patchDriver, deleteDriver, getDriverCheckId } = props
  const { searchInfoText, serachInfoIndex, searchInfoHub, searchInfoCurrent } = props.location.state
  const isHasKeys = Object.keys(_driver).length > 0
  const [check, setCheck] = useState(isHasKeys ? (_driver.isOkcheon == 1 ? true : false) : false) //false:언체크, true:체크,(0: X, 1: O)
  const [driverName, setDriverName] = useState(isHasKeys ? _driver.driverName : "") //기사명
  const [region, setRegion] = useState(isHasKeys ? _driver.region : "") //관할구역
  const [area, setArea] = useState(isHasKeys ? _driver.area : "") //지역
  const [phoneNo, setPhoneNo] = useState(isHasKeys ? _driver.phoneNo : "") //휴대폰번호
  //
  const [id, setId] = useState(isHasKeys ? _driver.userId : "") //아이디
  const [password, setPassword] = useState("") //비밀번호
  //
  const [popup, setPopup] = useState(false) //팝업
  const [saveCheckPopup, setSaveCheckPopup] = useState(false)

  const [idDesc, setIdDesc] = useState(isHasKeys ? 1 : 0)
  const idDescArr = [" ", "사용할수있는 아이디 입니다", "사용할수없는 아이디 입니다"]
  useEffect(() => {
    if (Object.keys(_driver).length === 0) return
  }, [])

  //onClick...
  const onClickCancel = () => {
    navigate("/app/driver", { state: { searchInfoText, serachInfoIndex, searchInfoHub, searchInfoCurrent } })
  }
  const onClickSave = async () => {
    if (driverName === "" || region === "" || area === "" || phoneNo === "" || id === "" || (!isHasKeys && password === "") || idDesc !== 1) return
    let temp = {
      driverName: driverName,
      region: region,
      area: area,
      phoneNo: phoneNo,
      id: id,
      isOkcheon: check ? 1 : 0,
    }
    if (password !== "") {
      temp = { ...temp, password: password }
    }
    if (!isHasKeys) {
      //신규등록
      await postDriver({ ...temp })
    } else {
      //수정
      await patchDriver({ ...temp, driverSeq: _driver.driverSeq })
    }
    navigate("/app/driver")
  }
  const onClickDelete = async () => {
    const result = await deleteDriver({ driverSeq: _driver.driverSeq })
    navigate("/app/driver")
  }
  const onFocusIn = async () => {
    setIdDesc(0)
  }
  const onFocusOut = async () => {
    if (id === "") return
    //신규등록
    if (!isHasKeys) {
      const result = await getDriverCheckId({ id: id })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    } else {
      //수정
      const result = await getDriverCheckId({ id: id, driverSeq: _driver.driverSeq })
      if (result === 1) {
        setIdDesc(1)
      } else {
        setIdDesc(2)
      }
    }
  }
  //end onClick...
  return (
    <Layout navi={"기사관리"}>
      <SEO title={"기사관리"} />
      <BasicPopup
        open={saveCheckPopup}
        onClose={(value) => {
          if (value === "확인") {
            setSaveCheckPopup(false)
          }
        }}
        content={"빈칸이 존재합니다"}
        selectedValue={["확인"]}
      />
      <BasicPopup
        open={popup}
        onClose={(value) => {
          if (value === "취소") {
            setPopup(false)
          } else if (value === "확인") {
            onClickDelete()
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <PageName title={["본사", !isHasKeys ? "등록" : "상세"]} style={{ marginBottom: "40px" }}></PageName>
      <Section>
        <CheckBox label={"옥천허브기사"} value={check} setValue={setCheck} style={{ marginRight: "15px", width: "20px", height: "20px" }} />
      </Section>

      <Splitline></Splitline>
      <Section>
        <div style={{ display: "flex", width: "100%" }}>
          <AlignDiv>
            <InputBox label="기사명" value={driverName} setValue={setDriverName}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="관할구역" marginLeft={10} value={region} setValue={setRegion}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="지역" marginLeft={10} value={area} setValue={setArea}></InputBox>
          </AlignDiv>
          <AlignDiv>
            <InputBox label="휴대폰번호" marginLeft={10} value={phoneNo} setValue={setPhoneNo}></InputBox>
          </AlignDiv>
        </div>
      </Section>
      <Splitline mb={17}></Splitline>

      <div style={{ display: "flex", marginBottom: "2px" }}>
        <InputBox label="아이디" fullWidth={true} value={id} setValue={setId} onFocusIn={onFocusIn} onFocusOut={onFocusOut}></InputBox>
        <InputBox label="비밀번호" isPassword={true} marginLeft={10} fullWidth={true} value={password} setValue={setPassword}></InputBox>
      </div>
      <div style={{ display: "flex", height: "20px" }}>
        <IDdesc isEnable={idDesc === 1}>{idDescArr[idDesc]}</IDdesc>
      </div>
      <Splitline mb={20}></Splitline>
      {!isHasKeys ? (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton
          onClickCancel={onClickCancel}
          onClickSave={onClickSave}
          onClickDelete={() => {
            setPopup(true)
          }}
        ></FotterButton>
      )}
    </Layout>
  )
}
export default DriverContainer(DriverNew)
const PStyled = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const IDdesc = styled.p`
  font-family: "NanumSquareRegular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: ${(props) => (props.isEnable ? "#00adee" : "#000")};
`

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const AlignDiv = styled.div`
  margin-right: ${(props) => (props.end ? "0px" : "2px")};
`
const Section = styled.section`
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
