import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getFreights,
  initFreight,
  getFreight,
  postFreight,
  patchFreight,
  deleteFreight,
  patchFreightsPrint,
  getFreightDownloadExcel,
  getPointToBranch,
  setFreightPrint,
  initFreightPrint,
  postFreightsUpload,
} = actions.FreightAction
const {
  getDocuments,
  initDocument,
  getDocument,
  postDocument,
  patchDocument,
  deleteDocument,
  patchDocumentsPrint,
  getDocuDownloadExcel,
  setDocumentPrint,
  initDocumentPrint,
  postDocumentsUpload,
} = actions.DocumentAction
const { getPoints } = actions.PointAction
const { getBranchs, getBranchList } = actions.BranchAction
const { setDocuPage } = actions.ConfigAction
// redux state > component mapping
const mapStateToProps = (state) => ({
  _isDocuPage: state.ConfigReducer._isDocuPage,

  _freights: state.FreightReducer._freights,
  _freightsTotal: state.FreightReducer._freightsTotal,
  _freight: state.FreightReducer._freight,
  _documents: state.DocumentReducer._documents,
  _documentsTotal: state.DocumentReducer._documentsTotal,
  _document: state.DocumentReducer._document,
  _points: state.PointReducer._points,
  _branchs: state.BranchReducer._branchs,
  _freightPrint: state.FreightReducer._freightPrint,
  _documentPrint: state.DocumentReducer._documentPrint,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getFreights: (param) => dispatch(getFreights(param)),
  initFreight: (param) => dispatch(initFreight(param)),
  getFreight: (param) => dispatch(getFreight(param)),
  postFreight: (param) => dispatch(postFreight(param)),
  patchFreight: (param) => dispatch(patchFreight(param)),
  deleteFreight: (param) => dispatch(deleteFreight(param)),
  patchFreightsPrint: (param) => dispatch(patchFreightsPrint(param)),

  getDocuments: (param) => dispatch(getDocuments(param)),
  initDocument: (param) => dispatch(initDocument(param)),
  getDocument: (param) => dispatch(getDocument(param)),
  postDocument: (param) => dispatch(postDocument(param)),
  patchDocument: (param) => dispatch(patchDocument(param)),
  deleteDocument: (param) => dispatch(deleteDocument(param)),
  patchDocumentsPrint: (param) => dispatch(patchDocumentsPrint(param)),

  getPoints: (param) => dispatch(getPoints(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
  getBranchList: (param) => dispatch(getBranchList(param)),

  getDocuDownloadExcel: (param) => dispatch(getDocuDownloadExcel(param)),
  getFreightDownloadExcel: (param) => dispatch(getFreightDownloadExcel(param)),
  setDocuPage: (param) => dispatch(setDocuPage(param)),

  getPointToBranch: (param) => dispatch(getPointToBranch(param)),

  setFreightPrint: (param) => dispatch(setFreightPrint(param)),
  setDocumentPrint: (param) => dispatch(setDocumentPrint(param)),
  initFreightPrint: () => dispatch(initFreightPrint()),
  initDocumentPrint: () => dispatch(initDocumentPrint()),

  postFreightsUpload: (param) => dispatch(postFreightsUpload(param)),
  postDocumentsUpload: (param) => dispatch(postDocumentsUpload(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
