import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import imageApis from "../../apis/imageApis"
import SEO from "../seo"
import BoardContainer from "../../containers/boardContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Button from "../component/common/button"
import FotterButton from "../component/common/fotterButton"
import BasicPopup from "../../popups/basicPopup"
import xIcon from "../../images/x_btn.png"

const NoticeNew = (props) => {
  const { _board, postBoard, patchBoard, deleteBoard } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [deletePopup, setDeletePopup] = useState(false)
  const [inputBoxState1, setInputBoxState1] = useState("") // 제목
  const [inputBoxState2, setInputBoxState2] = useState(_board.content || "") // 내용
  const [image, setImage] = useState({ file: null, base64: null, imageUri: null })
  const imageInput = useRef()

  useEffect(() => {
    if (Object.keys(_board).length === 0) return

    setInputBoxState1(_board.title || "")
    setInputBoxState2(_board.content || "")
    setImage(
      _board.images.length > 0 ? { file: null, base64: null, imageUri: _board.images[0].imageUrl } : { file: null, base64: null, imageUri: null }
    )
  }, [])

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const onClickSave = async () => {
    if (inputBoxState1 === "" || inputBoxState2 === "") return setPopup({ open: true, text: "빈 칸이 존재합니다." })

    let result = false
    if (Object.keys(_board).length === 0) {
      const param = {
        title: inputBoxState1,
        content: inputBoxState2,
      }

      if (image.file !== null) {
        const form_data = new FormData()
        form_data.append("image", image.file)
        const imageUpload = await imageApis.uploadImage(form_data)
        param.images = [imageUpload.data.image]
      }

      result = await postBoard(param)
    } else {
      const param = {
        boardSeq: _board.boardSeq,
        title: inputBoxState1,
        content: inputBoxState2,
        status: 1, //"상태(0: 미사용, 1: 사용)"
      }

      if (image.file !== null) {
        const form_data = new FormData()
        form_data.append("image", image.file)
        const imageUpload = await imageApis.uploadImage(form_data)
        param.images = [imageUpload.data.image]
      } else if (image.imageUri !== null) {
        param.images = [image.imageUri]
      }

      result = await patchBoard(param)
    }
    if (result) navigate(-1)
  }

  return (
    <Layout navi={"공지관리"}>
      <SEO title={"공지관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <BasicPopup
        open={deletePopup}
        onClose={async (value) => {
          if (value === "취소") {
            setDeletePopup(false)
          } else if (value === "확인") {
            await deleteBoard({ boardSeq: _board.boardSeq })
            setDeletePopup(false)
            navigate(-1)
          }
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <PageName title={["공지관리", "등록"]}></PageName>
      <table style={{ margin: "30px auto 40px", width: "600px" }}>
        <tbody>
          <tr style={{ borderTop: "1px solid #7e96ad" }}>
            <TdStyled>제목</TdStyled>
            <td>
              <TitleStyled value={inputBoxState1} onChange={(e) => setInputBoxState1(e.target.value)}></TitleStyled>
            </td>
          </tr>
          <tr>
            <TdStyled>내용</TdStyled>
            <td>
              <ContentStyled value={inputBoxState2} onChange={(e) => setInputBoxState2(e.target.value)}></ContentStyled>
            </td>
          </tr>
          <tr style={{ borderBottom: "1px solid #7e96ad" }}>
            <TdStyled>이미지</TdStyled>
            <td>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "5px",
                }}
              >
                <input
                  type={"file"}
                  ref={imageInput}
                  style={{ display: "none" }}
                  value={""}
                  onChange={async (e) => {
                    const file = e.target.files[0]
                    const base64Image = await toBase64(file)
                    setImage({ base64: base64Image, file: file, imageUri: null })
                  }}
                  accept={"image/*"}
                />
                <Button onClick={() => imageInput.current.click()}>추가</Button>
                <div
                  style={{
                    display: image.file !== null || image.imageUri !== null ? "flex" : "none",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <p
                    style={{
                      overflowX: "hidden",
                      flex: 1,
                      width: "0px",
                      marginRight: "5px",
                      color: "#35363a",
                      fontSize: "14px",
                      fontFamily: "NanumSquareRegular",
                      lineHeight: "16px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {image.file ? image.file.name : image.imageUri || ""}
                  </p>
                  <button onClick={() => setImage({ file: null, base64: null, imageUri: null })}>
                    <img style={{ width: "20px", height: "20px" }} src={xIcon} alt={"삭제 아이콘"} />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {Object.keys(_board).length === 0 ? (
        <FotterButton onClickCancel={() => navigate(-1)} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton onClickCancel={() => navigate(-1)} onClickSave={onClickSave} onClickDelete={() => setDeletePopup(true)}></FotterButton>
      )}
    </Layout>
  )
}

export default BoardContainer(NoticeNew)

const TdStyled = styled.td`
  width: 210px;
  height: 50px;
  background-color: #eef2f7;
`
const TitleStyled = styled.input`
  width: 380px;
  height: 40px;
  border-radius: 4px;
  border: solid #dbdee5 1px;
  margin: 5px 5px 5px 5px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
`
const ContentStyled = styled.textarea`
  width: 380px;
  height: 400px;
  resize: none;
  border-radius: 4px;
  border: solid #dbdee5 1px;
  margin: 6px 6px 5px 5px;
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.42px;
`
