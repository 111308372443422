import React from "react"
import styled from "styled-components"

const Category = (props) => {
  const { items, state, setState } = props
  const buttonSelect = (v) => {
    setState(v)
  }
  return (
    <Container>
      <div style={{ display: "flex", width: "" }}>
        {items.map((v, i) => (
          <Box active={state === i} onClick={() => buttonSelect(i)} key={i}>
            <Text active={state === i}>{v}</Text>
          </Box>
        ))}
      </div>
    </Container>
  )
}
export default Category

const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #dbdee5;
  flex-grow: 1;
  width: 1200px;
  margin-top: 27px;
  margin-bottom: 20px;
`
const Box = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-bottom: ${(props) => (props.active ? "3px solid #00adee" : "none")};
  padding: 8px;
  padding-bottom: ${(props) => (props.active ? "5px" : "8px")};
`
const Text = styled.p`
  font-family: ${(props) => (props.active ? "NanumSquareBold" : "NanumSquareRegular")};
  color: ${(props) => (props.active ? "#35363a" : "#acb0bb")};
  font-size: 18px;
  line-height: 31.5px;
  letter-spacing: -0.54px;
`
