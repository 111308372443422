import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getFreightTracks,
  getFreights,
  getFreight,
  postFreight,
  patchFreight,
  deleteFreight,
  patchFreightsPrint,
  getFreightTrackDownloadExcel,
} = actions.FreightAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _freightTracks: state.FreightReducer._freightTracks,
  _freightTracksTotal: state.FreightReducer._freightTracksTotal,
  _freights: state.FreightReducer._freights,
  _freight: state.FreightReducer._freight,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getFreightTracks: (param) => dispatch(getFreightTracks(param)),
  getFreights: (param) => dispatch(getFreights(param)),
  getFreight: (param) => dispatch(getFreight(param)),
  postFreight: (param) => dispatch(postFreight(param)),
  patchFreight: (param) => dispatch(patchFreight(param)),
  deleteFreight: (param) => dispatch(deleteFreight(param)),
  patchFreightsPrint: (param) => dispatch(patchFreightsPrint(param)),
  getFreightTrackDownloadExcel: (param) => dispatch(getFreightTrackDownloadExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
