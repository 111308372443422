import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import ReceiptContainer from "../../containers/receiptContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Button from "../component/common/button"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import BasicPopup from "../../popups/basicPopup"
import ZipCodePopup from "../../popups/zipCodePopup"
const sizeList = [
  { seq: -1, name: "선택" },
  { seq: 1, name: "대" },
  { seq: 2, name: "소" },
]
const payTypeList = [
  { seq: -1, name: "선택" },
  { seq: 1, name: "신용" },
  { seq: 2, name: "선불" },
  { seq: 3, name: "착불" },
]
const ParcelNew = (props) => {
  const { _freight, postFreight, _points, _branchs, patchFreight, deleteFreight, getPoints, getBranchList, getPointToBranch } = props
  const { searchInfoStartDate, searchInfoEndDate, serachInfoIndex, searchInfoText, searchInfoIsScan, searchInfoCurrent } = props.location.state
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [deletePopup, setDeletePopup] = useState(false)
  const [zipCodePopup, setZipCodePopup] = useState(false)
  const [waybillNum, setWaybillNum] = useState("") //운송장번호
  const [senderCustomer, setSenderCustomer] = useState({ seq: -1, name: "선택" }) //발신거래처지점명
  const [sender, setSender] = useState("") //발신인
  const [sendTeleNo, setSendTeleNo] = useState("") //발신인 전화번호
  const [recipientCustomer, setRecipientCustomer] = useState({ seq: -1, name: "선택" }) //수신거래처지점명
  const [recipient, setRecipient] = useState("") //수신인
  const [recipientPhone, setRecipientPhone] = useState("") //수신인 전화번호
  const [payType, setPayType] = useState({ seq: -1, name: "선택" })
  //
  const [originatingBranch, setOriginatingBranch] = useState({ name: "", seq: -1 }) //발신지사
  const [receivingBranch, setReceivingBranch] = useState({ name: "", seq: -1 }) //수신지사
  const [addr, setAddr] = useState("") //수신주소
  const [addrDetail, setAddrDetail] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [productName, setProductName] = useState("") //품명
  const [qty, setQty] = useState("") //수량
  const [price, setPrice] = useState("") //단가
  //
  const [size, setSize] = useState({ seq: -1, name: "선택" }) //크기
  const [etc, setEtc] = useState("") //비고
  const [pageName, setPageName] = useState("소화물등록")
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (Object.keys(_freight).length === 0) return
    if (Object.keys(_points).length === 0) return
    if (Object.keys(_branchs).length === 0) return
    setWaybillNum(_freight.freightNo)
    const sendPoint = _points.find((i) => i.pointSeq === _freight.sendPointSeq)
    const recvPoint = _points.find((i) => i.pointSeq === _freight.recvPointSeq)
    if (sendPoint !== undefined) {
      setSenderCustomer({ seq: sendPoint.pointSeq, name: sendPoint.customer.custName +' - '+ sendPoint.pointName })
    }
    if (recvPoint !== undefined) {
      setRecipientCustomer({
        seq: recvPoint.pointSeq,
        name: recvPoint.pointName,
      })
    }
    setSender(_freight.sendEmpName)

    setRecipient(_freight.recvEmpName)
    setRecipientPhone(_freight.recvTeleNo)
    const sendBranch = _branchs.find((i) => i.branchSeq === _freight.sendBranchSeq)
    const recvBranch = _branchs.find((i) => i.branchSeq === _freight.recvBranchSeq)
    if (sendBranch !== undefined) {
      setOriginatingBranch({
        seq: sendBranch.branchSeq,
        name: sendBranch.branchName,
      })
    }
    if (recvBranch !== undefined) {
      setReceivingBranch({
        seq: recvBranch.branchSeq,
        name: recvBranch.branchSeq == 230 ? 'CJ' : recvBranch.branchName,
      })
    }
    setAddr(_freight.recvAddr)
    setAddrDetail(_freight.recvAddrDetail)
    setZipCode(_freight.recvZipCode)
    setProductName(_freight.itemName)
    setQty(_freight.qty.toLocaleString())
    setPrice(_freight.price.toLocaleString())
    // //
    setSize(sizeList[_freight.size])
    setEtc(_freight.remark)
    setPageName("소화물상세")
    setSendTeleNo(_freight.sendTeleNo)
    {
      const t = payTypeList.find((v) => v.seq === _freight.payType)
      if (t !== undefined) {
        setPayType(t)
      }
    }
  }, [_points, _branchs, _freight])

  const getData = async () => {
    await getPoints({})
    await getBranchList({})
  }

  const onClickSave = async () => {
    if (
      senderCustomer.seq === -1 ||
      // recipientCustomer.seq === -1 ||
      sender === "" ||
      sendTeleNo === "" ||
      recipient === "" ||
      recipientPhone === "" ||
      originatingBranch.seq === -1 ||
      receivingBranch.seq === -1 ||
      addr === "" ||
      addr === null ||
      addrDetail === "" ||
      addrDetail === null ||
      zipCode === "" ||
      zipCode === null ||
      productName === "" ||
      qty === "" ||
      qty === "0" ||
      price === "" ||
      price === "0" ||
      size.seq === -1 ||
      payType.seq === -1
    ) {
      return setPopup({ open: true, text: "빈 칸이 존재합니다." })
    }

    let temp = {
      sendPointSeq: senderCustomer.seq, //발신지점코드
      recvPointSeq: recipientCustomer.seq > 0 ? recipientCustomer.seq : 0, //수신지점코드
      sendEmpName: sender, //발신자
      sendTeleNo: sendTeleNo,
      recvEmpName: recipient, //수신자
      recvTeleNo: recipientPhone, //수신자전화번호
      sendBranchSeq: originatingBranch.seq, //발신지사코드
      recvBranchSeq: receivingBranch.seq, //수신지사코드
      recvAddr: addr, //수신주소
      recvAddrDetail: addrDetail, //수신상세주소
      recvZipCode: zipCode, //수신우편번호
      itemName: productName, //품명
      qty: qty.replace(/,/g, ""), //수량
      price: price.replace(/,/g, ""), //가격
      size: size.seq, //크기(1: 대, 2: 소)
      payType: payType.seq,
      remark: etc, //비고
    }

    if (Object.keys(_freight).length === 0) {
      await postFreight({
        ...temp,
      })
    } else {
      await patchFreight({
        freightSeq: _freight.freightSeq,
        ...temp,
      })
    }
    navigate(-1)
  }

  const onSelectZipCode = async ( value ) => {

    // console.log(777,value)

    setZipCode(value)

  }

  const onSelectAddr = async ( value ) => {

    // console.log(111,value,_points)

    setAddr(value)

    let point = [] 
    
    if ( senderCustomer.seq === -1 ) {
      
      point = _points.filter( row => row.addr.trim() == value )

    }
    else {

      const custPoint = _points.filter( row => row.pointSeq === senderCustomer.seq )

      if ( custPoint.length > 0 ) {
        
        point = _points.filter( row => row.custSeq == custPoint[0].custSeq && row.addr.trim() == value )

      } // end if 

    } // end if 

    if ( point.length > 0 ) {

      // console.log( 777, value, point ) // 경기 고양시 일산동구 장항동 615-3

      onSelectRecv( { seq : point[0].pointSeq, name : point[0].pointName }, false )

    } 
    else { 

      setReceivingBranch({ name: 'CJ', seq: 230 })
      
    } // end if 

  }

  const onSelectRecv = async (value,flag) => {

    // if (senderCustomer.seq !== -1) {
    
    //   const result = await getPointToBranch({ sendPointSeq: senderCustomer.seq, recvPointSeq: value.seq })
    
    //   if (result.send && result.recv) {
    //     setOriginatingBranch({ name: result.send.branchName, seq: result.send.branchSeq })
    //     setReceivingBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })

    //   } else {
    //     setOriginatingBranch({ name: "", seq: -1 })
    //     setReceivingBranch({ name: "", seq: -1 })

    //   } // end if 

    // } // end if

    const point = _points.filter( row => row.pointSeq == value.seq )
    const branch = _branchs.filter( row => row.branchSeq == (point.length > 0 ? point[0].branchSeq : 0) )
    if (branch.length > 0) {
      setReceivingBranch({ name: branch[0].branchName, seq: branch[0].branchSeq })
    }
    else {
      setReceivingBranch({ name: "", seq: -1 })
    }

    setRecipientCustomer(value)

    // const point = _points.filter( row => row.pointSeq == value.seq )

    if ( point.length > 0 ) {

      // console.log(123,point)

      setAddr( point[0].addr ) // 서울 중구 남대문로5가 500 / 605 
      setAddrDetail( point[0].addrDetail )
      if( flag ) setZipCode( point[0].zipCode === null || point[0].zipCode === undefined ? '' : point[0].zipCode )

    } 
    else { 
      
      setAddr( "" )
      setAddrDetail( "" )
      setZipCode( "" )
      
    } // end if 

    if (value.seq !== -1) {
      const temp = _points.find((i) => i.pointSeq === value.seq)
      if (
        temp !== -1 &&
        !_points
          .filter((i) => i.custSeq === temp.custSeq)
          .map((i) => i.pointSeq)
          .includes(senderCustomer.seq)
      ) {
        setSenderCustomer({ seq: -1, name: "선택" })
      }
    }
  }

  return (
    <Layout navi={"접수관리"}>
      <SEO title={"접수관리"} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <BasicPopup
        open={deletePopup}
        onClose={async (value) => {
          if (value === "확인") {
            const result = await deleteFreight({
              freightSeq: _freight.freightSeq,
            })
            if (result) navigate(-1)
          }
          setDeletePopup(false)
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <ZipCodePopup open={zipCodePopup} onClose={() => setZipCodePopup(false)} setAddr={onSelectAddr} setZipCode={onSelectZipCode} />
      <section>
        <PageName title={["접수관리", `${pageName}`]}></PageName>

        <Button
          display={Object.keys(_freight).length === 0 ? "none" : "block"}
          marginTop={33}
          marginBottom={17}
          onClick={() => {
            window.open(`http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${waybillNum}`, "_self")
            //window.open(`http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${waybillNum}`, "_blank")
          }}
        >
          CJ 택배조회 바로가기
        </Button>

        <Box>
          <InputBox readOnly={true} label="운송장번호" value={waybillNum} setValue={setWaybillNum} />
          <InputBox width={'280'} // 190
            type={"select"}
            label="발신거래처지점명"
            marginLeft={12}
            item={
              recipientCustomer.seq === -1
                ? [{ seq: -1, name: "선택" }, ..._points.sort((a,b)=>a.customer.custName > b.customer.custName ? 1 :-1).map((i) => ({ seq: i.pointSeq, name: i.customer.custName+' - '+i.pointName }))]
                : [
                    { seq: -1, name: "선택" },
                    ..._points.sort((a,b)=>a.customer.custName > b.customer.custName ? 1 :-1)
                      .filter((i) => i.custSeq === _points.find((i) => i.pointSeq === recipientCustomer.seq).custSeq)
                      .map((i) => ({ seq: i.pointSeq, name: i.customer.custName+' - '+i.pointName })),
                  ]
            }
            value={senderCustomer.name}
            setValue={async (value) => {

              // if (recipientCustomer.seq !== -1) {
              //   const result = await getPointToBranch({ sendPointSeq: value.seq, recvPointSeq: recipientCustomer.seq })
              //   if (result.send && result.recv) {
              //     setOriginatingBranch({ name: result.send.branchName, seq: result.send.branchSeq })
              //     setReceivingBranch({ name: result.recv.branchName, seq: result.recv.branchSeq })

              //   } else {
              //     setOriginatingBranch({ name: "", seq: -1 })
              //     setReceivingBranch({ name: "", seq: -1 })

              //   } // end if 

              // } // end if 

              const point = _points.filter( row => row.pointSeq == value.seq )
              const branch = _branchs.filter( row => row.branchSeq == (point.length > 0 ? point[0].branchSeq : 0) )
              if (branch.length > 0) {
                setOriginatingBranch({ name: branch[0].branchName, seq: branch[0].branchSeq })
              }
              else {
                setOriginatingBranch({ name: "", seq: -1 })
              }

              setSenderCustomer(value)

              if (value.seq !== -1) {
                const temp = _points.find((i) => i.pointSeq === value.seq)
                if (
                  temp !== -1 &&
                  !_points
                    .filter((i) => i.custSeq === temp.custSeq)
                    .map((i) => i.pointSeq)
                    .includes(recipientCustomer.seq)
                ) {
                  setRecipientCustomer({ seq: -1, name: "선택" })
                }
              }
            }}
          />
          <InputBox marginLeft={12} label="발신인" value={sender} setValue={setSender} />
          <InputBox marginLeft={12} label="발신인전화번호" value={sendTeleNo} setValue={setSendTeleNo} />
          <InputBox
            type={"select"}
            label="수신거래처지점명"
            marginLeft={12}
            item={
              senderCustomer.seq === -1
                ? [{ seq: -1, name: "선택" }, ..._points.sort((a,b)=>a.customer.custName > b.customer.custName ? 1 :-1).map((i) => ({ seq: i.pointSeq, name: i.pointName }))]
                : [
                    { seq: -1, name: "선택" },
                    ..._points.sort((a,b)=>a.customer.custName > b.customer.custName ? 1 :-1)
                      .filter((i) => i.custSeq === _points.find((i) => i.pointSeq === senderCustomer.seq).custSeq)
                      .map((i) => ({ seq: i.pointSeq, name: i.pointName })),
                  ]
            }
            value={recipientCustomer.name}
            setValue={(value)=>onSelectRecv(value,true)}
          />
          <InputBox marginLeft={12} label="수신인" value={recipient} setValue={setRecipient} />
        </Box>
        <Box>
          <InputBox label="수신인전화번호" value={recipientPhone} setValue={setRecipientPhone} />
          <InputBox readOnly={true} marginLeft={12} label="발신지사" value={originatingBranch.name} />
          <InputBox readOnly={true} marginLeft={12} label="수신지사" value={receivingBranch.name} />

          <InputBox type={"address"} label="수신주소" marginLeft={12} value={addr} setValue={onSelectAddr} onClick={() => setZipCodePopup(true)} />
          <InputBox label="상세주소" marginLeft={12} value={addrDetail} setValue={setAddrDetail} />
          <InputBox readOnly={true} label="우편번호" marginLeft={12} value={zipCode} />
        </Box>
        <Box>
          <InputBox label="품명" value={productName} setValue={setProductName} />
          <InputBox marginLeft={12} type={"qty"} label="수량" value={Number(qty.replace(/,/g, "")).toLocaleString()} setValue={setQty} />
          <InputBox marginLeft={12} type={"price"} label="단가" value={Number(price.replace(/,/g, "")).toLocaleString()} setValue={setPrice} />
        </Box>
        <Box>
          <InputBox type={"select"} label="크기" item={sizeList} value={size.name} setValue={setSize} />
          <InputBox type={"select"} marginLeft={12} label="신용/착불/선불" item={payTypeList} value={payType.name} setValue={setPayType} />
          <InputBox marginLeft={12} label="비고" fullWidth={true} value={etc} setValue={setEtc} />
        </Box>

        <div style={{ marginTop: 20 }}>
          {Object.keys(_freight).length === 0 ? (
            <FotterButton
              onClickCancel={() =>
                navigate("/app/receipt", {
                  state: {
                    searchInfoTap: 1,
                    searchInfoStartDate,
                    searchInfoEndDate,
                    serachInfoIndex,
                    searchInfoText,
                    searchInfoIsScan,
                    searchInfoCurrent,
                  },
                })
              }
              onClickSave={onClickSave}
            ></FotterButton>
          ) : (
            <FotterButton
              onClickCancel={() => {
                // console.log(4444444, searchInfoIsScan)
                navigate("/app/receipt", {
                  state: {
                    searchInfoTap: 1,
                    searchInfoStartDate,
                    searchInfoEndDate,
                    serachInfoIndex,
                    searchInfoText,
                    searchInfoIsScan,
                    searchInfoCurrent,
                  },
                })
              }}
              onClickSave={onClickSave}
              onClickDelete={() => setDeletePopup(true)}
            ></FotterButton>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ReceiptContainer(ParcelNew)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  padding-top: 17px;
  padding-bottom: 20px;
  border-bottom: 4px solid #fafafb;
`
