import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import SEO from "../seo"
import FreightContainer from "../../containers/freightContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import SectionName from "../component/common/sectionName"
import DatePicker from "../component/common/datePicker"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave, GetStatus } from "../../utils/util"
import moreBtn from "../../images/more_btn.png"

const Parcel = (props) => {
  const { _freightTracks, _freightTracksTotal, getFreightTracks, getFreightTrackDownloadExcel } = props
  const [popup, setPopup] = useState(false)
  const [signPopup, setSignPopup] = useState({ open: false, imageUrl: null })
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))
  const [tempSearchStartDate, setTempSearchStartDate] = useState(moment().startOf("months"))
  const [tempSearchEndDate, setTempSearchEndDate] = useState(moment().endOf("months"))
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  const [current, setCurrent] = useState(1)
  const [remarkNum, setRemarkNum] = useState(-1)
  const onClickSearch = async () => {
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    if (tempSearchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(3)
    //   setPopup(true)
    //   return
    // }

    let temp = { page: 0, count: 20, startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (tempSearchIndex !== 0){ //&& tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getFreightTracks({ ...temp })
    setCurrent(1)

  }
  const onClickExcelSave = async () => {
    let temp = { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getFreightTrackDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "소화물 리스트" })
  }
  const onClickLookUp = async () => {
    setTempSearchIndex(0)
    setTempSearchText("")
    setSearchIndex(0)
    setSearchText("")
    setStartDate(tempSearchStartDate)
    setEndDate(tempSearchEndDate)
    
    let dateFr = moment(tempSearchStartDate).format("yyyy-MM-DD")
    let dateTo = moment(tempSearchEndDate).format("yyyy-MM-DD")

    // if (!moment(tempSearchStartDate).isBefore(moment(tempSearchEndDate))) {
    if ( dateFr > dateTo ) {
      setMessageIndex(1)
      setPopup(true)
      return
    }

    let temp = {
      page: 0,
      count: 20,
      startDate: moment(tempSearchStartDate).format("yyyy-MM-DD"),
      endDate: moment(tempSearchEndDate).format("yyyy-MM-DD"),
    }
    await getFreightTracks({ ...temp })
    setCurrent(1)
  }

  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    getData()
  }, [current])

  const getData = async () => {
    let temp = { page: current - 1, count: 20, startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getFreightTracks({ ...temp })
  }
  return (
    <Layout navi={"소화물관리"}>
      <SEO title={"소화물관리"} />
      <BasicPopup
        open={signPopup.open}
        onClose={() => setSignPopup({ open: false, imageUrl: null })}
        imageUrl={signPopup.imageUrl}
        content={""}
        selectedValue={["확인"]}
      />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <section>
        <PageName title={["소화물관리"]}></PageName>
        <SectionName>소화물 관리</SectionName>
        <Box justifyContent={"space-between"}>
          <Box>
            <DatePicker
              label={"스캔일자"}
              startDate={tempSearchStartDate}
              setStartDate={setTempSearchStartDate}
              endDate={tempSearchEndDate}
              setEndDate={setTempSearchEndDate}
              onClick={onClickLookUp}
            />
            <Search
              item={[
                "선택",
                "운송장번호",
                "발신거래처지점명",
                "발신인",
                "수신거래처지점명",
                "수신인",
                "수신주소",
                "발신지사",
                "수신지사",
                "스캔담당",
                "위치",
                "상태",
                "크기",
              ]}
              index={tempSearchIndex}
              setIndex={setTempSearchIndex}
              text={tempSearchText}
              setText={setTempSearchText}
              onClickSearch={onClickSearch}
            />
          </Box>
          <Button onClick={onClickExcelSave}>엑셀 저장</Button>
        </Box>
      </section>

      <section style={{ marginTop: "21px" }}>
        <table style={{ marginBottom: 35.4 }}>
          <thead>
            <tr>
              <th>번호</th>
              <th style={{ width: "75px" }}>운송장번호</th>
              <th>{`발신\n거래처 지점명`}</th>
              <th>발신자</th>
              <th>{`수신\n거래처 지점명`}</th>
              <th>수신자</th>
              <th style={{ width: "180px" }}>수신주소</th>
              <th>{`발신\n지사`}</th>
              <th>{`수신\n지사`}</th>
              <th>스캔일자</th>
              <th>{`스캔\n담당`}</th>
              <th>위치</th>
              <th>상태</th>
              <th style={{ width: "35px" }}>크기</th>
              <th style={{ width: "35px" }}>비고</th>
              <th>{`사진\n서명`}</th>
            </tr>
          </thead>
          <tbody>
            {_freightTracks.map((listItem, listIndex) => {
              return (
                <tr key={listIndex}>
                  <td>{_freightTracksTotal - (current - 1) * 10 - listIndex}</td>
                  <td>{listItem.freight.freightNo}</td>
                  <td>{listItem.freight.sendPoint.pointName}</td>
                  <td>{listItem.freight.sendEmpName}</td>
                  <td>{listItem.freight.recvPoint ? listItem.freight.recvPoint.pointName : ""}</td>
                  <td>{listItem.freight.recvEmpName}</td>
                  <td>{`${listItem.freight.recvPoint ? listItem.freight.recvPoint.addr : ""} ${listItem.freight.recvPoint ? listItem.freight.recvPoint.addrDetail : ""}`}</td>
                  <td>{listItem.freight.sendBranch.branchName}</td>
                  <td>{listItem.freight.recvBranch.branchName}</td>
                  <td>{moment(listItem.regDatetime).format(`YYYY.MM.DD`) + "\n" + moment(listItem.regDatetime).format(`H:mm`)}</td>
                  <td>{listItem.scanName}</td>
                  <td>{`${listItem.addr}\n${listItem.addrDetail}`}</td>
                  <td>{GetStatus(listItem.status)}</td>
                  <td>{listItem.freight.size === 1 ? "대" : "소"}</td>
                  <td>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div style={{ width: "100%", height: "calc( 100% - 9px )", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {(listItem.freight.remark + "").length > 1 ? listItem.freight.remark.substring(0, 1) + "..." : listItem.freight.remark}
                      </div>
                      {(listItem.freight.remark + "").length > 1 && (
                        <div style={{ width: "100%", height: "9px", display: "flex", justifyContent: "flex-end" }}>
                          <button
                            onClick={() => {
                              if (listIndex === remarkNum) setRemarkNum(-1)
                              else setRemarkNum(listIndex)
                            }}
                            onBlur={() => setRemarkNum(-1)}
                            style={{ height: "9px" }}
                          >
                            <img src={moreBtn} alt={"더보기"} style={{ display: "flex" }} />
                          </button>

                          {listIndex === remarkNum && (
                            <div style={{ position: "relative" }}>
                              <div style={{ position: "absolute", left: "-194.5px", top: "9px", zIndex: 10 }}>
                                <div
                                  style={{
                                    width: "0px",
                                    height: "0px",
                                    borderBottom: "5.6px solid #fff",
                                    borderLeft: "5.65px solid transparent",
                                    borderRight: "5.65px solid transparent",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    width: "380px",
                                    height: "136px",
                                    overflowY: "auto",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    borderRadius: "4px",
                                    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.3)",
                                    padding: "11px",
                                  }}
                                >
                                  <p style={{ whiteSpace: "pre-wrap" }}>{listItem.freight.remark}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setSignPopup({ open: true, imageUrl: listItem.photoUrl || listItem.signUrl })
                      }}
                    >
                      확인
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination current={current} setCurrent={setCurrent} total={_freightTracksTotal} />
      </section>
    </Layout>
  )
}

export default FreightContainer(Parcel)

const Box = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
