import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getManageFreightTracks,
  getManagePouchTracks,
  patchManageFreightPrice,
  patchManagePouchPrice,
  getFreightDownloadExcel,
  getPouchDownloadExcel,
  setManagePouchTracksSettlePrice,
  setManagePouchTracksBillPrice,
  setManageFreightTracksSettlePrice,
  setManageFreightTracksBillPrice,
} = actions.ManageAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _manageFreightTracks: state.ManageReducer._manageFreightTracks,
  _manageFreightTracksTotal: state.ManageReducer._manageFreightTracksTotal,
  _managePouchTracks: state.ManageReducer._managePouchTracks,
  _managePouchTracksTotal: state.ManageReducer._managePouchTracksTotal,

  _manageFreightBillPrice: state.ManageReducer._manageFreightBillPrice,
  _manageFreightSettlePrice: state.ManageReducer._manageFreightSettlePrice,
  _managePouchBillPrice: state.ManageReducer._managePouchBillPrice,
  _managePouchSettlePrice: state.ManageReducer._managePouchSettlePrice,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getManageFreightTracks: (param) => dispatch(getManageFreightTracks(param)),
  getManagePouchTracks: (param) => dispatch(getManagePouchTracks(param)),
  patchManageFreightPrice: (param) => dispatch(patchManageFreightPrice(param)),
  patchManagePouchPrice: (param) => dispatch(patchManagePouchPrice(param)),
  getFreightDownloadExcel: (param) => dispatch(getFreightDownloadExcel(param)),
  getPouchDownloadExcel: (param) => dispatch(getPouchDownloadExcel(param)),

  setManagePouchTracksSettlePrice: (param) => dispatch(setManagePouchTracksSettlePrice(param)),
  setManagePouchTracksBillPrice: (param) => dispatch(setManagePouchTracksBillPrice(param)),
  setManageFreightTracksSettlePrice: (param) => dispatch(setManageFreightTracksSettlePrice(param)),
  setManageFreightTracksBillPrice: (param) => dispatch(setManageFreightTracksBillPrice(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
