import React from "react"
import Layout from "../component/common/layout"
import SEO from "../seo"
import BoardContainer from "../../containers/boardContainer"

const Main = (props) => {
  return (
    <Layout navi={"메인"}>
      <SEO title={"메인"} />
    </Layout>
  )
}

export default BoardContainer(Main)
