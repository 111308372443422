import React, { useState, useEffect } from "react"
import SEO from "../seo"
import LargePouchContainer from "../../containers/largePouchContainer"
import Layout from "../component/common/layout"
import styled from "styled-components"
import PageName from "../component/common/pageName"
import { navigate } from "gatsby"
import InputBox from "../component/common/inputBox"
import FotterButton from "../component/common/fotterButton"
import BasicPopup from "../../popups/basicPopup"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import searchIcon from "../../images/search.png"
import dropIcon from "../../images/drop.png"
const useList = [
  { seq: 0, name: "미사용" },
  { seq: 1, name: "사용" },
  { seq: 2, name: "삭제" },
]
const useStyles = makeStyles(() => ({
  container: {
    "& .MuiPaper-root": {
      width: "300px",
      height: "448px",
      padding: "0",
    },
    "& .MuiDialog-paper": {},
  },
}))
const LargePouchNew = (props) => {
  const { _largePouch, _branchs, postLargePouch, patchLargePouch, deleteLargePouch, getBranchs } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [deletePopup, setDeletePopup] = useState(false)
  const [sendBranchName, setSendBranchName] = useState("")
  const [recvBranchName, setRecvBranchName] = useState("")
  const [inputBoxState3, setInputBoxState3] = useState("")
  const [inputBoxState4, setInputBoxState4] = useState({ seq: -1, name: "선택" })
  const [sendBranchPopup, setSendBranchPopup] = useState(false)
  const [recvBranchPopup, setRecvBranchPopup] = useState(false)
  useEffect(() => {
    getData()

    if (Object.keys(_largePouch).length === 0) return

    const sendBranch = _branchs.find((i) => i.branchSeq === _largePouch.sendBranchSeq)
    if (sendBranch !== undefined) {
      setSendBranchName(sendBranch.branchName)
    }
    const recvBranch = _branchs.find((i) => i.branchSeq === _largePouch.recvBranchSeq)
    if (recvBranch !== undefined) {
      setRecvBranchName(recvBranch.branchName)
    }
    setInputBoxState3(_largePouch.pouchNo)
    const useStatus = useList.find((i) => i.seq === _largePouch.status)
    setInputBoxState4({ seq: _largePouch.status, name: useStatus.name })
  }, [])

  const getData = async () => {
    await getBranchs({})
  }

  const onClickSendBranch = () => {
    setSendBranchPopup(true)
  }
  const onClickRecvBranch = () => {
    setRecvBranchPopup(true)
  }
  const onClickCancel = async () => {
    navigate("/app/largePouchManage", { state: { searchInfoText, serachInfoIndex, searchInfoCurrent } })
  }

  const onClickSave = async () => {
    if (sendBranchName === "" || recvBranchName === "" || inputBoxState4.seq === -1) return setPopup({ open: true, text: "빈 칸이 존재합니다." })

    let result = false
    if (Object.keys(_largePouch).length === 0) {
      result = await postLargePouch({
        status: inputBoxState4.seq + "",
        sendBranchSeq: _branchs.find((i) => i.branchName === sendBranchName).branchSeq,
        recvBranchSeq: _branchs.find((i) => i.branchName === recvBranchName).branchSeq,
      })
    } else {
      result = await patchLargePouch({
        largePouchSeq: _largePouch.largePouchSeq,
        status: inputBoxState4.seq + "",
        sendBranchSeq: _branchs.find((i) => i.branchName === sendBranchName).branchSeq,
        recvBranchSeq: _branchs.find((i) => i.branchName === recvBranchName).branchSeq,
      })
    }

    if (result) navigate(-1)
  }

  const onClickDelete = async () => {
    const result = await deleteLargePouch({
      largePouchSeq: _largePouch.largePouchSeq,
    })

    if (result) navigate(-1)
  }

  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <PopupStyled2
        open={sendBranchPopup}
        setOpen={setSendBranchPopup}
        branchs={_branchs}
        branchName={sendBranchName}
        setBranchName={setSendBranchName}
      ></PopupStyled2>
      <PopupStyled2
        open={recvBranchPopup}
        setOpen={setRecvBranchPopup}
        branchs={_branchs}
        branchName={recvBranchName}
        setBranchName={setRecvBranchName}
      ></PopupStyled2>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <BasicPopup
        open={deletePopup}
        onClose={(value) => {
          if (value === "확인") {
            onClickDelete()
          }
          setDeletePopup(false)
        }}
        content={"삭제하시겠습니까?"}
        selectedValue={["취소", "확인"]}
      />
      <PageName title={["대낭관리", Object.keys(_largePouch).length === 0 ? "등록" : "상세"]}></PageName>
      <div style={{ display: "flex", width: "800px", marginTop: "30px" }}>
        <InputBox type={"address"} label="발신지사" value={sendBranchName} setValue={setSendBranchName} onClick={onClickSendBranch}></InputBox>
        <InputBox
          type={"address"}
          label="수신지사"
          value={recvBranchName}
          setValue={setRecvBranchName}
          marginLeft={12}
          onClick={onClickRecvBranch}
        ></InputBox>
        <InputBox readOnly={true} label="대낭코드" value={inputBoxState3} setValue={setInputBoxState3} marginLeft={12}></InputBox>
        <InputBox type={"select"} label="대낭상태" item={useList} value={inputBoxState4.name} setValue={setInputBoxState4} marginLeft={12} />
      </div>
      <Splitline mt={17} mb={20}></Splitline>

      {Object.keys(_largePouch).length === 0 ? (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave}></FotterButton>
      ) : (
        <FotterButton onClickCancel={onClickCancel} onClickSave={onClickSave} onClickDelete={() => setDeletePopup(true)}></FotterButton>
      )}
    </Layout>
  )
}
export default LargePouchContainer(LargePouchNew)

const Splitline = styled.div`
  width: 1200px;
  height: 4px;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  background-color: #fafafb;
`

const PopupStyled2 = (props) => {
  const classes = useStyles()
  const { open, setOpen, branchs, branchName, setBranchName } = props
  const [index, setIndex] = useState(2)
  const [text, setText] = useState("")
  const [messageIndex, setMessageIndex] = useState(0)
  const [popup, setPopup] = useState(false)
  const [select, setSelect] = useState(false)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const item = ["선택", "코드", "이름"]
  const [searchResult, setSearchResult] = useState([])
  const [tempBranch, setTempBranch] = useState()
  const onClickSearch = () => {
    if (index === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } else if (text.length < 2) {
      setMessageIndex(2)
      setPopup(true)
      return
    }
    if (index === 1) {
      setSearchResult(branchs.filter((i) => i.branchNo.toString().includes(text)))
    } else if (index === 2) {
      setSearchResult(branchs.filter((i) => i.branchName.toString().includes(text)))
    }
  }
  useEffect(() => {
    if (branchName) {
      const findItem = branchs.find((i) => i.branchName === branchName)
      if (findItem !== undefined) {
        setTempBranch(findItem)
        setSearchResult([findItem])
      } else {
        setTempBranch()
        setSearchResult([])
      }
    }
  }, [branchName])
  return (
    <Dialog className={classes.container} open={open} fullWidth={true}>
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <div>
        <div style={{ display: "flex", width: "300px", height: "406px", padding: "20px", paddingBottom: "0px", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
            <p style={{ fontFamily: "NanumSquareRegular", fontSize: "14px", lineHeight: "24.5px", letterSpacing: "-0.42px", color: "#101010" }}>
              검색
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <Container>
              <SelectBox>
                <StyledSelect onClick={() => setSelect(!select)} onBlur={() => setSelect(false)}>
                  <StyledP select={item[index]}>{item[index]}</StyledP>
                  <img style={{ width: "8px", height: "6px", marginRight: "6px" }} src={dropIcon} alt={"드롭 아이콘"} />
                </StyledSelect>
                <OptionBox open={select} onMouseDown={(e) => e.preventDefault()}>
                  {item.map((option, optionIndex) => (
                    <Option
                      key={optionIndex}
                      onClick={() => {
                        setIndex(optionIndex)
                        setSelect(false)
                      }}
                    >
                      {option}
                    </Option>
                  ))}
                </OptionBox>
              </SelectBox>

              <SearchBox>
                <InputStyled value={text} onChange={(e) => setText(e.target.value)} />
                <SearchBtn onClick={onClickSearch}>
                  <img style={{}} src={searchIcon} alt={"검색 아이콘"} />
                </SearchBtn>
              </SearchBox>
            </Container>
          </div>
          <div style={{ display: "flex", marginTop: "10px", width: "100%", height: "34px", backgroundColor: "#eef2f7", alignItems: "center" }}>
            <span>
              <p
                style={{
                  fontFamily: "NanumSquareRegular",
                  fontSize: "13px",
                  lineHeight: "15px",
                  letterSpacing: "-0.39px",
                  color: "#445e78",
                  marginLeft: "10px",
                }}
              >
                코드
              </p>
            </span>
            <span style={{ marginLeft: "85px" }}>
              <p style={{ fontFamily: "NanumSquareRegular", fontSize: "13px", lineHeight: "15px", letterSpacing: "-0.39px", color: "#445e78" }}>
                이름
              </p>
            </span>
          </div>
          <div style={{ display: "flex", overflowY: "scroll", flexDirection: "column", height: "270px", marginRight: "-15px" }}>
            {searchResult.map((v, i) => {
              return (
                <div key={i} style={{ height: "50px", borderBottom: "1px solid #dbdee5", display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", width: "80px", height: "100%", alignItems: "center", paddingLeft: "10px" }}>
                    <PStyled1 style={{ wordBreak: "break-all" }}>{v.branchNo}</PStyled1>
                  </div>
                  <div style={{ display: "flex", width: "80px", flex: "1", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <PStyled1 style={{ wordBreak: "break-all" }}>{v.branchName}</PStyled1>
                  </div>
                  <div style={{ display: "flex", width: "70px", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <button
                      style={{
                        width: "50px",
                        height: "30px",
                        borderRadius: "4px",
                        color: "#00adee",
                        border: "1px solid #00adee",
                        backgroundColor: tempBranch === v ? "#00adee" : "#fff",
                      }}
                      onClick={() => {
                        if (tempBranch === v) setTempBranch()
                        else setTempBranch(v)
                      }}
                    >
                      {tempBranch === v && <PStyled1 style={{ color: "#fff" }}>해제</PStyled1>}
                      {tempBranch !== v && <PStyled1 style={{ color: "#00adee" }}>선택</PStyled1>}
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <ButtonView>
          {["취소", "확인"].map((v, i) => {
            return (
              <Button
                key={i}
                one={i === 0}
                onClick={() => {
                  setOpen(false)
                  if (v === "취소") {
                    setIndex(2)
                    setText("")
                    //
                  } else if (v === "확인") {
                    if (tempBranch) {
                      setBranchName(tempBranch.branchName)
                      setSearchResult([tempBranch])
                    } else {
                      setBranchName("")
                      setSearchResult([])
                    }
                    setIndex(2)
                    setText("")
                  }
                }}
              >
                {v}
              </Button>
            )
          })}
        </ButtonView>
      </div>
    </Dialog>
  )
}

const PStyled1 = styled.p`
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const ButtonView = styled.section`
  display: flex;
  height: 42px;
`

const Button = styled.button`
  background-color: #ffffff;
  width: 100%;
  padding: 0px;
  border-top: 1px solid #dbdee5;
  border-radius: 0;
  color: ${(props) => (props.one ? "#8a8e98" : "#00adee")};
  font-size: 14px;
  font-family: NanumSquareExtraBold;
  line-height: 24.45px;
  letter-spacing: -0.42px;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 410px;
  height: 40px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`

// SELECT
const SelectBox = styled.div`
  position: relative;
  width: 69px;
  height: 40px;
  z-index: 100;
`
const StyledSelect = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 42px;
  left: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: 69px;
  max-height: 160px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
`
const Option = styled.button`
  overflow-x: hidden;
  display: block;
  width: 100%;
  /* min-width: 100px; */
  height: 40px;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  border-top: 1px solid #dbdee5;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-top: none;
  }
`

// SEARCH
const SearchBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-left: 1px solid #dbdee5;
`
const InputStyled = styled.input`
  background-color: ${(props) => (props.readOnly ? "#eef2f7" : "#fff")};
  flex: 1;
  padding-left: 10px;
  max-width: 150px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: #35363a;
`
const SearchBtn = styled.button`
  background-color: #00adee;
  width: 40px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
const StyledP = styled.p`
  margin-left: 10px;
  font-family: NanumSquareRegular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.42px;
  color: ${(props) => (props.select === "선택" ? "#acb0bb" : "#35363a")};
`
