import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getDrivers } = actions.DriverAction
const {
  getPoints,
  getPoint,
  postPoint,
  patchPoint,
  deletePoint,
  initPoint,
  getPointCheckId,
  getDownloadExcel,
  postPointsUpload,
} = actions.PointAction

const { getBranchs } = actions.BranchAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _drivers: state.DriverReducer._drivers,
  _points: state.PointReducer._points,
  _point: state.PointReducer._point,
  _pointsTotal: state.PointReducer._pointsTotal,
  _customer: state.CustomerReducer._customer,
  _branchs: state.BranchReducer._branchs,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getPointCheckId: (param) => dispatch(getPointCheckId(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
  getDrivers: (param) => dispatch(getDrivers(param)),
  getPoints: (param) => dispatch(getPoints(param)),
  getPoint: (param) => dispatch(getPoint(param)),
  postPoint: (param) => dispatch(postPoint(param)),
  patchPoint: (param) => dispatch(patchPoint(param)),
  deletePoint: (param) => dispatch(deletePoint(param)),
  initPoint: () => dispatch(initPoint()),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
  postPointsUpload: (param) => dispatch(postPointsUpload(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
