import React from "react"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"

const Layout = (props) => {
  const { children, navi } = props

  return (
    <Container>
      <Header navi={navi} />
      <ChildContainer>
        <ChildView>{children}</ChildView>
        <Footer />
      </ChildContainer>
    </Container>
  )
}

export default Layout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ChildContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ChildView = styled.div`
  flex: 1;
  width: 1300px;
  margin: 0 auto;
  padding: 30px 0;
`
