import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import styled from "styled-components"
//
import Button from "../component/common/button"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Pagination from "../component/common/pagination"
import Search from "../component/common/search"
import SectionName from "../component/common/sectionName"
import BranchContainer from "../../containers/branchContainer"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const Branch = (props) => {
  const { getBranchs, getBranch, _branchs, _branchsTotal, initBranch, getDownloadExcel } = props
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")
  // 페이지네이션
  const [current, setCurrent] = useState(searchInfoCurrent || 1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }

    let temp = { page: 0, count: 20 }
    if (tempSearchIndex !== 0){ //&& tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getBranchs({ ...temp })
    setCurrent(1)
  }
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "지사리스트" })
  }
  const onClickRegister = async () => {
    await initBranch()
    navigate("/app/branchNew")
  }
  //end onClick ..

  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getBranchs({ ...temp })
  }

  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])

  return (
    <Layout navi={"지사관리"}>
      <SEO title={"지사관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["지사관리"]} style={{ marginBottom: "40px" }}></PageName>
      <SectionName>지사관리</SectionName>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Search
          item={["선택", "지사코드", "지사명", "아이디", "지사장명", "운영구분", "주소", "우편번호", "전화번호", "팩스번호", "등록자"]}
          index={tempSearchIndex}
          setIndex={setTempSearchIndex}
          text={tempSearchText}
          setText={setTempSearchText}
          onClickSearch={onClickSearch}
        />
        <div style={{ diplay: "flex", marginBottom: "18.4px" }}>
          <Button onClick={onClickExcelSave}>{"엑셀 저장"}</Button>
          <Button onClick={onClickRegister} marginLeft={5}>
            {"등록"}
          </Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>지사코드</th>
            <th>지사명</th>
            <th>아이디</th>
            <th>지사장명</th>
            <th>개설일자</th>
            <th>운영구분</th>
            <th>주소</th>
            <th>우편번호</th>
            <th>전화번호</th>
            <th>팩스번호</th>
            <th>등록일자</th>
            <th>등록자</th>
          </tr>
        </thead>
        <tbody>
          {_branchs.map((v, i) => (
            <tr key={v.branchSeq || i}>
              <td>{_branchsTotal - (current - 1) * 20 - i}</td>
              <td>{v.branchNo}</td>
              <td>
                <button
                  onClick={async () => {
                    const result = await getBranch({ branchSeq: v.branchSeq })
                    if (result) {
                      navigate("/app/branchNew", {
                        state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                      })
                    }
                  }}
                >
                  <u>{v.branchName}</u>
                </button>
              </td>
              <td>{v.userId}</td>
              <td>{v.branchEmpName}</td>
              <td>{v.openDate}</td>
              <td>{v.isEnabled == 1 ? "운영" : "미운영"}</td>
              <td>{`${v.addr}\n${v.addrDetail}`}</td>
              <td>{v.zipCode}</td>
              <td>{v.teleNo}</td>
              <td>{v.faxNo}</td>
              <td>{v.regDatetime}</td>
              <td>{v.registName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination current={current} setCurrent={setCurrent} total={_branchsTotal} />
    </Layout>
  )
}

export default BranchContainer(Branch)

const Test = styled(Button)`
  color: red;
`
const Section = styled.section`
  font-family: "NanumSquareRegular";
  padding: 20px 0;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  margin-left: auto;
  flex: none;
`
