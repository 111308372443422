import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getBoards, initBoard, getBoard, postBoard, patchBoard, deleteBoard } = actions.BoardAction
// redux state > component mapping
const mapStateToProps = (state) => ({
  _boards: state.BoardReducer._boards,
  _boardsTotal: state.BoardReducer._boardsTotal,
  _board: state.BoardReducer._board,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getBoards: (param) => dispatch(getBoards(param)),
  initBoard: (param) => dispatch(initBoard(param)),
  getBoard: (param) => dispatch(getBoard(param)),
  postBoard: (param) => dispatch(postBoard(param)),
  patchBoard: (param) => dispatch(patchBoard(param)),
  deleteBoard: (param) => dispatch(deleteBoard(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
