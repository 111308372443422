import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import SEO from "../seo"
import BoardContainer from "../../containers/boardContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import SectionName from "../component/common/sectionName"

const Notice = (props) => {
  const { _boards, _boardsTotal, getBoards, initBoard, getBoard } = props
  // 페이지네이션
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    getData()
  }, [current])

  const getData = async () => {
    await getBoards({ page: current - 1, count: 20 })
  }

  //등록
  const onClickRegister = async () => {
    await initBoard()
    navigate("/app/noticeNew")
  }

  return (
    <Layout navi={"공지관리"}>
      <SEO title={"공지관리"} />
      <PageName title={["공지관리"]}></PageName>
      <SectionName>공지관리</SectionName>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onClickRegister}>{"등록"}</Button>
      </div>
      <table style={{ marginTop: "20px", marginBottom: "40px" }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>등록일자</th>
            <th>등록자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {_boards.map((v, i) => (
            <tr
              key={i}
              onClick={async () => {
                await getBoard({ boardSeq: v.boardSeq })
                navigate("/app/noticeNew")
              }}
            >
              <td>{_boardsTotal - (current - 1) * 10 - i}</td>
              <td>{v.title}</td>
              <td>{v.regDatetime}</td>
              <td>{v.registName}</td>
              <td>{v.status === 1 ? "사용중" : "미사용중"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination current={current} setCurrent={setCurrent} total={_boardsTotal} />
    </Layout>
  )
}

export default BoardContainer(Notice)
