import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getLargePouchTracks,
  getLargePouchs,
  getLargePouchItems,
  initLargePouch,
  getLargePouch,
  postLargePouch,
  patchLargePouch,
  deleteLargePouch,
  getTrackDownloadExcel,
  getManageDownloadExcel,
  getTrackDetailDownloadExcel,

  setLargePouchPrint,

} = actions.LargePouchAction
const { getBranchs } = actions.BranchAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _largePouchTracks: state.LargePouchReducer._largePouchTracks,
  _largePouchTracksTotal: state.LargePouchReducer._largePouchTracksTotal,
  _largePouchs: state.LargePouchReducer._largePouchs,
  _largePouchsTotal: state.LargePouchReducer._largePouchsTotal,
  _largePouchItems: state.LargePouchReducer._largePouchItems,
  _largePouchItemsTotal: state.LargePouchReducer._largePouchItemsTotal,
  _largePouch: state.LargePouchReducer._largePouch,
  _branchs: state.BranchReducer._branchs,

  _largePouchPrint: state.LargePouchReducer._largePouchPrint,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getLargePouchTracks: (param) => dispatch(getLargePouchTracks(param)),
  getLargePouchs: (param) => dispatch(getLargePouchs(param)),
  getLargePouchItems: (param) => dispatch(getLargePouchItems(param)),
  initLargePouch: (param) => dispatch(initLargePouch(param)),
  getLargePouch: (param) => dispatch(getLargePouch(param)),
  postLargePouch: (param) => dispatch(postLargePouch(param)),
  patchLargePouch: (param) => dispatch(patchLargePouch(param)),
  deleteLargePouch: (param) => dispatch(deleteLargePouch(param)),
  getBranchs: (param) => dispatch(getBranchs(param)),
  getTrackDownloadExcel: (param) => dispatch(getTrackDownloadExcel(param)),
  getTrackDetailDownloadExcel: (param) => dispatch(getTrackDetailDownloadExcel(param)),
  getManageDownloadExcel: (param) => dispatch(getManageDownloadExcel(param)),

  setLargePouchPrint: (param) => dispatch(setLargePouchPrint(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
