// 전화번호에 - 붙이는 함수
export function phoneNumFormat(num, type) {
  if (num === null) return ""

  let formatNum = ""
  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3")
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2")
  } else {
    if (num.indexOf("02") === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3")
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3")
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
    }
  }

  return formatNum
}
export const OnClickExcelSave = (props) => {
  const { result, fileName } = props
  const blob = new Blob([result], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  })

  const link = document.createElement("a")
  const href = window.URL.createObjectURL(blob)

  link.href = href
  link.download = `${fileName}.xlsx`
  link.click()
}

export const GetStatus = (num) => {
  const arr = ["지사 발신", "옥천 상차", "한통 발신", "한통 수신", "배송완료", "한통 픽업", "한통 분류", "옥천 상차", "옥천 하차", "지사 픽업"]
  return arr[num - 1]
}

export const WidthToDP = (size) => {
  if (typeof window !== "undefined") {
    return (1771 / 1771) * size
  } else return size
}
