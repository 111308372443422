import styled from "styled-components"

const SectionName = styled.p`
  font-family: NanumSquareBold;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.72px;
  color: #35363a;
  margin-top: 25px;
  margin-bottom: 25px;
`

export default SectionName
