import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import moment from "moment"
import SEO from "../seo"
import LargePouchContainer from "../../containers/largePouchContainer"
import Layout from "../component/common/layout"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import Category from "../component/common/category"
import DatePicker from "../component/common/datePicker"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave, GetStatus } from "../../utils/util"
const LargePouchTrack = (props) => {
  const { getLargePouchTracks, _largePouchTracks, _largePouchTracksTotal, getTrackDownloadExcel } = props
  //팝업
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "날짜를 다시 확인해주세요.", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  //탭
  const [tap, setTap] = useState(2) //0:행낭추적,1:행낭관리,2:대낭추적, 3:대낭관리
  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchText, setSearchText] = useState("")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(1)
  //스캔일자
  const [startDate, setStartDate] = useState(moment().startOf("months"))
  const [endDate, setEndDate] = useState(moment().endOf("months"))

  const [tempSearchStartDate, setTempSearchStartDate] = useState(moment().startOf("months"))
  const [tempSearchEndDate, setTempSearchEndDate] = useState(moment().endOf("months"))
  //onClick ..
  const onClickSearch = async () => {
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (!moment(startDate).isBefore(moment(endDate))) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    if (tempSearchIndex === 0) {
      setMessageIndex(2)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(3)
    //   setPopup(true)
    //   return
    // }
    let temp = { page: 0, count: 20, startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (tempSearchIndex !== 0){ //&& tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getLargePouchTracks({ ...temp })
    setCurrent(1)
  }

  const onClickLookUp = async () => {
    setTempSearchIndex(0)
    setTempSearchText("")
    setSearchIndex(0)
    setSearchText("")
    setStartDate(tempSearchStartDate)
    setEndDate(tempSearchEndDate)

    let dateFr = moment(tempSearchStartDate).format("yyyy-MM-DD")
    let dateTo = moment(tempSearchEndDate).format("yyyy-MM-DD")

    // if (!moment(tempSearchStartDate).isBefore(moment(tempSearchEndDate))) {
    if ( dateFr > dateTo ) {
      setMessageIndex(1)
      setPopup(true)
      return
    }
    
    let temp = {
      page: 0,
      count: 20,
      startDate: moment(tempSearchStartDate).format("yyyy-MM-DD"),
      endDate: moment(tempSearchEndDate).format("yyyy-MM-DD"),
    }
    await getLargePouchTracks({ ...temp })
    setCurrent(1)
  }

  const onClickExcelSave = async () => {
    let temp = { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getTrackDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "대낭추적리스트" })
  }
  const tapCallback = (tap) => {
    if (tap === 2) return
    switch (tap) {
      case 0:
        navigate("/app/pouchTrack")
        break
      case 1:
        navigate("/app/pouchManage")
        break
      case 2:
        navigate("/app/largePouchTrack")
        break
      case 3:
        navigate("/app/largePouchManage")
        break
    }
  }
  //end onClick ..

  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    setTempSearchStartDate(startDate)
    setTempSearchEndDate(endDate)
    getData()
  }, [current])

  const getData = async () => {
    let temp = { page: current - 1, count: 20, startDate: startDate.format("yyyy-MM-DD"), endDate: endDate.format("yyyy-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getLargePouchTracks({ ...temp })
  }

  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["대낭추적"]} style={{ marginBottom: "40px" }}></PageName>
      <Category items={["행낭추적", "행낭관리", "대낭추적", "대낭관리"]} state={tap} setState={tapCallback}></Category>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <DatePicker
            label={"스캔일자"}
            startDate={tempSearchStartDate}
            setStartDate={setTempSearchStartDate}
            endDate={tempSearchEndDate}
            setEndDate={setTempSearchEndDate}
            onClick={onClickLookUp}
          ></DatePicker>
          <Search
            item={["선택", "대낭코드", "발신지사", "수신지사", "스캔담당", "위치", "상태"]}
            index={tempSearchIndex}
            setIndex={setTempSearchIndex}
            text={tempSearchText}
            setText={setTempSearchText}
            onClickSearch={onClickSearch}
          />
        </div>
        <div style={{ diplay: "flex" }}>
          <Button onClick={onClickExcelSave}>{"엑셀 저장"}</Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>번호</th>
            <th>대낭코드</th>
            <th>발신지사</th>
            <th>수신지사</th>
            <th>스캔일자</th>
            <th>스캔담당</th>
            <th>위치</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {_largePouchTracks.map((v, i) => {
            return (
              <tr key={i}>
                <td>{_largePouchTracksTotal - (current - 1) * 10 - i}</td>
                <td>
                  <button
                    onClick={async () => {
                      navigate(`/app/largePouchTrackDetail?largeTrackSeq=${v.largePouch.largePouchSeq}&largeTrackNo=${v.largePouch.pouchNo}`)
                    }}
                  >
                    <u>{v.largePouch.pouchNo}</u>
                  </button>
                </td>
                <td>{v.largePouch.sendBranch.branchName}</td>
                <td>{v.largePouch.recvBranch.branchName}</td>
                <td>{moment(v.regDatetime).format("YYYY-MM-DD")}</td>
                <td>{v.scanName}</td>
                <td>
                  {v.addr}&nbsp;{v.addrDetail}
                </td>
                <td>{GetStatus(v.status)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Pagination current={current} setCurrent={setCurrent} total={_largePouchTracksTotal} />
    </Layout>
  )
}

export default LargePouchContainer(LargePouchTrack)
