import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getCustomers, getCustomer, postCustomer, patchCustomer, deleteCustomer, initCustomer, getDownloadExcel } = actions.CustomerAction
const { initPoint } = actions.PointAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _customers: state.CustomerReducer._customers,
  _customer: state.CustomerReducer._customer,
  _customersTotal: state.CustomerReducer._customersTotal,
  _point: state.PointReducer._point,
  _points: state.PointReducer._points,
  _pointsTotal: state.PointReducer._PointsTotal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getCustomers: (param) => dispatch(getCustomers(param)),
  getCustomer: (param) => dispatch(getCustomer(param)),
  postCustomer: (param) => dispatch(postCustomer(param)),
  patchCustomer: (param) => dispatch(patchCustomer(param)),
  deleteCustomer: (param) => dispatch(deleteCustomer(param)),
  initCustomer: () => dispatch(initCustomer()),
  initPoint: () => dispatch(initPoint()),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
