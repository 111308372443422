import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getDrivers,
  getDriversLocal,
  getDriver,
  getDriverLocal,
  postDriver,
  patchDriver,
  deleteDriver,
  initDriver,
  initDriverLocal,
  getDriverCheckId,
  getDownloadExcel,
} = actions.DriverAction
const { getBranchs } = actions.BranchAction
// redux state > component mapping
const mapStateToProps = (state) => ({
  _drivers: state.DriverReducer._drivers,
  _driversTotal: state.DriverReducer._driversTotal,
  _driversLocal: state.DriverReducer._driversLocal,
  _driversLocalTotal: state.DriverReducer._driversLocalTotal,
  _branchs: state.BranchReducer._branchs,
  _driver: state.DriverReducer._driver,
  _driverLocal: state.DriverReducer._driverLocal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getBranchs: (param) => dispatch(getBranchs(param)),
  getDriverLocal: (param) => dispatch(getDriverLocal(param)),
  getDriverCheckId: (param) => dispatch(getDriverCheckId(param)),
  getDrivers: (param) => dispatch(getDrivers(param)),
  getDriversLocal: (param) => dispatch(getDriversLocal(param)),
  getDriver: (param) => dispatch(getDriver(param)),
  postDriver: (param) => dispatch(postDriver(param)),
  patchDriver: (param) => dispatch(patchDriver(param)),
  deleteDriver: (param) => dispatch(deleteDriver(param)),
  initDriver: () => dispatch(initDriver()),
  initDriverLocal: () => dispatch(initDriverLocal()),
  getDownloadExcel: (param) => dispatch(getDownloadExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
