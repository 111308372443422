import React, { useState, useEffect } from "react"
import SEO from "../seo"
import LargePouchContainer from "../../containers/largePouchContainer"
import Layout from "../component/common/layout"
import moment from "moment"
import styled from "styled-components"
import PageName from "../component/common/pageName"
import Search from "../component/common/search"
import Button from "../component/common/button"
import Pagination from "../component/common/pagination"
import Category from "../component/common/category"
import { navigate } from "gatsby"
import CheckBox from "../component/common/checkbox"
import BasicPopup from "../../popups/basicPopup"
import { OnClickExcelSave } from "../../utils/util"
const LargePouchManage = (props) => {
  
  const { _largePouchs, _largePouchsTotal, getLargePouchs, initLargePouch, 
    getLargePouch, _largePouch, getManageDownloadExcel,
    
    setLargePouchPrint, //_largePouchPrint,

  } = props
  
  const { searchInfoText, serachInfoIndex, searchInfoCurrent } = props.location.state
  //탭
  const [tap, setTap] = useState(3) //0:행낭추적,1:행낭관리,2:대낭추적, 3:대낭관리

  // 검색 컴포넌트
  const [searchIndex, setSearchIndex] = useState(serachInfoIndex || 0)
  const [searchText, setSearchText] = useState(searchInfoText || "")

  const [tempSearchIndex, setTempSearchIndex] = useState(0)
  const [tempSearchText, setTempSearchText] = useState("")
  // 페이지네이션
  const [current, setCurrent] = useState(searchInfoCurrent || 1)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  const [check, setCheck] = useState([])
  const tapCallback = (tap) => {
    if (tap === 3) return
    switch (tap) {
      case 0:
        navigate("/app/pouchTrack")
        break
      case 1:
        navigate("/app/pouchManage")
        break
      case 2:
        navigate("/app/largePouchTrack")
        break
      case 3:
        navigate("/app/largePouchManage")
        break
    }
  }

  //onClick ..
  const onClickSearch = async () => {
    setSearchIndex(tempSearchIndex)
    setSearchText(tempSearchText)
    if (tempSearchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } 
    // else if (tempSearchText.length < 2) {
    //   setMessageIndex(2)
    //   setPopup(true)
    //   return
    // }

    let temp = { page: 0, count: 20 }
    if (tempSearchIndex !== 0){// && tempSearchText.length >= 2) {
      temp = { ...temp, searchType: tempSearchIndex, search: tempSearchText.length > 0 ? tempSearchText : '' }
    }
    await getLargePouchs({ ...temp })
    setCurrent(1)
  }
  const onClickCheck = async (seq) => {
    const temp = [...check]
    if (temp.includes(seq)) {
      temp.splice(temp.indexOf(seq), 1)
    } else {
      temp.push(seq)
    }
    setCheck(temp)
  }
  const onClickTicketPrint = () => {
    
    if (check.length === 0) return

    const temp = check
    
    setCheck([])

    setLargePouchPrint(_largePouchs.filter((t) => temp.includes(t.largePouchSeq)))
    
    navigate("/app/printLargePouch")

  }
  const onClickExcelSave = async () => {
    let temp = {}
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    const result = await getManageDownloadExcel({ ...temp })
    OnClickExcelSave({ result: result, fileName: "대낭관리리스트" })
  }
  const onClickRegister = async () => {
    await initLargePouch()
    navigate("/app/largePouchNew")
  }
  //end onClick...
  const getData = async () => {
    let temp = { page: current - 1, count: 20 }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    await getLargePouchs({ ...temp })
  }
  useEffect(() => {
    setTempSearchIndex(searchIndex)
    setTempSearchText(searchText)
    getData()
  }, [current])

  return (
    <Layout navi={"행낭관리"}>
      <SEO title={"행낭관리"} />
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      <PageName title={["대낭관리"]} style={{ marginBottom: "40px" }}></PageName>
      <Category items={["행낭추적", "행낭관리", "대낭추적", "대낭관리"]} state={tap} setState={tapCallback}></Category>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <Search
            item={["선택", "대낭코드", "발행자", "발신지사", "수신지사", "대낭상태"]}
            index={tempSearchIndex}
            setIndex={setTempSearchIndex}
            text={tempSearchText}
            setText={setTempSearchText}
            onClickSearch={onClickSearch}
          />
        </div>
        <div style={{ diplay: "flex" }}>
          <Button onClick={onClickTicketPrint}>{"선택 표찰 출력"}</Button>
          <Button onClick={onClickExcelSave} marginLeft={6}>
            {"엑셀 저장"}
          </Button>
          <Button onClick={onClickRegister} marginLeft={6}>
            {"등록"}
          </Button>
        </div>
      </div>
      <table style={{ marginBottom: 43.4 }}>
        <thead>
          <tr>
            <th>선택</th>
            <th>번호</th>
            <th>대낭코드</th>
            <th>발행자</th>
            <th>발행일</th>
            <th>발신지사</th>
            <th>수신지사</th>
            <th>대낭상태</th>
            <th>표찰출력</th>
          </tr>
        </thead>
        <tbody>
          {_largePouchs.map((v, i) => {
            return (
              <tr key={i}>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CheckBox label="" value={check.includes(v.largePouchSeq)} setValue={() => onClickCheck(v.largePouchSeq)} />
                  </div>
                </td>
                <td>{_largePouchsTotal - (current - 1) * 10 - i}</td>
                <td>
                  <button
                    onClick={async () => {
                      if (v.status === 2) return
                      await getLargePouch({ largePouchSeq: v.largePouchSeq })
                      navigate("/app/largePouchNew", {
                        state: { searchInfoText: searchText, serachInfoIndex: searchIndex, searchInfoCurrent: current },
                      })
                    }}
                  >
                    <u>{v.pouchNo}</u>
                  </button>
                </td>
                <td>{v.registName}</td>
                <td>{moment(v.regDatetime).format("YYYY.MM.DD H:mm")}</td>
                <td>{v.sendBranch.branchName}</td>
                <td>{v.recvBranch.branchName}</td>
                <td>{getStatus(v.status)}</td>
                <td>
                  <SmallButton onClick={() => {

                    setLargePouchPrint(_largePouchs.filter((t) => t.largePouchSeq == v.largePouchSeq ))
                        
                    navigate("/app/printLargePouch")

                  }}>{"출력"}</SmallButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Pagination current={current} setCurrent={setCurrent} total={_largePouchsTotal} />
    </Layout>
  )
}
export default LargePouchContainer(LargePouchManage)

const SmallButton = styled(Button)`
  height: 30px;
  margin-left: 6px;
`
const getStatus = (status) => {
  const arr = ["미사용", "사용중", "삭제"]
  return arr[status]
}
